<div class="media-box media"
  [ngClass]="(newsDetail ? 'news-detail ' : '') + customClasses + (template === 4 ? ' media-item-4' : '')">
  <div [ngClass]="'media-image ' + customClasses + (template === 4 ? ' media-item-4' : '')">
    <span class="image-container">
      <div class="play-button-holder">
        <div *ngIf="media.mediaType === 'Video'" class="play-button flex-center" (click)="openMediaPopup(true)">
          <i class="fas fa-play"></i>
        </div>
      </div>

      <picture *ngIf="
          media.mediaType.toLowerCase() != 'audio' &&
          media.mediaType.toLowerCase() != 'document'
        ">
        <source srcset="{{ webPHelper.changeFileToWebP(getSource(media)) }}" type="image/webp" />
        <source srcset="{{ getSource(media) }}" type="image/jpeg" />
        <source srcset="assets/images/placeholder.jpg" type="image/jpeg" />
        <img (error)="webPHelper.removeWebP($event)" src="{{ getSource(media) }}" alt="Story featured image" />
      </picture>

      <div class="audio-overlay flex-center" *ngIf="media.mediaType.toLowerCase() === 'audio'">
        <div class="file-type-wrapper flex-center">
          <img src="../../../../assets/images/file-type-sound.svg" alt="{{ media.name }}" />
        </div>
      </div>
      
      <div class="document-overlay flex-center" *ngIf="media.mediaType.toLowerCase() === 'document'">
        <div class="file-type-wrapper flex-center" style="width: 100%; height: 100%;">
          <ng-container *ngIf="media.fileExtension.toLowerCase() === 'pdf' && media.thumbUrl">
            <img [src]="media.thumbUrl" alt="{{ media.name }}" />
          </ng-container>
          <ng-container *ngIf="(media.fileExtension.toLowerCase() === 'xlsx' || media.fileExtension.toLowerCase() === 'docx') && media.thumbUrl">
            <div class="image-doc-container">
              <img *ngIf="media.fileExtension.toLowerCase() === 'xlsx'" src="../../../../assets/images/file-type-xls.svg" alt="{{ media.name }}" style="width: 50px; height: 50px;" />
              <img *ngIf="media.fileExtension.toLowerCase() === 'docx'" src="../../../../assets/images/file-type-docx.svg" alt="{{ media.name }}" style="width: 50px; height: 50px;" />
              <img *ngIf="media.fileExtension.toLowerCase() === 'other'" src="" alt="{{ media.name }}" />
            </div>
          </ng-container>
          <ng-container *ngIf="!media.thumbUrl">
            <div class="image-doc-container">
              <img *ngIf="media.fileExtension.toLowerCase() === 'pdf'" src="../../../../assets/images/file-type-pdf.svg" alt="{{ media.name }}" style="width: 38px; height: 53px;"/>
              <img *ngIf="media.fileExtension.toLowerCase() === 'xlsx'" src="../../../../assets/images/file-type-xls.svg" alt="{{ media.name }}" style="width: 50px; height: 50px;"/>
              <img *ngIf="media.fileExtension.toLowerCase() === 'docx'" src="../../../../assets/images/file-type-docx.svg" alt="{{ media.name }}" style="width: 50px; height: 50px;" />
              <img *ngIf="media.fileExtension.toLowerCase() === 'other'" src="" alt="{{ media.name }}" />
            </div>
          </ng-container>
        </div>
      </div>
      
    </span>
    <div class="button-hover-overlay">
      <div class="button-container">
        <div class="open-popup overlay-button" (click)="openMediaPopup()">
          <div class="iconContainer">
            <img class="" src="/assets/images/dna/expand.svg">
          </div>
          {{ language.expand }}
        </div>

        <div class="add-to-cart overlay-button"  *ngIf="newsDetail" (click)="sendToCart()">
          <div class="iconContainer">
            <img class="" src="/assets/images/dna/cart.svg">
          </div>
          {{ language.addToMyCart }}
        </div>

        <div class="add-to-download-box overlay-button" (click)="download()">
          <div class="iconContainer">
            <img *ngIf="!downloading" class="" src="/assets/images/dna/download.svg">
          </div>
          {{ language.download }}
          <app-loadersmall *ngIf="downloading" [class]="'smaller'"></app-loadersmall>
        </div>

        <app-share-5 *ngIf="config != false && config.socialSharing?.length > 0 && (config?.storyTitle || media.name)" 
          [image]="media.thumbUrl" [big]="false" [vertical]="customClasses == 'news-detail-media-file'"
          [shared]="media.shares" [sbUrl]="shareUrl" [title]="config?.storyTitle || media.name"
          [description]="config?.shortDescription" [mediaId]="media.id" [webNewsDetailsResponse]="config"
          [isMediaShare]="true" [isMediaShareVertical]="true" [socials]="config.socialSharing"
          [isPublished]="isPublished"></app-share-5>

          
          <!-- <app-share-5 *ngIf="config.socialSharing?.length > 0" [image]="story.thumbUrl" [title]="story.storyTitle"
            [description]="story.shortDescription" [horizontal]="true" [shared]="story.shared"
            [sbUrl]="getStoryUrl()" [staggingStoryId]="story.staggingStoryId" [mediaId]="story.mediaId"
            [storyId]="story.storyId" [socials]="config.socialSharing">
          </app-share-5> -->
      </div>
    </div>
  </div>

  <div class="media-info" *ngIf="full">
    <div class="media-name">
      {{ media.name }}
    </div>
    <div class="media-date">
      {{ media.publishedDate }}
    </div>

    <hr/>

    <!-- <div class="bottom-row flex"> -->
    <div class="read-more" (click)="sendToCart()">
      <span *ngIf="!media.addedToCart">{{ language.addToMyCart }}</span>
      <span *ngIf="media.addedToCart">{{ language.addedToMyCart }}</span>
    </div>
    <!-- <div class="add-to-download-box round generic-button empty" (click)="download()">
        <app-loadersmall *ngIf="downloading" [class]="'smaller'"></app-loadersmall>
      </div> -->
    <!-- </div> -->
  </div>

  <div class="media-info news-detail" *ngIf="newsDetail">
    <h2>
      {{media.name}}
    </h2>
    <p>{{media.mediaType}}</p>
  </div>

</div>

<app-popup-5 *ngIf="popup && !showExternally" [(popup)]="popup" [(hideCounters)]="config.hideCounters" [mediaId]="mediaId"
  [isPublished]="isPublished" [autoplay]="autoplay"></app-popup-5>
<app-loader *ngIf="loader"></app-loader>
<app-loginpopup *ngIf="loginPopup" [(loginPopup)]="loginPopup" (loginSuccessfulChange)="onLogin($event)"></app-loginpopup>