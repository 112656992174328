import { Component, OnInit } from '@angular/core';
import { ContactsService } from './contacts.service';
import { ContactsModel } from './contacts.model';
import { Meta } from '@angular/platform-browser';
import { McService } from '../../shared/services/mc-service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PageStyle } from '../../models/style/style.model';
import { FontsAndColorsService } from '../../shared/services/fonts-and-colors.service';
import { MetaModel } from '../../models/meta/meta';
import { LanguageService } from '../../shared/services/language.service';
import { Language } from '../../models/language/language.model';
import { BrandsManageModel, BrandsModel } from '../brands/brands-model';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.less'],
  providers: [ContactsService]
})
export class ContactsComponent implements OnInit {
  contacts = new ContactsModel();
  loader: boolean;
  styles = new PageStyle();
  metadatas: MetaModel;
  language: Language;
  brandsResponse = new BrandsModel();
  brands: BrandsManageModel[] = [];
  isEasyJet: boolean = false;

  constructor(private ContactsService: ContactsService, private languageService: LanguageService, private meta: Meta, private titleService: Title, private router: Router,
    private mcService: McService, private fontsAndColorsService: FontsAndColorsService) {
    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();

    this.languageService.language.subscribe(
      (language) => {
        this.language = language;
      }
    );
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getContacts();
    // if (localStorage.getItem("isEasyJet") === "1") {
    //   this.isEasyJet = true;
    // }
  }

  getContacts() {
    this.loader = true;
    this.getHardcodedContacts();
    // this.ContactsService.mediaCenterWebContacts()

    //   .subscribe((response: any) => {
    //     // this.contacts = response;
    //     this.loader = false;
    //   },
    //     (error) => {
    //       //
    //     });
  }

  getHardcodedContacts() {
    this.contacts.ourTeam = [
      {
        name: 'Holly Mitchell',
        email: 'Holly.Mitchell@easyjet.com',
        description: 'for easyJet media enquiries for journalists from the UK or UK publications.',
        position: '1',
        thumbUrl: '../../assets/easyJet/Holly Mitchell.jpg',
      },
      {
        name: 'Andy Cockburn',
        email: 'Andy.Cockburn@easyJet.com',
        description: 'For easyJet media enquiries for journalists from the UK or UK publications on London Gatwick, London Luton, London Stansted, London Southend, Liverpool and Manchester as well as Egypt, Israel, Turkey and Jordan.',
        position: '1',
        thumbUrl: '../../assets/easyJet/Andy Cockburn.jpg',
      },
      {
        name: 'Paula Peralta',
        email: 'Paula.Peralta@easyjet.com',
        description: 'for easyJet media enquiries for journalists from the UK or UK publications on Scotland and Northern Ireland as well as the Isle of Man, Jersey and Gibraltar.',
        position: '1',
        thumbUrl: '../../assets/easyJet/Paula Peralta.jpg',
      },
      {
        name: 'Lottie Phillips',
        email: 'Lottie.phillips@easyJet.com',
        description: 'For easyJet media enquiries for Bristol, Birmingham, Bournemouth, Newcastle, Newquay, Southampton, as well as Denmark, Sweden, Norway and Finland and Iceland.',
        position: '1',
        thumbUrl: '../../assets/easyJet/Lottie Phillips.jpg',
      },
      {
        name: 'Kirsten de Haan',
        email: 'Kirsten.dehaan@easyjet.com',
        description: 'for easyJet media enquiries for journalists from the Netherlands and Belgium.',
        position: '1',
        thumbUrl: '../../assets/easyJet/Kirsten Dehaan.jpg',
      },
      {
        name: 'Matteo Finotto',
        email: 'Matteo.Finotto@easyJet.com',
        description: 'for easyJet media enquiries for journalists from Southern Europe and the Balkans (Italy, Spain, Portugal, Greece, Bulgaria, Croatia, Cyprus, Kosovo, Serbia, Montenegro and Slovenia).',
        position: '1',
        thumbUrl: '../../assets/easyJet/Matteo Finotto.jpg',
      },
      {
        name: 'Sara Scuri',
        email: 'Sara.Scuri@easyJet.com',
        description: 'for easyJet media enquiries for Italy.',
        position: '1',
        thumbUrl: '../../assets/easyJet/Sara Scuri.jpg',
      },
      {
        name: 'Carinne Heinen',
        email: 'Carinne.Heinen@easyJet.com',
        description: 'for easyJet media enquiries for journalists from France, Switzerland (French-speaking), Belgium (French-speaking), Luxembourg and Morocco.',
        position: '1',
        thumbUrl: '../../assets/easyJet/Carinne Heinen.jpg',
      },
      {
        name: 'Manuel Guenin',
        email: 'Manuel.Guenin@easyjet.com',
        description: 'for easyJet media enquiries for journalists from Austria, Germany, Hungary, Poland and Switzerland (German-speaking).',
        position: '1',
        thumbUrl: '../../assets/easyJet/Manuel Guenin.jpg',
      },
      {
        name: 'Doris Diagne',
        email: 'Doris.Diagne@easyjet.com',
        description: 'for easyJet media enquiries for journalists from France.',
        position: '1',
        thumbUrl: '../../assets/easyJet/Doris Diagne.jpg',
      },
      {
        name: 'Kundai Musara',
        email: 'Kundai.Musara@easyJet.com',
        description: 'for easyJet media enquiries on Sustainability Communications.',
        position: '1',
        thumbUrl: '../../assets/easyJet/Kundai Musara.jpg',
      },
      {
        name: 'Matt Clemens',
        email: 'Matt.Clemens@easyJet.com',
        description: 'for easyJet media enquiries on Sustainability Communications.',
        position: '1',
        thumbUrl: '../../assets/easyJet/Matt.jpeg',
      },
    ]

    this.contacts.rotary = [
      { day: 'Monday', date: '20/5/2024', name: 'Matteo Finotto & Kirsten de Haan' },
      { day: 'Tuesday', date: '31/5/2024', name: 'Lottie Phillips & Kirsten de Haan' },
      { day: 'Wednesday', date: '22/5/2024', name: 'Matteo Finotto & Kirsten de Haan' },
      { day: 'Thursday', date: '23/5/2024', name: 'Andy Cockburn & Kirsten de Haan' },
      { day: 'Friday', date: '24/5/2024', name: 'Kundai Musara & Matt Clemens' },
      { day: 'Saturday', date: '25/5/2024', name: 'Kundai Musara & Matt Clemens' },
      { day: 'Sunday', date: '26/5/2024', name: 'Kundai Musara & Matt Clemens' },
      { day: 'Monday', date: '27/5/2024', name: 'Sophie Stronell & Matt Clemens' },
      { day: 'Tuesday', date: '28/5/2024', name: 'Sophie Stronell & Matt Clemens' },
      { day: 'Wednesday', date: '29/5/2024', name: 'Sophie Stronell & Matt Clemens' },
      { day: 'Thursday', date: '30/5/2024', name: 'Sophie Stronell & Matt Clemens' }
    ]
  }

  getBrands() {
    this.loader = true;
    this.mcService.getBrands()
      .subscribe((response: any) => {
        this.brandsResponse = response;
        // if (this.brandsResponse.mustRegisterToAccess && localStorage.getItem('Token') == null) {
        //   sessionStorage.setItem('redirectUrl', '/' + window.location.pathname);
        //   this.router.navigateByUrl('/sign-in');
        // }
        this.brands = response.clients;
        this.loader = false;
        if (this.brandsResponse.customCategories && this.brandsResponse.customCategories.length > 0)
          this.brandsResponse.customCategories.unshift({ id: 0, parentId: 0, label: 'All' });
        if (this.brandsResponse.mainCategories && this.brandsResponse.mainCategories.length > 0)
          this.brandsResponse.mainCategories.unshift({ id: 0, parentId: 0, label: 'All' });
      },
        (error) => {
          //
        });
  }
}
