<section class="login flex" ngClass="{login-dna : isDna}">
  
  <div class='banner-img desktop-only'>
    <div>
      <img src="../../assets/images/Aira_logo.svg" >
    </div>
  </div>

  <div class="register-wrapper">
    <div class="selector-container">
      <h1 class="selector-item">
        Log in
      </h1>
    </div>
    <form action="" class="register-form flex" [formGroup]="valForm" (ngSubmit)="submitLoginForm($event)">
      <div class="form-element flex">
        <input type="email" id="email" name="email" placeholder="{{language.enterEmail}}" [(ngModel)]="loginModel.email"
          formControlName="username" />
        <span class="text-danger" *ngIf="
            valForm.controls['username'].hasError('required') &&
            (valForm.controls['username'].dirty ||
              valForm.controls['username'].touched)
          ">
          {{ language.emailNameRequired }}
        </span>
        <span class="text-danger" *ngIf="
            valForm.controls['username'].hasError('email') &&
            (valForm.controls['username'].dirty ||
              valForm.controls['username'].touched)
          ">{{ language.invalidEmail }}</span>
      </div>
      <div *ngIf ="!magicLink" class="form-element flex">
        <div class="eye-icon" (click)="visiblePassword()">
          <img *ngIf="showPassword" src="../../assets/images/eye-black.svg" alt="Show password" />
          <img *ngIf="!showPassword" src="../../assets/images/eye-black-hide.svg" alt="Hide password" />
        </div>
        <input type="password" name="password" id="password" placeholder="{{language.enterPassword}}" [type]="type"
          [(ngModel)]="loginModel.password" formControlName="password" />
        <span class="text-danger" *ngIf="
            valForm.controls['password'].hasError('required') &&
            (valForm.controls['password'].dirty ||
              valForm.controls['password'].touched)
          ">
          Password is required
        </span>
      </div>
      <div *ngIf ="!magicLink" class="reset-pass">
        <p>Forgot Password?</p>
        <span (click)="forgotPassword()">{{ language.forgotPassword }}</span>
      </div>

      <div *ngIf ="magicLink">
        <p>We'll send you an email with a link that wil automatically log you in.</p>
        <div class="alternative-login">
          <p>Alternatively, </p>
          <span (click)="changeToLogin()"> sign in using your user and password.</span>
        </div>
      </div>

      <div *ngIf ="!magicLink">
        <div class="alternative-login">
          <p>Sign in with </p>
          <span (click)="changeToMagicLink()"> login link</span>
        </div>
      </div>

      <div *ngIf ="!magicLink" class="register-buttons flex-center">
        <button type="submit" class="generic-button submit flex-center">
          Log in
        </button>
      </div>

      <div *ngIf ="magicLink" class="register-buttons flex-center">
        <button type="submit" class="generic-button submit flex-center">
          Send Log in Link
        </button>
      </div>

    </form>
  </div>
</section>

<div id="sent-link-popup-container" *ngIf="showSentPopup">
  <div id="sent-link-popup">
    <h1> Check your inbox </h1>
    <p>We have just sent you to your email the new access</p>
    <div class="popup-btn generic-button flex-center" (click)="closeSentPopup()">Ok</div>
  </div>
</div>


<app-loader *ngIf="loader"></app-loader>