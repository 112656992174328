<section class="news-detail flex-center">
  <div class="content-wrapper">
    <ng-container *ngIf="searchModel?.stories">
      <h2 class="section-headline secondary" *ngIf="searchResultStories?.stories?.length == 0 && !loaderStory">
        {{ language.searchNoResults }}
      </h2>
      <div class="news-feed flex" [ngClass]="grid">
        <app-loadersmall *ngIf="loaderStory && resultStories.stories?.length != 0"></app-loadersmall>
        <ng-container *ngFor="
            let story of searchResultStories?.stories | slice : 0 : 6;
            let i = index
          ">
          <app-story-preview-5 *ngIf="(story?.fullHDUrl || story?.thumbUrl) && !isVodafone" class="story-preview" [template]="template"
            [story]="story" [config]="searchResultStories"></app-story-preview-5>
          
          <app-story-code *ngIf="(story?.fullHDUrl || story?.thumbUrl) && isVodafone" class="story-preview" [template]="template"
          [story]="story" [config]="searchResultStories"></app-story-code>
        </ng-container>
      </div>
      <div class="all-results" (click)="allResults()">view more</div>
      <div class="lazy-loading-loader-container" *ngIf="lazyLoadingLoader">
        <app-loadersmall></app-loadersmall>
      </div>
    </ng-container>
    <ng-container *ngIf="searchModel?.media">
      <h2 class="section-headline secondary" *ngIf="searchResultMedia?.media?.length == 0 && !loaderMedia">
        {{ language.searchNoResults }}
      </h2>
      <div class="media media-wrapper" id="media-wrapper">
        <app-loadersmall *ngIf="loaderMedia && (resultMedia.media?.length != 0 || true)"></app-loadersmall>
        <div class="media-feed" [ngClass]="grid">
          <ng-container *ngFor="
              let media of searchResultMedia?.media | slice : 0 : 6;
              let i = index
            ">
            <app-media-file-5 *ngIf="isDna" class="media-file" [customClasses]="'search-media-file'" [template]="template"
              [media]="media" [config]="searchResultMedia" [full]="true" [showExternally]="true"
              (dataEvent)="receiveDataFromChild($event)"></app-media-file-5>
              <app-media-file *ngIf="!isDna" class="media-file" [customClasses]="'search-media-file'" [template]="template"
              [media]="media" [config]="searchResultMedia" [full]="true" [showExternally]="true"
              (dataEvent)="receiveDataFromChild($event)"></app-media-file>
          </ng-container>
        </div>
        <div class="lazy-loading-loader-container" *ngIf="lazyLoadingLoader">
          <app-loadersmall></app-loadersmall>
        </div>
      </div>
      <!-- <div class="all-results" (click)="allResults()">view more</div> -->
    </ng-container>
  </div>
</section>

<!-- <app-popup
  *ngIf="popup"
  [(hideCounters)]="resultMedia.hideCounters"
  [(popup)]="popup"
  [mediaId]="mediaId"
  (popupClosed)="updateMedia($event)"
  [autoplay]="autoplay"
></app-popup> -->