<div class="media-cart-popup" [style.top]="isMediaCartOpen ? '0' : '-110vh'">

  <div class="header-media-cart">
    <h1>Media Cart</h1>
    <div class="close" (click)="closeMediaCart()">
      <img src="../../../../assets/images/close-popup.svg" alt="">
    </div>
  </div>

  <div class="body-media-cart">

    <div class="filters-media-cart">
      
      <div class="file-type-filters">
        <ng-container *ngFor="let filter of filters">
          <div class="filter-button flex generic-button empty" (click)="filterType(filter.type)"
            [ngClass]="{ active: fileType == filter.type }">
            <span>{{ filter.text }}</span>
          </div>
        </ng-container>
      </div>


      <div class="buttons-media-cart">
        <div class="generic-button" (click)="downloadAllFiles()">
          <img src="../../../../assets/images/download-files-icon.svg" alt="">
          Download all files
        </div>

        <!-- <div class="generic-button button-gray" (click)="sendCart()">
          <img src="../../../../assets/images/send-cart-icon.svg" alt="">
          Send cart
        </div> -->

        <div class="generic-button button-gray" (click)="emptyCart()">
          <img src="../../../../assets/images/empty-cart-icon.svg" alt="">
          Empty cart
        </div>
      </div>

    </div>

    <div class="media-cart-medias">
      <div class="media-feed">
        <ng-container *ngIf="!products?.length">
          <p class="empty-message">No media saved.</p> </ng-container>
      
        <ng-container *ngFor="let storyMedia of products">
          <ng-container *ngFor="let media of storyMedia.media">
            <app-media-file-5 *ngIf="showFile(media.media)" class="media-file" [newsDetail]="false" [newsDescription]="true" [customClasses]="'news-detail-media-file'"
              [media]="media.media" [downloadUrl]="!guest ? media.downloadLinks[0]?.downloadUrl : ''" [config]="false"></app-media-file-5>
          </ng-container>
        </ng-container>
      </div>
    </div>

  </div>

</div>