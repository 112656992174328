<div *ngIf="!loader">
  <section class="hero-banner">
    <div class="main-story" >
      <div class="image-opacity" [backgroundUrl]="backgroundUrl" [ngStyle]="{'background-image': 'url(' + backgroundUrl + ')' }">

        <div class="button-hover-overlay" (click)="readMore(selectedFs, selectedFs?.storyTitle, selectedFs)">
          <div class="button-overlay button-container">
            <div class="add-to-wishlist" *ngIf="user.id != 0" (click)="toggleSavedStory(selectedFs.storyId, $event)">
              <div class="iconContainer">
                <img class="icon" *ngIf="!inSavedStories(selectedFs.storyId)" src="/assets/images/dna/heart.svg">
                <img class="icon filled" *ngIf="inSavedStories(selectedFs.storyId)" src="/assets/images/dna/heartFilled.svg">
              </div>
              <div *ngIf="!inSavedStories(selectedFs.storyId)">{{ language.addStoryToFav }} </div>
              <div  *ngIf="inSavedStories(selectedFs.storyId)">{{ language.addedToMyFiles }} </div>
              
            </div>
    
            <ng-container *ngIf="imageMedia.length > 0">
              <div class="button-overlay download-all-files" (click)="downloadAll(imageMedia, 'image')">
                <div class="iconContainer">
                  <img class="icon" src="/assets/images/dna/download.svg">
                </div>
                {{ downloadingAll ? language.downloading : "Download all files" }}
              </div>
            </ng-container>
    
            <app-share-5 *ngIf="this.homeModelResponse.socialSharing?.length > 0" [image]="selectedFs.thumbUrl" [title]="selectedFs.storyTitle"
              [description]="selectedFs.shortDescription" [big]="false" [horizontal]="true" [shared]="selectedFs.shared"
              [sbUrl]="getStoryUrl()" [staggingStoryId]="selectedFs.staggingStoryId" [mediaId]="selectedFs.mediaId"
              [storyId]="selectedFs.storyId" [socials]="this.homeModelResponse.socialSharing">
            </app-share-5>
          </div>
        </div>

      </div>
      <div class="main-headline">
        
        <div class="meta flex">
          <div *ngIf="showCategory" class="category-wrapper" (click)="showSubCategory(selectedFs)">
            <div class="category" [ngClass]="selectedFs?.category.length > 1 ? 'has-child' : ''">
              <div>
                {{ selectedFs?.category[0] }}
              </div>
            </div>
            <div class="category-dropdown" *ngIf="selectedFs?.dropdown && selectedFs?.category.length > 1">
              <div *ngFor="
                  let category of selectedFs?.category
                    | slice : 1 : selectedFs?.category.length
                ">
                {{ category }}
              </div>
            </div>
          </div>
        </div>
        <h2>
          {{ selectedFs?.storyTitle }}
        </h2>
        <h4>
          {{ selectedFs?.shortDescription }}
        </h4>
        <div class="headline-buttons">
          <div class="main-read-more flex-center" appStyle
            (click)="readMore(selectedFs, selectedFs?.storyTitle, selectedFs)">
            {{ language.readMore }}
          </div>
          <div class="story-nav-container">
            <div class="story-nav" (click)="prevSlide()">
              <i class="far fa-angle-left"></i>
            </div>
            <div class="story-nav" (click)="nextSlide()">
              <i class="far fa-angle-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="featured-stories-container">
      <ng-container *ngFor="let fstory of nextFStories; let i = index" (click)="changeStory(fstory, i)">
        <app-story-preview-5 [story]="fstory" [config]="homeModelResponse"
          [customClasses]="'half-height'"></app-story-preview-5>
      </ng-container>
    </div>
  </section>

  <section class="banner back-to-all-markets">
    <div class="left-side">
      <h1 class="title">Welcome <br>to dna. ///</h1>
    </div>
    <div class="right-side">
      <div class="text">
        The home of content from the brands you love. Read, download and share—for free.
      </div>
      <a *ngIf="user.id === 0" routerLink="/sign-in"
        routerLinkActive="active" (click)="rerouteToLogin(language.signIn)">
        <div class="lowercase button">Sign up today</div>
      </a>
      <a *ngIf="user.id != 0" routerLink="/profile">
        <div class="lowercase button">We already got you!</div>
      </a>
    </div>
  </section>

  <section class="news flex-center">
    <div class="content-wrapper">
      <ng-container>
        <div class="news-feed" *ngIf="homeModelResponse.latestStories.length != 0">
          <h2 class="section-headline reset-margin-top">
            {{ language.latestNews }}
          </h2>
          <ng-container *ngFor="let group of storyGroupings; let i = index">
            <app-news-group-5 [group]="group" [config]="homeModelResponse" [mirrored]="i % 2 != 0"></app-news-group-5>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </section>

  <div class="container-button-news">
    <a href="/stories" class="generic-button">See more</a>
  </div>

  <section *ngIf="brands && brands.length > 0" class="brands">
    <div class="content-wrapper">
      <h2 class="section-headline reset-margin-top">
        {{ language.brands }}
      </h2>
      <div class="brand-nav-container">
        <div class="brand-nav" (click)="moveLeftBrands()">
          <!-- <i class="far fa-angle-left"></i> -->
          <img src="/assets/images/dna/arrow-left.svg">
        </div>
        <div class="brand-nav" (click)="moveRightBrands()">
          <!-- <i class="far fa-angle-right"></i> -->
          <img src="/assets/images/dna/arrow-right.svg">
        </div>
      </div>
    </div>
    <ng-container>
      <div class="overflow-container">
        <div #brandsContainer id="brands-container" class="brand-wrapper related-centers-wrapper flex"
          [ngStyle]="{ 'left.px': -brandsCurrentScroll }">
          <a class="brand-box" *ngFor="let brand of brands" (click)="goToMc(brand.mediaCenterUrl, brand.id, brand.name)"
            (mouseenter)="brand.brandHovered = true" (mouseleave)="brand.brandHovered = false">
            <div class="brand-image flex-center">
              <img src="{{ brand.relatedMcBrandsLogo }}" alt="{{ brand.name }} Logo" />
            </div>
            <div class="opacity-fill" *ngIf="brand.brandHovered"></div>
            <div class="brand-name flex-center">
              <div class="text">
              {{ ((brand.brandHovered ? language.visit : (brand.name.length > 17 ? brand.name.slice(0, 17) + "..." : brand.name))) }}
              </div>
              <div class="see-more-brand">
                +
              </div>
            </div>
          </a>
        </div>
      </div> 
    </ng-container>
  </section>
 
</div>

<app-popup *ngIf="popup" [(hideCounters)]="homeModelResponse.hideCounters" [(popup)]="popup" [mediaId]="mediaId"
  [autoplay]="autoplay"></app-popup>
<app-loader *ngIf="loader"></app-loader>