<section class="news-group2" [ngClass]="{ 'mirrored': mirrored }">
    <div class="big-container">
      <app-story-code *ngIf="group.length > 0" [story]="group[0]" [config]="config"
        [customClasses]="'two-thirds'"></app-story-code>
      <app-story-code *ngIf="group.length > 3" [story]="group[3]" [config]="config"
        [customClasses]="'one-third'"></app-story-code>
    </div>
    <div class="small-container">
      <app-story-code *ngIf="group.length > 1" [story]="group[1]" [config]="config"
        [customClasses]="'one-half'"></app-story-code>
      <app-story-code *ngIf="group.length > 2" [story]="group[2]" [config]="config"
        [customClasses]="'one-half'"></app-story-code>
    </div>
  </section>