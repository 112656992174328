<!-- <div class="bkg" [ngClass]="{ active: bkg }"></div> -->
<section class="brands flex-center">
  <h1 class="hidden-h1">Brands</h1>

  <section class="banner own-your-own-dna">
    <div class="left-side">
      <h2 class="title">OWN YOUR <br> OWN DNA //</h2>
    </div>
    <div class="right-side">
      <div class="text">
        Check out the latest and greatest news from global brands. <br>
        Browse our ever-growing roster to access and download royalty-free content.
      </div>
      <a *ngIf="user.id === 0" routerLink="/sign-in"
        routerLinkActive="active" (click)="rerouteToLogin(language.signIn)">
        <div class="lowercase button">Sign up today</div>
      </a>
      <a *ngIf="user.id != 0" routerLink="/profile">
        <div class="lowercase button">We already got you!</div>
      </a>
    </div>
  </section>

  <section class="filters-container">
    <div class="search-by-brand-container">
      <input [(ngModel)]="query" placeholder="{{ language.search }}" />
      <div class="search-icon"></div>
    </div>
    <!-- Order by: DNA--> 
    <div class="select-date order-by-container">
      <div class="filter-select-container" (change)="onSortChange($event.target.value)">
        <select class="filter-select" [(ngModel)]="sortBy" name="orderBy">
          <option value="" selected data-default disabled>Order by</option>
          <option value="title-ASC">Title Ascending </option>
          <option value="title-DESC">Title Descending </option>
          <option value="date-ASC">Date Ascending </option>
          <option value="date-DESC">Date Descending </option>
        </select>
      </div>
    </div>
    
    <div class="filter-button-container" [ngClass]="markets" *ngIf="markets?.length > 0" (click)="openFilters()">
      <img src="/assets/icons/filter-logo.svg">
      <span>Filter by</span>
    </div>
  </section>


  <div class="content-wrapper">
    <!-- <div class="bkg" [ngClass]="{ active: bkg }" (click)="reverseCards()"></div> -->

    <div class="brand-feed">
      <div class="brand-wrapper related-centers-wrapper flex">
        <!-- <div class="brand-box-container"> -->
          <div class="brand-box" *ngFor="let brand of visibleBrands | search : 'name' : query"
            [ngClass]="{ flipped: brand.flip }">

            <div class="brand-image flex-center front-side flip">
              <img class="brand-image flex-center" src="{{ brand.relatedMcBrandsLogo }} " alt="{{ brand.name }} Logo" />
              <div class="more-brand-info open" (click)="flipCard(brand.id)">+</div>
            </div>
              
            <div class="back-side flip" (click)="flipCard(brand.id)">
              <!-- Chequeo si es EasyJet (14), o si es Angels Envy (191) -->
              <img *ngIf="brand.id != 14 || brand.id != 191" class="brand-name flex-center" src="{{ brand.logoUrl }}" alt="{{ brand.name }} Logo">
              <img *ngIf="brand.id == 14 || brand.id == 191" class="brand-name flex-center" src="{{ brand.altLogoUrl }}" alt="{{ brand.name }} Logo">
              <div class="brand-text">
                <!-- {{ brand.logoUrl }} 
                /assets/images/dna/brand-logo.png 
              /assets/images/dna/inside-brands-test.png -->
                {{ brand.description }}
              </div>
              <div class="brand-read-more" *ngIf="!brand.mediaCenterUrl" (click)="goToMc(brand.mediaCenterUrl, brand.id, brand.name)">Read more</div>
              <div class="brand-read-more" *ngIf="brand.mediaCenterUrl && brand.id != 40 && brand.id != 58 && brand.id != 34" (click)="goToMc(brand.mediaCenterUrl, brand.id, brand.name)">Visit site</div>

              <!-- The Economist id: 40 -->
              <div class="brand-read-more" *ngIf="brand.id == 40"> <a href="https://www.economistgroup.com/group-news?page=1&perPage=8" target="_blank" style="text-decoration:none">Visit site</a></div>
              <!-- Virgin id: 58, Ralph Lauren: 34 -->
              <div class="brand-read-more" *ngIf="brand.id == 58 || brand.id == 34" (click)="goToMc(null, brand.id, brand.name)">Read more</div>

              <div class="more-brand-info-back close" >+</div>
            </div>
          </div>
        <!-- </div> -->
        <div class="no-result" *ngIf="searchResult"
         [ngStyle]="{ 'font-family': styles.style.fonts.footerText }">
          {{ language.noBrandsInCustomCategory }}
        </div>
      </div>
    </div>
  </div>
</section>
<app-loader *ngIf="loader"></app-loader>

<div class="bkg-filter" [ngClass]="{ active: bkgFilters }"></div>
<section class="open-filters-container" [ngClass]="{ open: this.filters }">
  <div class="filterHeader">
      <div class="filterTitle">Filter by</div>
      <div id="closeFilter" (click)="closeFilters()">
          X
      </div>
  </div>

  <div id="filters">

    <!-- SELECT MARKET  -->
    <!-- <div class="filterDropdown" *ngIf="!isInMarketPage && markets?.length > 0"> -->
    <div class="filterDropdown" *ngIf="markets?.length > 0">
      <div class="filterNameDropdown" (click)="toggleDropdowFilter('markets')">
        <div class="filterNameText">Markets</div>
        <div class="openCloseFilter">+</div>
      </div>

      <div class="filtersContainer" [ngClass]="{ open: this.dropdownFilterMarkets }">
        <div id="all-markets-filter" class="categoryDiv">
          <!-- <div class="select-market" *ngIf="!isInMarketPage && markets?.length > 0"> -->
          <div class="select-market">
            <div class="filter-select-container">
              <select class="filter-select" name="markets" [(ngModel)]="selectedMarket">
                <option value="0">All</option>
                <option *ngFor="let market of markets" value="{{ market.marketId }}">
                  {{ market.marketName }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

   <!-- SELECT CATEGORY  -->
   <div class="filterDropdown select-category" *ngIf="searchModelData?.customCategories?.length > 0">
      <div class="filterNameDropdown" (click)="toggleDropdowFilter('categories')">
        <div class="filterNameText">Category</div>
        <div class="openCloseFilter">+</div>
      </div>
      <div id="brandSelect" class="filter-select filtersContainer" [ngClass]="{ open: this.dropdownFilterCategory }" [(ngModel)]="selectedCustomCategory">
        <div class="categoryDiv">
          <div class="select-date " *ngIf="searchModelData?.customCategories?.length > 0">
            <div class="filter-select-container">
              <select class="filter-select" [(ngModel)]="selectedCustomCategory" name="category">
                <option value="0">{{ language.selectCategory }}</option>
                <option *ngFor="let category of searchModelData.customCategories" value="{{ category.id }}">
                  {{ category.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div id="cleanFiltersContainer">
      <div class="tr-3" id="cleanFilters" (click)="clearFilters(); toggleFilters()">
        {{ language.clearFilters || 'Clear Filters' }}
      </div>
      <!-- getNews(p = 1, true);  -->
      <div class="tr-3" id="applyFilters" (click)="toggleFilters()">
        {{ language.applyFilters || 'Apply Filters' }}
      </div>
  </div>

</section>