import { Component, Input, OnInit } from '@angular/core';
import { LatestStoriesModel } from "../../homepage/home-model";

declare var $: any;

@Component({
  selector: 'app-news-group2',
  templateUrl: './news-group2.component.html',
  styleUrls: ['./news-group2.component.less']
})
export class NewsGroupComponent2 implements OnInit {
  @Input() group: LatestStoriesModel[];
  @Input() config: any;
  @Input() mirrored: boolean;

  constructor() {
  }

  ngOnInit() {}
}
