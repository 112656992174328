import { WebNewsModel } from "../../routes/news/news-model";
import { WebManageMediaModel } from "../../download/download-model";
import { environment } from "../../../environments/environment";

export class SearchModel {
  public subClients: ClientLookupModel[];
  public mainCategories: BaseLookupModel[];
  public customCategories: CustomCategoryLookupModel[];
  public searchModel: WebSearchModel;
  public hasCustomCategories: boolean;
  public hasMainCategories: boolean;

  constructor() {
    this.searchModel = new WebSearchModel();
    this.subClients = [];
  }
}

export class ClientLookupModel {
  public parentId: number;
  public id: number;
  public label: string;

  //added new properties
  public checked: boolean;
}

export class BaseLookupModel {
  public id: number;
  public label: string;

  //added new properties
  public checked: boolean;
}

export class CustomCategoryLookupModel {
  public parentId: number;
  public mainCategoryId: number;
  public clientId: number;
  public id: number;
  public label: string;

  //added new properties
  public marked: boolean;
}

export class WebSearchModel {
  public stories: boolean;
  public media: boolean;
  public images: boolean;
  public video: boolean;
  public document: boolean;
  public audio: boolean;
  public dateFrom: string;
  public dateTo: string;
  public searchText: string;
  public mainCategoryIds: number[];
  public customCategoryIds: number[];
  public subClientIds: number[];
  public page: number;
  public pageSize: number;
  public languageId: number;
  public marketId: number;
  public codeFrom: string;
  public codeTo: string;
  public sortCode: string;
  public sortDate: string;

  constructor() {
    this.stories = true;
    this.media = true;
    this.images = true;
    this.video = false;
    this.document = false;
    this.audio = false;
    this.dateFrom = null;
    this.dateTo = null;
    this.searchText = "";
    this.customCategoryIds = [];
    this.mainCategoryIds = [];
    this.subClientIds = [];
    this.marketId = null;
    this.codeFrom = null;
    this.codeTo = null;
    this.sortCode = null;
    this.sortDate = null;
  }
}

export class SearchResultModel {
  public stories: WebNewsModel[];
  public media: WebManageMediaModel[];
  public mustRegisterToAccess: boolean;
  public mustRegisterToDownload: boolean;
  public maxPages: number;
}

export class AlgoliaNewsRecords {
  public storyId: number;
  public title: string;
  public addedToCart: boolean;
  public mainCategories: any[];
  public customCategories: any[];
  public type: number;
  public tags: any[];
  public status: number;
  public mediaThumbUrl: string;
  public mediaOriginalUrl: string;
  public code?: string;

  static convertToWebNewsModel(record: AlgoliaNewsRecords): WebNewsModel {
    const result = new WebNewsModel();
    result.storyId = record.storyId;
    result.staggingStoryId = record.storyId;
    result.storyTitle = record.title;
    result.thumbUrl = record.mediaThumbUrl;
    result.fullHDUrl = record.mediaOriginalUrl;
    result.category = record.mainCategories
      .map((c) => c.name)
      .concat(record.customCategories.map((c) => c.name));
    result.code = record.code;
    return result;
  }
}

export class AlgoliaMediaRecords {
  public objectID: string;
  public title: string;
  public addedToCart: boolean;
  public mainCategories: any[];
  public type: number;
  public tags: any[];
  public status: number;
  public typeName: string;
  public urls: any[];
  public storyMediaIds: any[];
  public isPublished: boolean;
  public publishedIds: number[];

  static convertToWebManageMediaModel(
    record: AlgoliaMediaRecords
  ): WebManageMediaModel {
    const result = new WebManageMediaModel();
    if (record.isPublished) {
      result.id = record.publishedIds && record.publishedIds.length > 0 ? record.publishedIds[0]
        : parseInt(record.objectID);
    } else {
      result.id =
        record.storyMediaIds && record.storyMediaIds.length > 0
          ? record.storyMediaIds[0]
          : parseInt(record.objectID);
    }
    result.name = record.title;
    result.mediaType = record.typeName;
    result.mediaType = record.typeName;
    result.isPublished = record.isPublished;
    if (record.urls.find((url) => url.format == 2))
      result.thumbUrl = record.urls.find((url) => url.format == 2).url;
    if (
      record.typeName === "image" &&
      record.urls.find((url) => url.format == 3)
    )
      result.fullHDUrl = record.urls.find((url) => url.format == 3).url;
    return result;
  }
}
