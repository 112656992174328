import { Component, HostListener, NgZone, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { McService } from "../../shared/services/mc-service";
import { UrlHelper } from "../../shared/helpers/url-helper";
import { PageStyle } from "../../models/style/style.model";
import { TokenManagerService } from "../../shared/services/token-manager.service";
import { FontsAndColorsService } from "../../shared/services/fonts-and-colors.service";
import { GoogleAnalyticsEventsService } from "../../shared/services/google-analytics-events.service";
import { LanguageService } from "../../shared/services/language.service";
import { Language } from "../../models/language/language.model";
import { WebNewsListModel, WebNewsModel } from "../news/news-model";
import { WebManageMediaModel } from "../../download/download-model";
import { DownloadAddToModel, MediaModel } from "../media/media-model";
import { AlgoliaService } from "../../shared/services/algolia.service";
import { SearchModel, WebSearchModel } from "../../header/search/search-model";




// import { WebMediaListModel, WebMediaModel } from "../media/media-model";

declare var $: any;

@Component({
  selector: "brand-stories-6",
  templateUrl: "./brand-stories-6.component.html",
  styleUrls: ["./brand-stories-6.component.less"],
})
export class BrandStoriesComponent6 implements OnInit {
  news: any[];
  grid: string;
  dropdown = false;
  loader: boolean;
  subcategory = false;
  styles = new PageStyle();
  user: any;
  showPagination = false;
  socials: string[] = [];
  currentUrl: string;
  newsHeadlineLength = 10;
  webListResponse = new WebNewsListModel();
  p = 1;
  itemsPerPage: number;
  itemsPerPageNews: number;
  itemsPerPageMedia: number;
  sortBy: string = "";
  forDownload: any[] = [];
  downloadIds: any[] = [];
  totalItems: number;
  maxPageMedia = 1;
  mainStory: any;
  getLinks: string;


  shortDescriptionLength: number;
  language: Language;
  sLng: number;
  clientName: string;
  clientId: number;
  clientDescription: string;
  storyGroups: WebNewsModel[][] = [[]];
  webListMediaResponse = new MediaModel();
  mediaGroups: WebManageMediaModel[][] = [[]];
  searchModelData = new SearchModel();

  constructor(
    private router: Router,
    private mcService: McService,
    private languageService: LanguageService,
    ngZone: NgZone,
    public fontsAndColorsService: FontsAndColorsService,
    private googleAnalytics: GoogleAnalyticsEventsService,
    private activatedRoute: ActivatedRoute,
    private algoliaService: AlgoliaService

  ) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.clientName = params["brand"];
    });

    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();

    this.languageService.language.subscribe((language) => {
      this.language = language;
    });

    window.onresize = (e) => {
      ngZone.run(() => {
        if (window.innerWidth <= 1100 && window.innerWidth >= 800) {
          this.grid = "Columns3";
        } else if (window.innerWidth <= 800) {
          this.grid = "Columns2";
        } else {
          this.grid = this.webListResponse.newsGridType;
        }
        // //
      });
    };
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.resize();
  }
  ngOnInit() {
    this.getClientId();

    this.sLng = Number(localStorage.getItem("lng"));
    this.currentUrl = window.location.href;
    this.loader = true;
    window.scrollTo(0, 0);
    this.user = TokenManagerService.getUser();

    this.getBrandStories(this.p);
  }

  getClientId(){
    this.mcService.getClientIdByName(this.clientName).subscribe(
      (response: any) => {
        this.clientId = response.id;
        this.clientDescription = response.description;
        this.getBrandMedias(this.p);

      },
      (error) => {
        //
      }
    );
  }

  changeColorr(e) {
    e.target.style.background = this.styles.style.mainButton3HoverColor;
  }

  changeColor(e) {
    e.target.style.background = this.styles.style.mainButton3DefaultColor;
  }

  getStoryUrl(storyId) {
    return (
      this.currentUrl.replace("News", "story") +
      "/" +
      storyId +
      "/" +
      UrlHelper.getUrlTitle(
        this.webListResponse.news.find((x) => x.staggingStoryId == storyId)
          .storyTitle
      )
    );
  }

  getStyle() {
    this.mcService.getStyle().subscribe(
      (response: any) => {
        //
        this.styles = response;
      },
      (error) => {
        //
      }
    );
  }

  getBrandStories(page) {
    this.loader = true;
    if (this.sLng == 0) {
      this.sLng = 1;
    }
    this.mcService.getBrandStories(this.clientName, page, this.sLng).subscribe(
      (response: any) => {
        this.webListResponse = response;
        if (this.webListResponse.socialSharing != null) {
          this.socials = this.webListResponse.socialSharing;
        }
        this.mainStory = this.webListResponse.news[0];
        this.groupStories();
        this.resize();

        this.itemsPerPageNews = this.webListResponse.maxNewsPerPage;
        this.itemsPerPage = this.webListResponse.maxNewsPerPage;
        this.loader = false;

        setTimeout(() => {
          const element = document.getElementsByClassName(
            "current"
          ) as HTMLCollectionOf<HTMLElement>;
          if (element[0]) {
            element[0].style.backgroundColor =
              "" + this.styles.style.mainIdentifyingColor;
          }
        });
      },
      (error) => {
        //
      }
    );
      
  }

  groupStories() {
    let count = 0;
    this.storyGroups = [[]];
    const storiesToGroup = this.webListResponse.news.slice(1); //sacamos la primer story

    for (const story of storiesToGroup) {
      if (this.storyGroups[count].length >= 4) count++;
      if (!this.storyGroups[count]) this.storyGroups[count] = [];
      this.storyGroups[count].push(story);
    }
  }
  
  public mediaFiles: any[] = [];

  getBrandMedias(page, type = null, isReset = false) {
    this.searchModelData.searchModel.page = page;
    this.searchModelData.searchModel.pageSize = this.itemsPerPage;
    this.searchModelData.searchModel.languageId = this.sLng;
    let typeId = this.getMediaTypeId(type);
    let dateFrom = null;
    let dateTo = null;
    this.algoliaService.searchMediaWithFilters(
      this.searchModelData.searchModel.searchText,
      this.searchModelData.searchModel.page,
      this.sortBy,
      this.searchModelData.searchModel.pageSize,
      typeId,
      this.searchModelData.searchModel.subClientIds[0] = this.clientId,
      this.searchModelData.searchModel.marketId == 0 ? null : this.searchModelData.searchModel.marketId,
      dateFrom,
      dateTo
    ).then((response: any) => {
      const mappedResults = response.results.map((x) => {
        let id;
        if (x.isPublished) {
          id = x.publishedIds && x.publishedIds.length > 0 ? x.publishedIds[0]
            : parseInt(x.objectID);
        } else {
          id =
            x.storyMediaIds && x.storyMediaIds.length > 0
              ? x.storyMediaIds[0]
              : parseInt(x.objectID);
        }
        const fullHdUrl = x.urls.find((x) => x.format === 4);
        const originalUrl = x.urls.find((x) => x.format === 5);
        const thumbUrl = x.urls.find((x) => x.format === 2);
        return {
          mediaType: this.getMediaName(x.type),
          name: x.title,
          id: id,
          thumbUrl: thumbUrl ? thumbUrl.url : null,
          fullHDUrl: x.typeName === "image" && x.urls.find((url) => url.format == 3) ? x.urls.find((url) => url.format == 3).url : null,
          downloadLink: fullHdUrl ? fullHdUrl.url : originalUrl ? originalUrl.url : x.urls.length > 0 ? x.urls[0].url : null,
          typeName: x.typeName,
          isPublished: x.isPublished,
          relativeShareUrl: '/results/' + id + '?isPublished=' + x.isPublished,
          urls: x.urls,
        }
      });
      if (this.mediaFiles && !isReset) {
        this.mediaFiles = this.mediaFiles.concat(mappedResults);
      } else {
        this.mediaFiles = mappedResults;
        this.downloadIds = [];
        this.forDownload = [];
      }

      this.totalItems = response.total;
      this.maxPageMedia = Math.ceil(response.total / this.itemsPerPage);
      for (const x of this.mediaFiles) {
        if (x.mediaType.toLowerCase() === "document") {
          x.thumbUrl = "assets/images/document_default.png";
        } else if (x.mediaType.toLowerCase() === "audio") {
          x.thumbUrl = "assets/images/audio_default.png";
        }
        const fullHdUrl = x.urls.find((x) => x.format === 4);
        const originalUrl = x.urls.find((x) => x.format === 5);
        let downloadUrl = "";
        if (fullHdUrl) {
          downloadUrl = fullHdUrl.url;
        } else if (originalUrl) {
          downloadUrl = originalUrl.url;
        } else if (x.urls.length > 0) {
          downloadUrl = x.urls[0].url;
        }
        if (downloadUrl) {
          this.forDownload.push(downloadUrl);
          this.downloadIds.push(x.id);
          x.downloadUrl = downloadUrl;
        }
      }
      this.mediaGroups = [[]];
      this.groupMedia();
    });

  }


  groupMedia() {
    let count = 0;
    this.mediaGroups = [[]];
    for (const media of this.mediaFiles) {
      if (this.mediaGroups[count].length >= 4) count++;
      if (!this.mediaGroups[count]) this.mediaGroups[count] = [];
      this.mediaGroups[count].push(media);
    }

  }

  resize() {
    if (this.webListResponse.newsGridType === "Columns2") {
      this.newsHeadlineLength = 155;
      if (window.innerWidth <= 1500 && window.innerWidth >= 1200) {
        this.newsHeadlineLength = 120;
      }
      if (window.innerWidth <= 1200 && window.innerWidth >= 700) {
        this.newsHeadlineLength = 110;
      }
    }
    if (this.webListResponse.newsGridType === "Columns3") {
      this.newsHeadlineLength = 90;
      this.shortDescriptionLength = 140;

      if (window.innerWidth <= 1500 && window.innerWidth >= 1200) {
        this.newsHeadlineLength = 65;
        this.shortDescriptionLength = 95;
      }
      if (window.innerWidth <= 1200 && window.innerWidth >= 1000) {
        this.newsHeadlineLength = 120;
        this.shortDescriptionLength = 95;
      }
      if (window.innerWidth <= 1000 && window.innerWidth >= 700) {
        this.newsHeadlineLength = 75;
        this.shortDescriptionLength = 75;
      }
    }
    if (this.webListResponse.newsGridType === "Columns4") {
      this.newsHeadlineLength = 60;
      if (window.innerWidth <= 1500 && window.innerWidth >= 1200) {
        this.newsHeadlineLength = 15;
      }
      if (window.innerWidth <= 1200 && window.innerWidth >= 1000) {
        this.newsHeadlineLength = 110;
      }
      if (window.innerWidth <= 1000 && window.innerWidth >= 700) {
        this.newsHeadlineLength = 100;
      }
    }
  }

  setColor(e?) {
    setTimeout(() => {
      const element = document.getElementsByClassName(
        "current"
      ) as HTMLCollectionOf<HTMLElement>;
      if (element[0]) {
        element[0].style.backgroundColor =
          "" + this.styles.style.mainIdentifyingColor;
      }
    }, 100);
  }

  showSubCategory(news) {
    news.subCategory = !news.subCategory;
  }

  openDropdown(id) {
    for (const x of this.webListResponse.news) {
      if (x.storyId === id) {
        x.shareDropdown = !x.shareDropdown;
      }
    }
  }

  readMore(id) {
    localStorage.setItem("storyId", JSON.stringify(id));
    if (localStorage.getItem("Token") === null) {
      this.user.id = null;
    }
    if (this.user.cmsUser == null) {
      this.googleAnalytics.emitEvent(
        "story/" + id.staggingStoryId,
        "view",
        this.user.id ? this.user.id.toString() : "anonymous"
      );
      // this.mcService.addViewToNews(id.storyId, this.user.id)
      //   .subscribe((response) => {
      //
      //     },
      //     (error2 => {
      //       //
      //     }));
    }
    this.router.navigate([
      "/story/" +
        id.staggingStoryId +
        "/" +
        UrlHelper.getUrlTitle(
          this.webListResponse.news.find(
            (x) => x.staggingStoryId == id.staggingStoryId
          ).storyTitle
        ),
    ]);
  }

  twoGrid() {
    if (
      (this.grid === "Columns3" && this.itemsPerPage === 3) ||
      (this.grid === "Columns4" && this.itemsPerPage === 4)
    ) {
      this.grid = "Columns2";
      this.itemsPerPage = 2;
    }
    if (
      (this.grid === "Columns3" && this.itemsPerPage === 6) ||
      (this.grid === "Columns4" && this.itemsPerPage === 8)
    ) {
      this.grid = "Columns2";
      this.itemsPerPage = 4;
    }
    if (
      (this.grid === "Columns3" && this.itemsPerPage === 9) ||
      (this.grid === "Columns4" && this.itemsPerPage === 12)
    ) {
      this.grid = "Columns2";
      this.itemsPerPage = 6;
    }
    if (
      (this.grid === "Columns3" && this.itemsPerPage === 12) ||
      (this.grid === "Columns4" && this.itemsPerPage === 3)
    ) {
      this.grid = "Columns2";
      this.itemsPerPage = 8;
    }
    this.setColor();
    this.newsHeadlineLength = 155;
    if (window.innerWidth <= 1500 && window.innerWidth >= 1200) {
      this.newsHeadlineLength = 120;
    }
  }

  threeGrid() {
    if (
      (this.grid === "Columns2" && this.itemsPerPage === 2) ||
      (this.grid === "Columns4" && this.itemsPerPage === 4)
    ) {
      this.grid = "Columns3";
      this.itemsPerPage = 3;
    }
    if (
      (this.grid === "Columns2" && this.itemsPerPage === 4) ||
      (this.grid === "Columns4" && this.itemsPerPage === 8)
    ) {
      this.grid = "Columns3";
      this.itemsPerPage = 6;
    }
    if (
      (this.grid === "Columns2" && this.itemsPerPage === 6) ||
      (this.grid === "Columns4" && this.itemsPerPage === 12)
    ) {
      this.grid = "Columns3";
      this.itemsPerPage = 9;
    }
    if (
      (this.grid === "Columns2" && this.itemsPerPage === 8) ||
      (this.grid === "Columns4" && this.itemsPerPage === 16)
    ) {
      this.grid = "Columns3";
      this.itemsPerPage = 12;
    }
    this.setColor();
    this.newsHeadlineLength = 90;
    if (window.innerWidth <= 1500 && window.innerWidth >= 1200) {
      this.newsHeadlineLength = 65;
    }
    if (window.innerWidth <= 1200 && window.innerWidth >= 700) {
      this.newsHeadlineLength = 12;
    }
  }

  fourGrid() {
    if (
      (this.grid === "Columns2" && this.itemsPerPage === 2) ||
      (this.grid === "Columns3" && this.itemsPerPage === 3)
    ) {
      this.grid = "Columns4";
      this.itemsPerPage = 4;
    }
    if (
      (this.grid === "Columns2" && this.itemsPerPage === 4) ||
      (this.grid === "Columns3" && this.itemsPerPage === 6)
    ) {
      this.grid = "Columns4";
      this.itemsPerPage = 8;
    }
    if (
      (this.grid === "Columns2" && this.itemsPerPage === 6) ||
      (this.grid === "Columns3" && this.itemsPerPage === 9)
    ) {
      this.grid = "Columns4";
      this.itemsPerPage = 12;
    }
    if (
      (this.grid === "Columns2" && this.itemsPerPage === 8) ||
      (this.grid === "Columns3" && this.itemsPerPage === 12)
    ) {
      this.grid = "Columns4";
      this.itemsPerPage = 16;
    }
    this.setColor();
    this.newsHeadlineLength = 60;
    if (window.innerWidth <= 1500 && window.innerWidth >= 1200) {
      this.newsHeadlineLength = 42;
    }
    if (window.innerWidth <= 1200 && window.innerWidth >= 700) {
      this.newsHeadlineLength = 42;
    }
  }

  changePage(page) {
    this.p = page;
    this.getBrandStories(this.p);
  }

  getMediaTypeId(mediaName: string): number {
    switch (mediaName) {
      case "Image":
        return 3;
      case "Video":
        return 2;
      case "Document":
        return 4;
      case "Audio":
        return 1;
      default:
        return null;
    }
  }

  getMediaName(mediaTypeId: number): string {
    switch (mediaTypeId) {
      case 3:
        return "Image";
      case 2:
        return "Video";
      case 4:
        return "Document";
      case 1:
        return "Audio";
      default:
        return null;
    }
  }

  getLink(section) {
    if (window.location.hostname == "localhost") {
      this.getLinks = window.location.protocol + "//" + window.location.hostname + ":" + 
                      window.location.port + window.location.pathname + section;
    } else {
      this.getLinks = window.location.protocol + "//" + window.location.hostname + 
                      window.location.pathname + section;
    }
  }
  

}
