import { HttpClient } from "@angular/common/http";

import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { McService } from '../../../shared/services/mc-service';
import { Router } from '@angular/router';
import { TokenManagerService } from '../../../shared/services/token-manager.service';
import { StorageService } from '../../../shared/services/storage.service';
// import { HTMLElement } from "@types/chai";
declare var $: any;
import { LanguageService } from '../../../shared/services/language.service';
import { Language } from '../../../models/language/language.model';
import { PageStyle } from "../../../models/style/style.model";
import { FontsAndColorsService } from "../../../shared/services/fonts-and-colors.service";
import { 
  AlgoliaNewsRecords,
  AlgoliaMediaRecords,
  ClientLookupModel,
  SearchModel,
  SearchResultModel,
  WebSearchModel
} from "../../../header/search/search-model";
import { AlgoliaService } from "../../../shared/services/algolia.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-filters-tab",
  templateUrl: "./filters-tab.component.html",
  styleUrls: ["./filters-tab.component.less"],
})
export class FiltersTabComponent implements OnInit {
  @Output() searchChange = new EventEmitter<boolean>();
  dropdown = false;
  language: Language;
  categoryLabel: string;
  category = ['company', 'company', 'company'];
  // visibleBrands: BrandsManageModel[] = [];
  noBrands = false;
  searchResult: boolean;
  styles = new PageStyle();
  search = false;
  filters = false;
  bkg = false;
  bkgFilters = false;

  // styles = new PageStyle();
  user: any;

  constructor(
    private mcService: McService,
    private router: Router,
    // private http: HttpClient,
    // ngZone: NgZone,
    private sService: StorageService,
    private fontsAndColorsService: FontsAndColorsService
  ) {
    this.styles = fontsAndColorsService.getPageStyleInfo();
  }

  ngOnInit() {
    this.sService.getSearch().subscribe(term => {
      this.searchResult = term;
    });
    this.categoryLabel = this.language.categories;
  }

  goToMc(url, clientId) {
    this.mcService.redirectToMc(clientId).subscribe(
      (response: any) => {
        const token = response.token;
        if (url) 
          window.location.href = url + '&token=' + token;
      },
      (error) => {
        if (url)
          window.location.href = url;
      });
  }

  openFilters(){
    this.filters = true;
    this.bkgFilters = true;
  }
  closeFilters(){
    this.filters = false;
    this.bkgFilters = false;
  }
}
