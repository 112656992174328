<div class="news-box" [ngClass]="additionalClasses" [ngClass]="customClasses + (template === 4 ? ' homepage-4' : '')">
    <div class="featured-image">
      <span class="image-container">
        <picture *ngIf="!story.embeddedCode">
          <source srcset="{{ webPHelper.changeFileToWebP(getSource(story)) }}" type="image/webp" />
          <source srcset="{{ story.thumbUrl }}" type="image/jpeg" />
          <img (error)="webPHelper.removeWebP($event)" src="{{ getSource(story) }}" alt="Story featured image" />
        </picture>
        <div *ngIf="story.embeddedCode" [innerHTML]="story.embeddedCode" class="results-story-iframe"></div>
        <div class="news-overlay" *ngIf="!story.embeddedCode"></div>
        <div *ngIf="showCategory && story?.category != null" class="category-wrapper">
          <!-- <div class="date" *ngIf="template === 4">
            {{ story.publishedDate | date : "dd MMMM yyyy" }}
          </div>
          <div class="date" *ngIf="template === 4">
            ·
          </div> -->
          <div class="category">
            {{ story.category[0] }}
          </div>
        </div>
        <div *ngIf="showCounters" class="action-icons flex-center">
          <div *ngIf="!config.hideCounters">
            {{ story.views }}
          </div>
          <div *ngIf="!config.hideCounters" class="view-icon">
            <i class="fal fa-eye"></i>
          </div>
        </div>
      </span>
      <div class="button-hover-overlay" (click)="readMore()">
        <div class="button-container">
          <div class="add-to-wishlist" (click)="toggleSavedStory(story.storyId)">
            <i *ngIf="!inSavedStories(story.storyId)" class="far fa-heart"></i>
            <i *ngIf="inSavedStories(story.storyId)" class="fas fa-heart"></i>
            {{ language.addStoryToFav }}
          </div>
  
          <app-share *ngIf="config.socialSharing?.length > 0" [image]="story.thumbUrl" [title]="story.storyTitle"
            [description]="story.shortDescription" [big]="false" [horizontal]="true" [shared]="story.shared"
            [sbUrl]="getStoryUrl()" [staggingStoryId]="story.staggingStoryId" [mediaId]="story.mediaId"
            [storyId]="story.storyId" [socials]="config.socialSharing">
          </app-share>
        </div>
      </div>
    </div>
  
    <div class="info">
      <div class="datum" *ngIf="!story.hideStoryDate && template === 4" (click)="readMore()">
        {{ story.publishedDate | date : "dd MMMM yyyy" }}
      </div>
      <div class="story-title" (click)="readMore()">
        ({{ story.code }}) {{ shortenTitle(story.storyTitle) }}
      </div>
      <div class="datum" *ngIf="!story.hideStoryDate && template !== 4" (click)="readMore()">
        {{ story.publishedDate | date : "dd MMMM yyyy" }}
      </div>
      <hr />
  
      <div class="action">
        <div class="wrapper">
          <a (click)="readMore()" class="read-more">
            {{ language.readMore }}
          </a>
  
          <span>
            <!-- <div class="add-to-wishlist" (click)="toggleSavedStory(story.storyId)">
              <i *ngIf="!inSavedStories(story.storyId)" class="far fa-heart"></i>
              <i *ngIf="inSavedStories(story.storyId)" class="fas fa-heart"></i>
            </div> -->
  
            <app-share *ngIf="config.socialSharing.length > 0" [image]="story.thumbUrl" [title]="story.storyTitle"
              [description]="story.shortDescription" [big]="false" [vertical]="true" [shared]="story.shared"
              [sbUrl]="getStoryUrl()" [staggingStoryId]="story.staggingStoryId" [mediaId]="story.mediaId"
              [storyId]="story.storyId" [socials]="config.socialSharing">
            </app-share>
          </span>
        </div>
      </div>
    </div>
  </div>