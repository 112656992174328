import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

import algoliasearch from "algoliasearch";
import { forkJoin } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AlgoliaService {
  private searchClient: any;
  private storiesIndex: any;
  private mediaIndex: any;
  private clientId: number;
  private superMediaCenter: boolean;
  private storiesAscIndex: any;
  private storiesTitleAscIndex: any;
  private storiesTitleDescIndex: any;

  constructor() {
    this.searchClient = algoliasearch(
      environment.algoliaApplicationId,
      environment.algoliaApiKey
    );
    this.storiesIndex = this.searchClient.initIndex(
      environment.algoliaStoriesIndex
    );
    this.mediaIndex = this.searchClient.initIndex(
      environment.algoliaMediaIndex
    );
    this.storiesAscIndex = this.searchClient.initIndex(
      environment.algoliaStoriesAsc
    )
    this.storiesTitleAscIndex = this.searchClient.initIndex(
      environment.algoliaStoriesTitleAsc
    )
    this.storiesTitleDescIndex = this.searchClient.initIndex(
      environment.algoliaStoriesTitleDesc
    )
    this.superMediaCenter = false;
  }

  setClientId(id: number) {
    this.clientId = id;
  }

  setSuperMediaCenter() {
    this.superMediaCenter = true;
  }

  getSuperMediaCenter() {
    return this.superMediaCenter;
  }

  async searchStories(query: string, page: number) {
    const languageId = Number(localStorage.getItem("lng"));
    const currentMarket = JSON.parse(localStorage.getItem("currentMarket"));
    let filters = "";
    if (this.superMediaCenter) {
      filters =
        "(clientId = " +
        this.clientId +
        " OR parentClientId = " +
        this.clientId +
        " OR showOnDNASite = 1) AND languageId = " +
        languageId;
    } else {
      filters =
        "(clientId = " +
        this.clientId +
        " OR parentClientId = " +
        this.clientId +
        ") AND languageId = " +
        languageId;
    }
    filters += " AND status = 1";
    if (currentMarket) {
      filters += " AND markets.id = " + currentMarket.id;
    }
    let results = await this.storiesIndex.search(query, {
      filters: filters,
      page: page - 1,
      hitsPerPage: 60,
    });
    if (!this.superMediaCenter) {
      // ***
      results.hits = results.hits.filter(
        (story) =>
          story.clientId === this.clientId ||
          (story.parentClientId === this.clientId &&
            story.showOnRelatedParentSite == true)
      );
    }
    return results;
  }

  async searchMedia(query: string, page: number) {
    let filters = "(hasStory=1 OR isPublished=1)";
    const languageId = Number(localStorage.getItem("lng"));
    const currentMarket = JSON.parse(localStorage.getItem("currentMarket"));
    if (!this.superMediaCenter) {
      const clientIdFilter = `(storyClientIds=${this.clientId} OR storyClientParentIds=${this.clientId})`;
      const directClientFilter = `(clientId=${this.clientId} OR parentClientId=${this.clientId})`;
      filters += ` AND (${clientIdFilter} OR ${directClientFilter})`;
    }

    if (currentMarket) {
      filters += " AND marketIds = " + currentMarket.id;
    }

    filters += " AND (languageIds = " + languageId + " OR languageIds = 0)"
    
    const results = await this.mediaIndex.search(query, {
      filters: filters,
      page: page - 1,
      hitsPerPage: 12,
    });
    return results;
  }

  async searchStoriesWithFilters(
    query: string,
    page: number,
    orderBy: string,
    clientId: number = null,
    marketId: number = null,
    customCategoryId: number = null,
    dateFrom: Date = null,
    dateTo: Date = null) {

    const languageId = Number(localStorage.getItem("lng"));
    const currentMarket = JSON.parse(localStorage.getItem("currentMarket"));
    let filters = "";
    if (this.superMediaCenter) {
      if (clientId) {
        filters =
          "clientId = " +
          clientId + " AND languageId = " +
          languageId;
      } else {
        filters =
          "(clientId = " +
          this.clientId +
          " OR parentClientId = " +
          this.clientId +
          " OR showOnDNASite = 1) AND languageId = " +
          languageId;
      }
    } else {
      if (clientId) {
        filters =
          "clientId = " +
          clientId + " AND languageId = " +
          languageId;
      } else {
        filters =
          "(clientId = " +
          this.clientId +
          " OR parentClientId = " +
          this.clientId +
          ") AND languageId = " +
          languageId;
      }
    }


    if (dateFrom) {
      filters += " AND storyTimestamp >= " + (dateFrom.getTime() / 1000)
    }

    if (dateTo) {
      filters += " AND storyTimestamp <= " + (dateTo.getTime() / 1000)
    }

    filters += " AND status = 1";

    if (marketId) {
      filters += " AND markets.id = " + marketId;
    } else if (currentMarket) {
      filters += " AND markets.id = " + currentMarket.id;
    }


    if (customCategoryId) {
      filters += " AND customCategories.customCategoryId = " + customCategoryId;
    }
    
    
    const obj={
      filters: filters,
      page: page - 1,
      hitsPerPage: 12,
    }
    if(orderBy == "title-ASC") return await this.storiesTitleAscIndex.search(query,obj );
    if(orderBy == "title-DESC") return await this.storiesTitleDescIndex.search(query,obj);
    if(orderBy == "date-ASC") return await this.storiesAscIndex.search(query,obj);
    return await this.storiesIndex.search(query,obj);
  }


  async searchMediaWithFilters(
    query: string,
    page: number,
    orderBy: string,
    pageSize: number,
    type: number = null,
    clientId: number = null,
    marketId: number = null,
    dateFrom: Date = null,
    dateTo: Date = null) {
    let filters = "(hasStory=1 OR isPublished=1) ";
    const languageId = Number(localStorage.getItem("lng"));
    const currentMarket = JSON.parse(localStorage.getItem("currentMarket"));
    if (this.superMediaCenter) {
      if (clientId) {
        filters +=
          " AND (clientId = " + clientId +
          " OR parentClientId = " + clientId +
          " OR storyClientIds = " + clientId + " OR storyClientParentIds = " + clientId + ") " +
          " AND showOnDNASite = 1";
      } else {
        filters +=
          " AND (clientId = " + this.clientId +
          " OR parentClientId = " + this.clientId +
          " OR showOnDNASite = 1" +
          " OR storyClientIds = " + this.clientId +
          " OR storyClientParentIds = " + this.clientId + ")";
      }
    } else {
      if (clientId) {
        filters +=
          " AND (clientId = " + clientId +
          " OR parentClientId = " + clientId +
          " OR storyClientIds = " + clientId +
          " OR storyClientParentIds = " + clientId + ") ";
      } else {
        filters +=
          " AND (clientId = " + this.clientId +
          " OR parentClientId = " + this.clientId +
          " OR storyClientIds = " + this.clientId +
          " OR storyClientParentIds = " + this.clientId + ")";
      }
    }

    if (currentMarket) {
      filters += " AND marketIds = " + currentMarket.id;
    }

    filters += " AND (languageIds = " + languageId + " OR languageIds = 0)"

    if (dateFrom) {
      filters += " AND timestamp >= " + (dateFrom.getTime() / 1000);
    }

    if (dateTo) {
      filters += " AND timestamp <= " + (dateTo.getTime() / 1000);
    }

    if (marketId) {
      filters += " AND marketsIds = " + marketId;
    }

    const audioObservable = this.mediaIndex.search(query, {
      filters: filters + " AND type = 1",
      page: page - 1,
      hitsPerPage: 0,
    });
    const videoObservable = this.mediaIndex.search(query, {
      filters: filters + " AND type = 2",
      page: page - 1,
      hitsPerPage: 0,
    });
    const imageObservable = this.mediaIndex.search(query, {
      filters: filters + " AND type = 3",
      page: page - 1,
      hitsPerPage: 0,
    });
    const documentObservable = this.mediaIndex.search(query, {
      filters: filters + " AND type = 4",
      page: page - 1,
      hitsPerPage: 0,
    });
    const resultsObservable = this.mediaIndex.search(query, {
      filters: type ? filters + " AND type = " + type : filters,
      page: page - 1,
      hitsPerPage: pageSize,
    });

    const data = {
      audios: 0,
      videos: 0,
      images: 0,
      documents: 0,
      total: 0,
      results: []
    }

    const response = forkJoin([audioObservable, videoObservable, imageObservable, documentObservable, resultsObservable])
    const results = await response.toPromise();
    data.audios = (results[0] as any).nbHits;
    data.videos = (results[1] as any).nbHits;
    data.images = (results[2] as any).nbHits;
    data.documents = (results[3] as any).nbHits;
    data.results = (results[4] as any).hits;
    data.total = data.audios + data.videos + data.images + data.documents;

    const obj={
      filters: filters,
      page: page - 1,
      hitsPerPage: 12,
    }
    if(orderBy == "title-ASC") return await this.storiesTitleAscIndex.search(query,obj );
    if(orderBy == "title-DESC") return await this.storiesTitleDescIndex.search(query,obj);
    if(orderBy == "date-ASC") return await this.storiesAscIndex.search(query,obj);

    return data;
  }

}