import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Language } from "../../../models/language/language.model";
import { PageStyle } from "../../../models/style/style.model";
import { LatestStoriesModel } from "../../../routes/homepage/home-model";

import { McService } from "../../../shared/services/mc-service";
import { WebNewsModel, WebNewsDetailsMediaModel, WebNewsDetailsModel } from "../../../routes/news/news-model";
import { HttpClient } from "@angular/common/http";
import { DownloadAddToModel } from "../../../routes/media/media-model";
// import { WebNewsDetailsMediaModel, WebNewsDetailsModel } from "../news-model";


import { ButtonsHelper } from "../../helpers/buttons-helper";
import { UrlHelper } from "../../helpers/url-helper";
import { FontsAndColorsService } from "../../services/fonts-and-colors.service";
import { GoogleAnalyticsEventsService } from "../../services/google-analytics-events.service";
import { LanguageService } from "../../services/language.service";
import { SavedStoryService } from "../../services/saved-story.service";
import { TokenManagerService } from "../../services/token-manager.service";
import { WebpHelperService } from "../../services/webp-helper.service";

import * as JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";

@Component({
  selector: "app-story-preview-5",
  templateUrl: "./story-preview-5.component.html",
  styleUrls: ["./story-preview-5.component.less"],
})
export class StoryPreviewComponent5 {
  @Input() story: LatestStoriesModel | WebNewsModel;
  @Input() config: any;
  @Input() gridClass: any = [];
  @Input() index: any = 0;
  @Input() grid: any = "";
  @Input() customClasses: string;
  @Input() template: number;

  filtered: WebNewsDetailsMediaModel[] = [];
  imageMedia: WebNewsDetailsMediaModel[] = [];
  audioMedia: WebNewsDetailsMediaModel[] = [];
  videoMedia: WebNewsDetailsMediaModel[] = [];
  documentMedia: WebNewsDetailsMediaModel[] = [];
  forDownload: any[] = [];
  downloadIds: any[] = [];
  webNewsDetailsResponse: WebNewsDetailsModel;
  filters: any;
  popup: boolean;
  loginPopup: boolean;
  media: any[];
  loader: boolean;
  disabledButton: boolean;
  mediaId: number;
  autoplay: boolean;
  allFiles: number;
  share: boolean;
  all: boolean;
  imageTab: boolean;
  videoTab: boolean;
  documentTab: boolean;
  audioTab: boolean;

  additionalClasses: any = [];
  styles = new PageStyle();
  language: Language;
  user: any;
  newsHeadlineLength: number = 120;
  buttonsHelper = ButtonsHelper;
  currentUrl: string;
  showCounters = false;
  showCategory = true;

  constructor(
    private router: Router,
    private fontsAndColorsService: FontsAndColorsService,
    private googleAnalytics: GoogleAnalyticsEventsService,
    private languageService: LanguageService,
    private savedStoryService: SavedStoryService,
    public webPHelper: WebpHelperService,
    private http: HttpClient,
    private mcService: McService
  ) {
    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();

    this.languageService.language.subscribe((language) => {
      this.language = language;
    });
  }

  ngOnInit() {
    this.showCategory = localStorage.getItem("hideCategories") != "1";
    this.user = TokenManagerService.getUser();
    this.currentUrl = window.location.href;
    this.drawGrid();
  }

  ngOnChanges() {
    this.drawGrid();
  }

  getSource(story: LatestStoriesModel | WebNewsModel) {
    if (story.fullHDUrl) return story.fullHDUrl;
    return story.thumbUrl;
  }

  drawGrid() {
    this.additionalClasses = this.gridClass;
    if (this.grid == "Columns2" && (this.index + 1) % 2 == 0) {
      this.additionalClasses["no-margin-right"] = true;
    }
    if (this.grid == "Columns3" && (this.index + 1) % 3 == 0) {
      this.additionalClasses["no-margin-right"] = true;
    }
    if (this.grid == "Columns4" && (this.index + 1) % 4 == 0) {
      this.additionalClasses["no-margin-right"] = true;
    }
  }

  readMore() {
    window.scrollTo(0, 0);
    if (localStorage.getItem("Token") === null) {
      this.user.id = null;
    }
    if (this.user.cmsUser == null) {
      this.googleAnalytics.emitEvent(
        "view",
        "story/" + this.story.staggingStoryId,
        "view",
        this.user.id ? this.user.id.toString() : "anonymous"
      );
    }
    this.router.navigate([
      `/story/${this.story.staggingStoryId}/${UrlHelper.getUrlTitle(
        this.story.storyTitle
      )}`,
    ]);
    if (window.location.pathname === "/") {
      this.googleAnalytics.emitArticleEvent(
        "homepage",
        this.story.staggingStoryId
      );
    }
    this.googleAnalytics.emitReadMoreEvent(
      "grid",
      this.story.storyTitle,
      `/story/${this.story.staggingStoryId}/${UrlHelper.getUrlTitle(
        this.story.storyTitle
      )}`
    );
  }

  getStoryUrl() {
    return `/story/${this.story.staggingStoryId}/${UrlHelper.getUrlTitle(
      this.story.storyTitle
    )}`;
  }

  showSubCategory(news) {
    news.subCategory = !news.subCategory;
  }

  multupleFileTypes() {
    let array = [
      this.story.images,
      this.story.video,
      this.story.document,
      this.story.audio,
    ];
    return array.filter((a) => a != 0).length > 1;
  }

  toggleSavedStory(storyId, event: Event) {
    event.stopPropagation();
    if (this.inSavedStories(storyId))
      this.savedStoryService
        .remove(storyId)
        .then((response: any) => { })
        .catch((error) => {
          console.error(error);
        });
    else
      this.savedStoryService
        .add(storyId)
        .then((response: any) => { })
        .catch((error) => {
          console.error(error);
        });
  }

  inSavedStories(storyId) {
    return this.savedStoryService.inSavedStories(storyId);
  }

  titleMaxLength: number = 149;

  shortenTitle(title) {
    if (title == null || title == undefined) {
      return "";
    }

    if (title.length > this.titleMaxLength) {
      return title.substring(0, this.titleMaxLength) + "...";
    }

    return title;
  }

  downloadingAll = false;
  downloadingImage = false;
  downloadingVideo = false;
  downloadingDocument = false;
  downloadingAudio = false;

  downloadAll(list = null, mediaType) {
    if (mediaType == "all") this.downloadingAll;
    else if (mediaType == "image") this.downloadingImage;
    else if (mediaType == "video") this.downloadingVideo;
    else if (mediaType == "document") this.downloadingDocument;
    else if (mediaType == "audio") this.downloadingAudio;
    let component = this;
    if (list === null) list = this.filtered;
    list.forEach(function (media) {
      component.forDownload.push(media.downloadLink);
      component.downloadIds.push(media.id);
    });
    this.download({}, "text", mediaType);
  }

  login(){
    this.router.navigateByUrl('/sign-in');
  }

  download(element, text, mediaType = null) {
    if (
      this.webNewsDetailsResponse.mustRegisterToDownload &&
      !this.webNewsDetailsResponse.anonymousShare &&
      localStorage.getItem("Token") === null
    ) {
      window.scrollTo(0, 0);
      // this.loginPopup = true;
      // return;
      this.router.navigateByUrl('/sign-in');
    } else {
      var urls = this.forDownload;
      let component = this;
      var zip = new JSZip();
      var count = 0;
      var zipFilename = "download.zip";
      this.loader = true;
      if (this.forDownload.length > 1) {
        element.textContent = text;
        component.disabledButton = true;
        urls.forEach(function (url) {
          var filename = url.split("/")[8];
          JSZipUtils.getBinaryContent(url, function (err, data) {
            if (err) {
              throw err;
            }
            zip.file(filename, data, { binary: true });
            count++;
            if (count == urls.length) {
              zip.generateAsync({ type: "blob" }).then(function (content) {
                saveAs(content, zipFilename);

                component.disabledButton = false;
                element.textContent = "Download";
                if (component.user.cmsUser == null) {
                  component.addDownload();
                }
                if (mediaType == "all") component.downloadingAll = false;
                else if (mediaType == "image") component.downloadingImage = false;
                else if (mediaType == "video") component.downloadingVideo = false;
                else if (mediaType == "document") component.downloadingDocument = false;
                else if (mediaType == "audio") component.downloadingAudio = false;

                component.loader = false;
              });
            }
          });
        });
        this.addDownloadClientSide();
      } else {
        for (const x of this.forDownload) {
          component.disabledButton = true;
          element.textContent = text;
          this.http
            .get(x, { responseType: "blob" })
            .toPromise()
            .then(
              (blob: Blob) => {
                let filename = x.split("/")[8];
                saveAs(blob, filename);
                component.disabledButton = false;
                element.textContent = "Download";
                if (component.user.cmsUser == null) {
                  component.addDownload();
                }
                component.loader = false;
              },
              (error) => { }
            );
          this.addDownloadClientSide();
        }
      }
      this.forDownload = [];
    }
  }

  addDownload() {
    this.user = TokenManagerService.getUser();
    if (localStorage.getItem("Token") === null) {
      this.user.id = null;
    }
    let niz = new DownloadAddToModel();
    niz.storyMediaIds = this.downloadIds;
    niz.userId = this.user.id;
    niz.publishedMediaIds = [];
    niz.storyMediaIds.forEach((mediaId) => {
      this.googleAnalytics.emitEvent(
        "media/" + mediaId,
        "download",
        niz.userId ? niz.userId.toString() : ""
      );
    });
    this.mcService.addDownloadToMedia(niz).subscribe(
      (response) => { },
      (error) => {
        //
      }
    );
  }

  addDownloadClientSide() {
    for (const x of this.webNewsDetailsResponse.media) {
      for (const y of this.forDownload) {
        if (x.downloadLink == y) {
          x.downloads++;
        }
      }
    }
  }

}
