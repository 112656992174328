export class PageStyle {
  public downloadedFonts: any[];
  public fontType: any[];
  public style: Style;

  constructor() {
    this.fontType = [];
    this.downloadedFonts = [];
  }
}
export class Style {
  public cssSpecification: string;
  public dateCreated: string;
  public embeddedFontData: string;
  public fontFileNames: string;
  public fontType: string;
  public fonts: FontsModel;
  public mainBackgroundFooterColor: string;
  public mainBackgroundHeaderColor: string;
  public mainBackgroundPrimaryColor: string;
  public mainBackgroundSecondaryColor: string;
  public mainButton1DefaultColor: string;
  public mainButton1HoverColor: string;
  public mainButton1OutlineColor: string;
  public mainButton2DefaultColor: string;
  public mainButton2HoverColor: string;
  public mainButton2OutlineColor: string;
  public mainButton3DefaultColor: string;
  public mainButton3HoverColor: string;
  public mainButton3OutlineColor: string;
  public mainIdentifyingColor: string;
  public secondaryIdentifyingColor: string;
  public mediaCenterId: string;
  public textButton1Color: string;
  public textButton2Color: string;
  public textButton3Color: string;
  public textCounterColor: string;
  public textFooterColor: string;
  public textH1Color: string;
  public textH2Color: string;
  public textH3Color: string;
  public textH4Color: string;
  public textHeaderColor: string;
  public textLinkDefaultColor: string;
  public textLinkHoverColor: string;
  public textMainColor: string;
  public textTabsColor: string;
  public textTagsColor: string;
  public airaBackgroundColor: string;
  public airaLine: string;

  constructor() {
    this.fonts = new FontsModel();
    this.cssSpecification = '';
    this.dateCreated = '';
    this.embeddedFontData = '';
    this.fontFileNames = '';
    this.fontFileNames = '';
    this.fontType = '';
    this.mainBackgroundFooterColor = '';
    this.mainBackgroundHeaderColor = '';
    this.mainBackgroundPrimaryColor = '';
    this.mainBackgroundSecondaryColor = '';
    this.mainButton1DefaultColor = '';
    this.mainButton1HoverColor = '';
    this.mainButton1OutlineColor = '';
    this.mainButton2DefaultColor = '';
    this.mainButton2HoverColor = '';
    this.mainButton2OutlineColor = '';
    this.mainButton3DefaultColor = '';
    this.mainButton3HoverColor = '';
    this.mainButton3OutlineColor = '';
    this.mainIdentifyingColor = '';
    this.mediaCenterId = '';
    this.textButton1Color = '';
    this.textButton2Color = '';
    this.textButton3Color = '';
    this.textCounterColor = '';
    this.textFooterColor = '';
    this.textH1Color = '';
    this.textH2Color = '';
    this.textH3Color = '';
    this.textH4Color = '';
    this.textHeaderColor = '';
    this.textLinkDefaultColor = '';
    this.textLinkHoverColor = '';
    this.textMainColor = '';
    this.textTabsColor = '';
    this.textTagsColor = '';
    this.airaBackgroundColor = '';
    this.airaLine = '';
  }
}

export class FontsModel {
  public mainFont;
  public navigationFont;
  public sliderHeadlineFont;
  public sliderButtonFont;
  public dateFont;
  public featuredNewsHeadlineFont;
  public tagFont;
  public newsBoxHeadlineFont;
  public newsBoxParagraphFont;
  public newsBoxReadMoreFont;
  public mediaBarFont;
  public sectionHeadlineFont;
  public paragraphFont;
  public profileNameFont;
  public profileMessageFont;
  public socialDateFont;
  public downloadBtnFont;
  public aboutHeadlineFont;
  public aboutSubHeadlineStrongFont;
  public aboutSubHeadlineRegularFont;
  public aboutUsQuoteFont;
  public contentFileName;
  public contentFileMetaFont;
  public brandNameFont;
  public labelFont;
  public placeholderFont;
  public footerText;
  public footerLink;
  public btnFont;
  public searchSmallHeadlineFont;
  public searchCategoryFont;
  public airaBackgroundColor;
  public airaLine;

  constructor() {
    this.mainFont = '';
    this.navigationFont = '';
    this.sliderHeadlineFont = '';
    this.sliderButtonFont = '';
    this.dateFont = '';
    this.featuredNewsHeadlineFont = '';
    this.tagFont = '';
    this.newsBoxHeadlineFont = '';
    this.newsBoxParagraphFont = '';
    this.newsBoxReadMoreFont = '';
    this.mediaBarFont = '';
    this.sectionHeadlineFont = '';
    this.paragraphFont = '';
    this.profileNameFont = '';
    this.profileMessageFont = '';
    this.socialDateFont = '';
    this.downloadBtnFont = '';
    this.aboutHeadlineFont = '';
    this.aboutSubHeadlineStrongFont = '';
    this.aboutSubHeadlineRegularFont = '';
    this.aboutUsQuoteFont = '';
    this.contentFileName = '';
    this.contentFileMetaFont = '';
    this.brandNameFont = '';
    this.labelFont = '';
    this.placeholderFont = '';
    this.footerText = '';
    this.footerLink = '';
    this.btnFont = '';
    this.searchSmallHeadlineFont = '';
    this.searchCategoryFont = '';
    this.airaBackgroundColor = '#F3F1ED';
  }
}
