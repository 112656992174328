<div *ngIf="!loader">
  <!-- <section class="media-centre-title">
    <h1>{{ language.mediaCentreTitle || 'Media Centre' }}</h1>
    <h2>{{ language.mediaCentreSubtitle || 'Welcome to the easyJet media centre, where you'll be able to find all our
      latest news.' }}</h2>
  </section> -->

  <!--HOME PAGE 3-->

  <section class="hero-banner">
    <div class="main-story" [backgroundUrl]="backgroundUrl"
      [ngStyle]="{ 'background-image': 'url(' + backgroundUrl + ')' }">
      <div class="image-opacity"></div>
      <div class="button-hover-overlay" (click)="readMore(selectedFs, selectedFs?.storyTitle, selectedFs)">
        <div class="button-container">
          <div class="add-to-wishlist" (click)="toggleSavedStory(selectedFs?.storyId)">
            <i *ngIf="!inSavedStories(selectedFs?.storyId)" class="far fa-heart"></i>
            <i *ngIf="inSavedStories(selectedFs?.storyId)" class="fas fa-heart"></i>
            {{ language.addStoryToFav }}
          </div>

          <app-share *ngIf="homeModelResponse.socialSharing?.length > 0" [image]="selectedFs?.thumbUrl"
            [title]="selectedFs?.storyTitle" [description]="selectedFs?.shortDescription" [big]="false"
            [horizontal]="true" [shared]="selectedFs?.shared" [sbUrl]="getFeaturedStoryUrl()"
            [staggingStoryId]="selectedFs?.staggingStoryId" [mediaId]="selectedFs?.mediaId"
            [storyId]="selectedFs?.storyId" [socials]="homeModelResponse.socialSharing">
          </app-share>
        </div>
      </div>
      <div *ngIf="showCategory" class="category-wrapper" (click)="showSubCategory(selectedFs)">
        <div class="category" *ngFor="let category of selectedFs?.category">
          {{category}}
        </div>
        <!-- <div class="category-dropdown" *ngIf="selectedFs?.dropdown && selectedFs?.category.length > 1">
            <div *ngFor="
                let category of selectedFs?.category
                  | slice : 1 : selectedFs?.category.length
              ">
              {{ category }}
            </div>
          </div> -->
      </div>

    </div>
    <div class="main-headline">
      <h1>
        {{ selectedFs?.storyTitle }}
      </h1>
      <h4 *ngIf="selectedFs?.shortDescription">
        {{ selectedFs?.shortDescription }}
      </h4>
      <div class="headline-buttons">
        <div class="main-read-more flex-center" appStyle
          (click)="readMore(selectedFs, selectedFs?.storyTitle, selectedFs)">
          {{ language.readMore }}
        </div>
      </div>
    </div>
  </section>

  <section>
    <div class="featured-stories-container">
      <div class="story-nav" (click)="prevSlide()">
        <i class="far fa-angle-left"></i>
      </div>
      <ng-container *ngFor="let fstory of nextFStories; let i = index" (click)="changeStory(fstory, i)">
        <app-story-preview class="slider-item" [story]="fstory" [config]="homeModelResponse"
          [customClasses]="'slider'"></app-story-preview>
      </ng-container>
      <div class="story-nav" (click)="nextSlide()">
        <i class="far fa-angle-right"></i>
      </div>
    </div>
  </section>

  <!-- <section class="media-centre-title">
    <h1>Media Centre</h1>
    <h2>Welcome to the easyJet media centre, where you'll be able to find all our
      latest news.</h2>
  </section> -->

  <section class="news flex-center">
    <div class="content-wrapper">
      <ng-container *ngIf="homeModelResponse.latestStories.length != 0">
        <h2 class="section-headline reset-margin-top">
          {{ language.latestStory }}
        </h2>
        <div class="news-feed">
          <!-- <ng-container *ngFor="let group of storyGroupings; let i = index">
            <app-news-group [group]="group" [config]="homeModelResponse" [mirrored]="i % 2 != 0"></app-news-group>
          </ng-container> -->
          <ng-container *ngFor="let story of homeModelResponse.latestStories; let i = index">
            <app-story-preview class="news-item" [story]="story" [config]="homeModelResponse"
              [template]="4"></app-story-preview>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </section>

  <section *ngIf="brands && brands.length > 0" class="brands">
    <div class="content-wrapper">
      <h2 class="section-headline reset-margin-top">
        {{ language.brands }}
      </h2>
      <div class="brand-nav-container">
        <div class="brand-nav" (click)="moveLeftBrands()">
          <i class="far fa-angle-left"></i>
        </div>
        <div class="brand-nav" (click)="moveRightBrands()">
          <i class="far fa-angle-right"></i>
        </div>
      </div>
    </div>
    <ng-container>
      <div class="overflow-container">
        <div #brandsContainer id="brands-container" class="brand-wrapper related-centers-wrapper flex"
          [ngStyle]="{ 'left.px': -brandsCurrentScroll }">
          <a class="brand-box" *ngFor="let brand of brands" href="{{ brand.mediaCenterUrl }}"
            (mouseenter)="brand.brandHovered = true" (mouseleave)="brand.brandHovered = false">
            <div class="brand-image flex-center">
              <img src="{{ brand.logoUrl }}" alt="{{ brand.name }} Logo" />
            </div>
            <div class="opacity-fill" *ngIf="brand.brandHovered"></div>
            <div class="brand-name flex-center">
              {{ brand.brandHovered ? language.visit : brand.name }}
            </div>
          </a>
        </div>
      </div>
    </ng-container>
  </section>
</div>

<app-popup *ngIf="popup" [(hideCounters)]="homeModelResponse.hideCounters" [(popup)]="popup" [mediaId]="mediaId"
  [autoplay]="autoplay"></app-popup>
<app-loader *ngIf="loader"></app-loader>