<section class="news-page flex-center">
  <div class="gray-background"></div>
  <div
    class="content-wrapper"
    [ngStyle]="{
      'padding-bottom': itemsPerPage < webListResponse.news?.length ? '' : '100px'
    }"
  > 
    <div class="news-feed-container">
      <div class="news-bar flex-center">
        <a href="/brands" class="back-brands">
          <img src='../../assets/images/arrow-agency-black.svg'alt="Arrow">
          <p>Back to brands</p>
        </a>
        <div class="brand-main-info">
          <h1 class="hidden-h1">{{ clientName }}</h1>
          <h4 class="section-headline">
            Welcome to the brand page for
            <img
              class="brands-logo-img"
              src="{{ webListResponse.logoUrl }}"
              alt="Brand Logo"
            />
          </h4>
          <div class="brand-description">
            {{clientDescription}}
          </div>
        </div>
        
        <!-- (click)="goToMc(brand.mediaCenterUrl, brand.id, brand.name)"  -->
        <div class="sub-header" *ngIf="mainStory && storyGroups[0].length > 0 && mediaGroups[0].length > 0">
          <a href="{{getLinks}}" (click)="getLink('#main-story')" class="sub-header-item">{{language.latestStories}} </a>
          <a href="{{getLinks}}" (click)="getLink('#all-stories')" fragment="#all-stories" class="sub-header-item">{{language.allStories}}</a>
          <a href="{{getLinks}}" (click)="getLink('#all-media')" class="sub-header-item">{{language.mediaFiles}}</a>
          <!-- <a class="sub-header-item">{{language.brandGuidelines}}</a>
          <a class="sub-header-item">{{language.realtedBrands}}</a>
          <a class="sub-header-item">{{language.contact}}</a> -->
        </div>
      </div>

      <div id="main-story"></div>
      <div class="main-story-container" *ngIf="mainStory">
        <img class="main-stoy-image" src="{{mainStory.fullHDUrl}}">
        <div class="main-story-info">
          <div class="main-story-category"> {{mainStory.category}} </div>
          <h1 class="main-story-title"> {{mainStory.storyTitle}} </h1>
          <p class="main-story-description"> {{mainStory.shortDescription}} </p>
          <a class="main-story-button generic-button" href="/story/{{mainStory.storyId}}"> Read more </a>
        </div>
      </div>

      <div *ngIf="storyGroups[0].length > 0">
        <div id="all-stories"></div>
        <h2> {{language.allStories}} </h2>
        <div class="news-feed flex">
          <ng-container
            *ngFor="
              let group of storyGroups
                | paginate
                  : {
                      itemsPerPage: itemsPerPageNews / 4,
                      currentPage: p,
                      totalItems: webListResponse.totalCount / 4
                    };
              index as i
            "
          >
            <app-news-group-5
              class="group"
              [group]="group"
              [config]="webListResponse"
              [mirrored]="i % 2 != 0"
            ></app-news-group-5>
          </ng-container>
        </div>
      </div>

      <div *ngIf="mediaGroups[0].length > 0">
        <div id="all-media"></div>
        <h2> {{language.mediaFiles}} </h2>

        <div class="news-feed flex media-group">
          <ng-container
            *ngFor="
              let group of mediaGroups
                | paginate
                  : {
                      itemsPerPage: itemsPerPage / 4,
                      currentPage: p,
                      totalItems: webListMediaResponse.totalCount / 4
                    };
              index as i
            "
          >
            <app-media-group-5
              class="group"
              [group]="group"
              [config]="webListMediaResponse"
              [mirrored]="i % 2 != 0"
            ></app-media-group-5>
          </ng-container>
        </div>
      </div>

    </div>
    <pagination-controls
      maxSize="2"
      previousLabel=""
      nextLabel=""
      class="paggination flex-center"
      *ngIf="!loader && itemsPerPage < webListResponse.totalCount"
      (pageChange)="changePage($event); setColor($event)"
    >
    </pagination-controls>
  </div>
</section>

<app-loader *ngIf="loader"></app-loader>
