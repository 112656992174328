import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Language } from "../../../models/language/language.model";
import { PageStyle } from "../../../models/style/style.model";
import { LatestStoriesModel } from "../../../routes/homepage/home-model";
import { WebNewsModel } from "../../../routes/news/news-model";
import { ButtonsHelper } from "../../../shared/helpers/buttons-helper";//"../../../helpers/buttons-helper";
import { UrlHelper } from "../../../shared/helpers/url-helper";
import { FontsAndColorsService } from "../../../shared/services/fonts-and-colors.service";
import { GoogleAnalyticsEventsService } from "../../../shared/services/google-analytics-events.service";
import { LanguageService } from "../../../shared/services/language.service";
import { SavedStoryService } from "../../../shared/services/saved-story.service";
import { TokenManagerService } from "../../../shared/services/token-manager.service";
import { WebpHelperService } from "../../../shared/services/webp-helper.service";

@Component({
  selector: "app-story-code",
  templateUrl: "./story-code.component.html",
  styleUrls: ["./story-code.component.less"],
})
export class CodeStoryPreviewComponent {
  @Input() story: LatestStoriesModel | WebNewsModel;
  @Input() config: any;
  @Input() gridClass: any = [];
  @Input() index: any = 0;
  @Input() grid: any = "";
  @Input() customClasses: string;
  @Input() template: number;

  additionalClasses: any = [];
  styles = new PageStyle();
  language: Language;
  user: any;
  newsHeadlineLength: number = 120;
  buttonsHelper = ButtonsHelper;
  currentUrl: string;
  showCounters = false;
  showCategory = true;

  constructor(
    private router: Router,
    private fontsAndColorsService: FontsAndColorsService,
    private googleAnalytics: GoogleAnalyticsEventsService,
    private languageService: LanguageService,
    private savedStoryService: SavedStoryService,
    public webPHelper: WebpHelperService
  ) {
    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();

    this.languageService.language.subscribe((language) => {
      this.language = language;
    });
  }

  ngOnInit() {
    this.showCategory = localStorage.getItem("hideCategories") != "1";
    this.user = TokenManagerService.getUser();
    this.currentUrl = window.location.href;
    this.drawGrid();
  }

  ngOnChanges() {
    this.drawGrid();
  }

  getSource(story: LatestStoriesModel | WebNewsModel) {
    if (story.fullHDUrl) return story.fullHDUrl;
    return story.thumbUrl;
  }

  drawGrid() {
    this.additionalClasses = this.gridClass;
    if (this.grid == "Columns2" && (this.index + 1) % 2 == 0) {
      this.additionalClasses["no-margin-right"] = true;
    }
    if (this.grid == "Columns3" && (this.index + 1) % 3 == 0) {
      this.additionalClasses["no-margin-right"] = true;
    }
    if (this.grid == "Columns4" && (this.index + 1) % 4 == 0) {
      this.additionalClasses["no-margin-right"] = true;
    }
  }

  readMore() {
    window.scrollTo(0, 0);
    if (localStorage.getItem("Token") === null) {
      this.user.id = null;
    }
    if (this.user.cmsUser == null) {
      this.googleAnalytics.emitEvent(
        "view",
        "story/" + this.story.staggingStoryId,
        "view",
        this.user.id ? this.user.id.toString() : "anonymous"
      );
    }
    this.router.navigate([
      `/story/${this.story.staggingStoryId}/${UrlHelper.getUrlTitle(
        this.story.storyTitle
      )}`,
    ]);
    if (window.location.pathname === "/") {
      this.googleAnalytics.emitArticleEvent(
        "homepage",
        this.story.staggingStoryId
      );
    }
    this.googleAnalytics.emitReadMoreEvent(
      "grid",
      this.story.storyTitle,
      `/story/${this.story.staggingStoryId}/${UrlHelper.getUrlTitle(
        this.story.storyTitle
      )}`
    );
  }

  getStoryUrl() {
    return `/story/${this.story.staggingStoryId}/${UrlHelper.getUrlTitle(
      this.story.storyTitle
    )}`;
  }

  showSubCategory(news) {
    news.subCategory = !news.subCategory;
  }

  multupleFileTypes() {
    let array = [
      this.story.images,
      this.story.video,
      this.story.document,
      this.story.audio,
    ];
    return array.filter((a) => a != 0).length > 1;
  }

  toggleSavedStory(storyId) {
    if (this.inSavedStories(storyId))
      this.savedStoryService
        .remove(storyId)
        .then((response: any) => { })
        .catch((error) => {
          console.error(error);
        });
    else
      this.savedStoryService
        .add(storyId)
        .then((response: any) => { })
        .catch((error) => {
          console.error(error);
        });
  }

  inSavedStories(storyId) {
    return this.savedStoryService.inSavedStories(storyId);
  }

  titleMaxLength: number = 149;

  shortenTitle(title) {
    if (title == null || title == undefined) {
      return "";
    }

    if (title.length > this.titleMaxLength) {
      return title.substring(0, this.titleMaxLength) + "...";
    }

    return title;
  }
}
