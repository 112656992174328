import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Output, Renderer2 } from '@angular/core';
import { McService } from '../../shared/services/mc-service';
import { BrandsManageModel, BrandsModel } from './brands-model';
import { Router } from '@angular/router';
import { TokenManagerService } from '../../shared/services/token-manager.service';
import { StorageService } from '../../shared/services/storage.service';
// import { HTMLElement } from "@types/chai";
declare var $: any;
import { LanguageService } from '../../shared/services/language.service';
import { Language } from '../../models/language/language.model';
import { PageStyle } from "../../models/style/style.model";
import { FontsAndColorsService } from "../../shared/services/fonts-and-colors.service";
import { 
  AlgoliaNewsRecords,
  AlgoliaMediaRecords,
  ClientLookupModel,
  SearchModel,
  SearchResultModel,
  WebSearchModel
} from "../../header/search/search-model";
import { AlgoliaService } from "../../shared/services/algolia.service";
import { DomSanitizer } from "@angular/platform-browser";

import local_store from 'devextreme/data/local_store';

@Component({
  selector: 'app-brands-6',
  templateUrl: './brands-6.component.html',
  styleUrls: ['./brands-6.component.less']
})
export class BrandsComponent6 implements OnInit {
  @Output() searchChange = new EventEmitter<boolean>();
  dropdown = false;
  language: Language;
  categoryLabel: string;
  category = ['company', 'company', 'company'];
  visibleBrands: BrandsManageModel[] = [];
  noBrands = false;
  searchResult: boolean;
  styles = new PageStyle();
  search = false;
  filters = false;
  bkg = false;
  bkgFilters = false;
  dropdownFilterMarkets = false;
  dropdownFilterBrands = false;
  dropdownFilterCategory = false;
  dropdownFilterDate = false;
  dropdownFilterMediaType = false;
  filtersVisible = false;
  selectedCustomCategory: any = 0;
  markets: any[] = [];
  isInMarketPage = false;
  selectedMarket: any = 0;
  sortBy: string = "";
  marcaVolteada: null;
  user: any;
  
  brandsResponse = new BrandsModel();
  loader: boolean;
  query: any;

  searchModelData = new SearchModel();
  searchResultStories = new SearchResultModel();
  searchResultMedia = new SearchResultModel();

  showingResults: boolean;

  constructor(
    private algoliaService: AlgoliaService,
    private mcService: McService, 
    private languageService: LanguageService, 
    public fontsAndColorsService: FontsAndColorsService, 
    private router: Router, 
    private sService: StorageService,
    private storageService: StorageService,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    ) {
    this.language = languageService.getLanguage();
    this.styles = fontsAndColorsService.getPageStyleInfo();

    this.languageService.language.subscribe(
      (language) => {
        this.language = language;
      }
    );

  }

  ngOnInit() {
    this.user = TokenManagerService.getUser();
    this.getBrands();
    this.sService.getSearch().subscribe(term => {
      this.searchResult = term;
    });
    this.categoryLabel = this.language.categories;

    const market = JSON.parse(localStorage.getItem("currentMarket"));
    this.isInMarketPage = market != null && market != undefined;
    if (this.isInMarketPage) {
      this.selectedMarket = market.id;
    } else {
      this.mcService.getMarkets().subscribe((markets: any) => {
        this.markets = markets.filter((x) => x.name != "All");
      });
    }
  }

  getBrands() {
    this.loader = true;
    this.mcService.getBrands().subscribe((response: any) => {
      this.brandsResponse = response;
      // if (this.brandsResponse.mustRegisterToAccess && localStorage.getItem('Token') == null) {
      //   sessionStorage.setItem('redirectUrl', '/' + window.location.pathname);
      //   this.router.navigateByUrl('/sign-in');
      // }
      this.visibleBrands = response.clients;
      this.loader = false;
      if (this.brandsResponse.customCategories && this.brandsResponse.customCategories.length > 0)
        this.brandsResponse.customCategories.unshift({ id: 0, parentId: 0, label: 'All' });
      if (this.brandsResponse.mainCategories && this.brandsResponse.mainCategories.length > 0)
        this.brandsResponse.mainCategories.unshift({ id: 0, parentId: 0, label: 'All' });
    },
      (error) => {
        //
      });
  }

  // mediaCenter(media) {
  //   if (media != null || media.length != 0) {
  //     window.location.href = media;
  //   }
  // }

  brandStories(brandName) {
    const url = this.router.url;
    window.open(url + '/' + brandName, '_blank');

  }

  filter(ccId) {
    this.visibleBrands = [];
    for (const client of this.brandsResponse.clients) {
      let niz2 = client.customCategories ? client.customCategories.filter(x => x.id === ccId.id) : client.mainCategories.filter(x => x.id === ccId.id);
      if (niz2.length > 0) {
        this.visibleBrands.push(client);
      }
      if (ccId.id === 0) {
        this.visibleBrands.push(client);
      }
    }
    // if (this.visibleBrands.length === 0) {
    //   this.noBrands = true;
    // }
  }
  

  goToMc(url, clientId, clientName) {
    this.mcService.redirectToMc(clientId).subscribe(
      (response: any) => {
        const token = response.token;
        if (url){
          window.open(url + '&token=' + token, '_blank');
        } else {
          // redirect inside btrand
          this.brandStories(clientName)

        }
      },
      (error) => {
        //Error de falta the authorization header
    });

    if (url){
      window.open(url, '_blank');
    } else {
      // redirect inside btrand
      this.brandStories(clientName)
    }

  }

  
  onChangeBrand(id: number) {
    if (id == 0) {
      this.searchModelData.searchModel.subClientIds = [];
      let select = (<HTMLSelectElement>document.getElementById('brandSelect'));
      select.value = '0';
    } else {
      this.searchModelData.searchModel.subClientIds = [id];
    }
  }

  clearFilters() {
    if (this.searchModelData.subClients.length > 0) {
      this.onChangeBrand(0);
    }
    this.searchModelData.searchModel.dateFrom = null;
    this.searchModelData.searchModel.dateTo = null;
    this.searchModelData.searchModel.searchText = '';
    this.searchModelData.searchModel.codeFrom = null;
    this.searchModelData.searchModel.codeTo = null;
    this.searchModelData.searchModel.sortCode = null;
    this.searchModelData.searchModel.sortDate = null;
    this.selectedCustomCategory = 0;
    this.getBrands();
  }

  toggleFilters() {
    this.filtersVisible = !this.filtersVisible;
    this.closeFilters();
  }

  cardsTurn(brandId){
    this.reverseCards();
    this.flipCard(brandId);
  }

  flipCard(brandId) {
    var brand = this.visibleBrands.find(b => b.id == brandId);
    brand.flip = !brand.flip;
    this.bkg = !this.bkg;
  }

  reverseCards(){
    this.visibleBrands.forEach(b => {
      if(b.flip = true){
        this.flipCard(b.id);
      }
    })
  }

 

  openFilters(){
    this.filters = true;
    this.bkgFilters = true;
  }
  closeFilters(){
    this.filters = false;
    this.bkgFilters = false;
  }

  toggleDropdowFilter(filter: string){
    if(filter == 'markets'){
      this.dropdownFilterMarkets = !this.dropdownFilterMarkets;
    } else if(filter == 'brands'){
      this.dropdownFilterBrands = !this.dropdownFilterBrands;
    } else if(filter == 'categories'){
      this.dropdownFilterCategory = !this.dropdownFilterCategory;
    } else if(filter == 'date'){
      this.dropdownFilterDate = !this.dropdownFilterDate;
    } else if(filter == 'mediaType'){
      this.dropdownFilterMediaType = !this.dropdownFilterMediaType;
    }
  }

  onSortChange(sortBy: string){
    this.sortBy = sortBy;
  }
}
