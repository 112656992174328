import { Component, HostListener, NgZone, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { McService } from "../../shared/services/mc-service";
import { WebNewsListModel, WebNewsModel } from "./news-model";
import { UrlHelper } from "../../shared/helpers/url-helper";
import { PageStyle } from "../../models/style/style.model";
import { TokenManagerService } from "../../shared/services/token-manager.service";
import { FontsAndColorsService } from "../../shared/services/fonts-and-colors.service";
import { GoogleAnalyticsEventsService } from "../../shared/services/google-analytics-events.service";
import { LanguageService } from "../../shared/services/language.service";
import { Language } from "../../models/language/language.model";
import { SearchModel, WebSearchModel } from "../../header/search/search-model";
import { DomSanitizer } from "@angular/platform-browser";
import { AlgoliaService } from "../../shared/services/algolia.service";
import { forkJoin } from "rxjs";

declare var $: any;

@Component({
  selector: "app-news",
  templateUrl: "./news.component.html",
  styleUrls: ["./news.component.less"],
})
export class NewsComponent implements OnInit {
  news: any[];
  grid: string;
  dropdown = false;
  loader: boolean;
  subcategory = false;
  styles = new PageStyle();
  user: any;
  showPagination = false;
  socials: string[] = [];
  currentUrl: string;
  newsHeadlineLength: number = 10;
  webListResponse = new WebNewsListModel();
  p = 1;
  itemsPerPage: number = 12;
  shortDescriptionLength: number;
  language: Language;
  sLng: number;
  lazyLoadingLoader = false;
  maxPageMedia = 1;
  searchModelData = new SearchModel();
  callGetAllData = true;
  filtersVisible = false;
  dateToButton = false;
  dateFromButton = false;
  dateFrom: Date = null;
  dateTo: Date = null;
  storyGroups: WebNewsModel[][] = [[]];
  hasStoryCodes: boolean;
  groupedStories: any[] = [];
  loginPopup: boolean = false;
  selectedCustomCategory: any = 0;
  isInMarketPage = false;
  selectedMarket: any = 0;
  markets: any[] = [];
  isEasyJet: boolean = false;
  month: number;
  year: number;
  codeFrom: string = null;
  codeTo: string = null;
  sortCode: string = null;
  selectedSort: any = 0;
  sortDate: string = null;
  sortBy: string = '';

  constructor(
    private router: Router,
    private mcService: McService,
    private languageService: LanguageService,
    ngZone: NgZone,
    private sanitizer: DomSanitizer,
    public fontsAndColorsService: FontsAndColorsService,
    private googleAnalytics: GoogleAnalyticsEventsService,
    private algoliaService: AlgoliaService,
    private route: ActivatedRoute
  ) {
    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();

    this.languageService.language.subscribe((language) => {
      this.language = language;
    });

    this.hasStoryCodes = localStorage.getItem("hasStoryCodes") === "true";
  }

  ngOnInit() {
    this.sLng = Number(localStorage.getItem("lng"));
    this.currentUrl = window.location.href;
    this.loader = true;
    window.scrollTo(0, 0);
    this.user = TokenManagerService.getUser();
    const market = JSON.parse(localStorage.getItem("currentMarket"));
    this.isInMarketPage = market != null && market != undefined;
    if (this.isInMarketPage) {
      this.selectedMarket = market.id;
    } else {
      this.mcService.getMarkets().subscribe((markets: any) => {
        this.markets = markets.filter((x) => x.name != "All");
      });
    }
    this.getSearch();
    if (localStorage.getItem("isEasyJet") === "1") {
      this.isEasyJet = true;
    }
  }

  public config: any = null;

  getSearch() {
    const searchConfig$ = this.mcService.getSearchConfig();
    const search$ = this.mcService.getSearch();

    forkJoin([searchConfig$, search$]).subscribe(
      ([searchConfigResponse, searchResponse]) => {
        this.config = searchConfigResponse;
        this.searchModelData = searchResponse as any as SearchModel;
        this.searchModelData.searchModel = new WebSearchModel();
        this.loader = false;
        this.searchModelData.searchModel.images = false;
        this.searchModelData.searchModel.video = false;
        this.searchModelData.searchModel.document = false;
        this.searchModelData.searchModel.audio = false;
        this.searchModelData.searchModel.media = false;
        this.searchModelData.searchModel.stories = true;

        this.route.params.subscribe((params) => { this.month = params['month']; this.year = params['year']; });

        if (this.year) {
          let dateFrom = new Date;
          let dateTo = new Date;
          if (this.month) {
            dateFrom = new Date(this.year, this.month - 1, 1);
            dateTo = new Date(this.year, this.month, 0);
          } else {
            dateFrom = new Date(this.year, 0, 1);
            dateTo = new Date(this.year, 12, 0);
          }
         
          this.searchModelData.searchModel.dateFrom = dateFrom.toISOString();
          this.searchModelData.searchModel.dateTo = dateTo.toISOString();
        }

        this.getNews(this.p, true);
      },
      (error) => {
        this.loader = false;
      }
    );

  }

  @HostListener("window:scroll", ["$event"])
  onScroll(event) {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 300 &&
      !this.lazyLoadingLoader
    ) {
      this.loadMore();
    }
  }

  loadMore() {
    if (this.p < this.maxPageMedia) {
      this.p++;
      this.lazyLoadingLoader = true;
      this.changePage(this.p);
    }
  }

  changeColorr(e) {
    e.target.style.background = this.styles.style.mainButton3HoverColor;
  }

  changeColor(e) {
    e.target.style.background = this.styles.style.mainButton3DefaultColor;
  }

  getStoryUrl(storyId) {
    return (
      this.currentUrl.replace("news", "story") +
      "/" +
      storyId +
      "/" +
      UrlHelper.getUrlTitle(
        this.webListResponse.stories.find((x) => x.staggingStoryId == storyId)
          .storyTitle
      )
    );
  }

  getStyle() {
    this.mcService.getStyle().subscribe(
      (response: any) => {
        this.styles = response;
      },
      (error) => {
        //
      }
    );
  }

  public algoliaStories = [];
  getNews(page, isReset = false) {
    if (this.sLng == 0) {
      this.sLng = 1;
    }
    this.loader = isReset;
    this.searchModelData.searchModel.page = page;
    if (isReset) this.searchModelData.searchModel.page = 1;
    this.searchModelData.searchModel.pageSize = this.itemsPerPage;
    this.searchModelData.searchModel.languageId = this.sLng;
    if (this.selectedCustomCategory != null && this.selectedCustomCategory != undefined && this.selectedCustomCategory != 0) {
      this.searchModelData.searchModel.customCategoryIds = [this.selectedCustomCategory];
    } else {
      this.searchModelData.searchModel.customCategoryIds = [];
    }

    if(this.hasStoryCodes){
      if(this.selectedSort != null && this.selectedSort != undefined && this.selectedSort != 0) {
        if(this.selectedSort.includes('date')){
          this.searchModelData.searchModel.sortDate = this.selectedSort.split('-')[1];
          this.searchModelData.searchModel.sortCode = null;
        }else {
          this.searchModelData.searchModel.sortCode = this.selectedSort.split('-')[1];
          this.searchModelData.searchModel.sortDate = null;
        }
      } 
    }

    this.searchModelData.searchModel.marketId = this.selectedMarket && this.selectedMarket != 0 ?
      parseInt(this.selectedMarket) : null;

    if (!this.hasStoryCodes) {
      let dateFrom = null;
      let dateTo = null;
      if (this.searchModelData.searchModel.dateFrom != null) {
        dateFrom = new Date(this.searchModelData.searchModel.dateFrom);
        dateFrom.setHours(0, 0, 0, 0);
      }

      if (this.searchModelData.searchModel.dateTo != null) {
        dateTo = new Date(this.searchModelData.searchModel.dateTo);
        dateTo.setHours(23, 59, 59, 999);
      }

      this.algoliaService.searchStoriesWithFilters(
        this.searchModelData.searchModel.searchText,
        this.searchModelData.searchModel.page,
        this.sortBy,
        this.searchModelData.searchModel.subClientIds.length > 0 ? this.searchModelData.searchModel.subClientIds[0] : null,
        this.searchModelData.searchModel.marketId,
        this.searchModelData.searchModel.customCategoryIds.length > 0 ? this.searchModelData.searchModel.customCategoryIds[0] : null,
        dateFrom, dateTo).then((response: any) => {
          this.maxPageMedia = Math.ceil(response.nbHits / this.itemsPerPage);
          if (isReset) {
            this.algoliaStories = [];
          }

          this.algoliaStories = this.algoliaStories.concat(response.hits.map((s) => {
            return {
              storyTitle: s.title,
              category: this.algoliaService.getSuperMediaCenter() || s.customCategories ? s.mainCategories.map((c) => c.name) : s.customCategories.map((c) => c.name),
              publishedDate: s.storyDate,
              hideStoryDate: s.hideStoryDate,
              staggingStoryId: s.staggingStoryId,
              thumbUrl: s.mediaThumbUrl,
              shortDescription: s.shortDescription,
              embeddedCode: s.embeddedCode,
              mediaId: s.mediaId,
            }
          }));
          this.storyGroups = [[]];
          this.groupStories(this.algoliaStories);
          this.loader = false;
          this.lazyLoadingLoader = false;
        });

    } else {
      this.mcService.searchMcStories(this.searchModelData.searchModel, this.hasStoryCodes).subscribe(
        (response: any) => {
          if (this.hasStoryCodes && !this.searchModelData.searchModel.marketId) {
            this.maxPageMedia = Math.ceil(response.totalCount / this.itemsPerPage);
            this.webListResponse = response;

            if (!isReset) {
              this.groupedStories = this.groupedStories.concat(
                response.groupedStories
              );
            } else {
              this.groupedStories = response.groupedStories;
            }
            if (this.webListResponse.socialSharing != null) {
              this.socials = this.webListResponse.socialSharing;
            }
            for (const news of this.groupedStories) {
              for (const story of news.stories) {
                if (story.featuredEmbeddedCode) {
                  story.embeddedCode = this.sanitizer.bypassSecurityTrustHtml(
                    story.featuredEmbeddedCode
                  );
                }
              }
            }

          } else {
            this.maxPageMedia = Math.ceil(response.totalCount / this.itemsPerPage);
            let stories = this.webListResponse.stories;
            this.webListResponse = response;
            if (!isReset) {
              this.webListResponse.stories = stories.concat(
                this.webListResponse.stories
              );
            }
            if (this.webListResponse.socialSharing != null) {
              this.socials = this.webListResponse.socialSharing;
            }
            for (const news of this.webListResponse.stories) {
              if (news.featuredEmbeddedCode) {
                news.embeddedCode = this.sanitizer.bypassSecurityTrustHtml(
                  news.featuredEmbeddedCode
                );
              }
            }
            this.storyGroups = [[]];
            this.groupStories(this.webListResponse.stories);
          }
          this.loader = false;
          this.lazyLoadingLoader = false;
        },
        (error) => {
          //
        }
      );
    }
  }

  groupStories(stories) {
    let count = 0;
    for (const story of stories) {
      if (this.storyGroups[count].length >= 4) count++;
      if (!this.storyGroups[count]) this.storyGroups[count] = [];
      this.storyGroups[count].push(story);
    }
  }

  setColor(e?) {
    setTimeout(() => {
      const element = document.getElementsByClassName(
        "current"
      ) as HTMLCollectionOf<HTMLElement>;
      if (element[0]) {
        element[0].style.backgroundColor =
          "" + this.styles.style.mainIdentifyingColor;
      }
    }, 100);
  }

  showSubCategory(news) {
    news.subCategory = !news.subCategory;
  }

  openDropdown(id) {
    for (const x of this.webListResponse.stories) {
      if (x.storyId === id) {
        x.shareDropdown = !x.shareDropdown;
      }
    }
  }

  readMore(id) {
    localStorage.setItem("storyId", JSON.stringify(id));
    if (localStorage.getItem("Token") === null) {
      this.user.id = null;
    }
    if (this.user.cmsUser == null) {
      this.googleAnalytics.emitEvent(
        "story/" + id.staggingStoryId,
        "view",
        this.user.id ? this.user.id.toString() : "anonymous"
      );
    }
    this.router.navigate([
      "/story/" +
      id.staggingStoryId +
      "/" +
      UrlHelper.getUrlTitle(
        this.webListResponse.stories.find(
          (x) => x.staggingStoryId == id.staggingStoryId
        ).storyTitle
      ),
    ]);
  }

  changePage(page) {
    this.p = page;
    this.getNews(this.p);
  }

  openDateFrom() {
    this.dateFromButton = !this.dateFromButton;
  }

  openDateTo() {
    this.dateToButton = !this.dateToButton;
  }
  changeFromDate(event) {
    if (event.value !== null) {
      if (new Date(this.dateFrom).getTime() > new Date(this.dateTo).getTime()) {
        this.dateTo = new Date();
      }
    }
  }

  changeToDate(event) {
    if (event.value !== null) {
      if (new Date(this.dateFrom).getTime() > new Date(this.dateTo).getTime()) {
        const dateTo: Date = new Date(this.dateTo);
        this.dateFrom = new Date(dateTo.getTime());
      }
    }
  }

  onChangeBrand(id: number) {
    if (id == 0) {
      this.searchModelData.searchModel.subClientIds = [];
      let select = (<HTMLSelectElement>document.getElementById('brandSelect'));
      select.value = '0';
    } else {
      this.searchModelData.searchModel.subClientIds = [id];
    }
  }

  clearFilters() {
    if (this.searchModelData.subClients.length > 0) {
      this.onChangeBrand(0);
    }
    this.searchModelData.searchModel.dateFrom = null;
    this.searchModelData.searchModel.dateTo = null;
    this.searchModelData.searchModel.searchText = '';
    this.searchModelData.searchModel.codeFrom = null;
    this.searchModelData.searchModel.codeTo = null;
    this.searchModelData.searchModel.sortCode = null;
    this.searchModelData.searchModel.sortDate = null;
    this.selectedCustomCategory = 0;
    this.getNews(1, true);
  }

  toggleFilters() {
    this.filtersVisible = !this.filtersVisible;
  }
}