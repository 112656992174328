<section class="news-group-5" [ngClass]="{ 'mirrored': mirrored }">
  <div class="big-container">
    <app-story-preview-5 *ngIf="group.length > 0" [story]="group[0]" [config]="config"
      [customClasses]="'two-thirds'"></app-story-preview-5>
    <app-story-preview-5 *ngIf="group.length > 3" [story]="group[3]" [config]="config"
      [customClasses]="'one-third'"></app-story-preview-5>
  </div>
  <div class="small-container">
    <app-story-preview-5 *ngIf="group.length > 1" [story]="group[1]" [config]="config"
      [customClasses]="'one-half'"></app-story-preview-5>
    <app-story-preview-5 *ngIf="group.length > 2" [story]="group[2]" [config]="config"
      [customClasses]="'one-half'"></app-story-preview-5>
  </div>
</section>