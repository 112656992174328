import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { McService } from "../shared/services/mc-service";
import { RegisterModel, UserManageModel } from "./register-model";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  ValidationErrors,
} from "@angular/forms";
import { CustomValidators } from "ng2-validation";
import { ActivatedRoute, Params, Router } from "@angular/router";
import {
  FacebookService,
  LoginResponse,
  LoginOptions,
  UIResponse,
  UIParams,
  FBVideoComponent,
} from "ngx-facebook";
import { FontsAndColorsService } from "../shared/services/fonts-and-colors.service";
import { PageStyle } from "../models/style/style.model";
import { environment } from "../../environments/environment";
import { StorageService } from "../shared/services/storage.service";
import { LayoutModel } from "../layout/layout-model";
import { LanguageService } from "../shared/services/language.service";
import { Language } from "../models/language/language.model";
import { PasswordCheckService } from "../shared/services/password-check.service";
import { ToastrService } from 'ngx-toastr';
import { ToastrConfig } from "../shared/helpers/toastr-config";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.less"],
})
export class RegisterComponent implements OnInit {
  @ViewChild("firstCheckBox") firstCheckBox: ElementRef;
  @Input() isDna: boolean;
  addDropdown: boolean;
  termsPopup: boolean;
  @Input() dropdownStyle: boolean;
  dropdownLabel: string;
  dropdownLabel2: string;
  dropdownLabel3: string;
  patternPassword = /^\S*$/;
  confirmError = false;
  facebook = false;
  twitter = false;
  instagram = false;
  linkedin = false;
  youtube = false;
  wordpress = false;
  dropdown = false;
  languageDropDown: boolean;
  categoryDropdown: boolean;
  valForm: FormGroup;
  languages: any[] = [];
  categories: any[] = [];
  mediaUser: boolean;
  users: string[] = [];
  usersToShow: any[] = [];
  allUsers: string[] = [];
  termsAndConditions: boolean;
  noMediaUserType: boolean;
  layoutResponseModel: LayoutModel;
  language: Language;
  markedAll: boolean;
  newsletterTrue = false;
  TC = false;
  newsLetter = false;

  userFilled: boolean;

  password: any;
  confirmPassword: any;
  email: any;
  styles = new PageStyle();

  registerResponseModel = new RegisterModel();
  isFb: boolean;
  loader = false;
  registerSuccessPopup: boolean;

  twitterRegister: boolean;
  twitterAuthorizationVerifier: string;
  twitterAuthorizationId: string;
  twitterName: string;
  showPassword: boolean;
  showPasswordConfirm: boolean;
  type: string;
  typeConfirm: string;
  currentPage: number = 1;
  showCategories: boolean = true;

  constructor(
    private mcService: McService,
    private languageService: LanguageService,
    private sService: StorageService,
    private fb: FormBuilder,
    private router: Router,
    private fbo: FacebookService,
    public fontsAndColorsService: FontsAndColorsService,
    private activatedRoute: ActivatedRoute,
    private checkPasswordService: PasswordCheckService,
    private toastr: ToastrService
  ) {
    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();

    this.languageService.language.subscribe((language) => {
      this.language = language;
    });

    this.activatedRoute.params.subscribe((params: Params) => {
      this.twitterAuthorizationVerifier = params["oauth_verifier"];
      this.twitterAuthorizationId = params["authorization_id"];
      this.twitterName = params["twitterName"];

      if (this.twitterAuthorizationId) {
        this.twitterRegister = true;
      }
    });
  }

  isPwFocus: boolean = false;
  onPwFocus() {
    this.isPwFocus = true;
  }
  onPwBlur() {
    this.isPwFocus = false;
  }

  ngOnInit() {
    this.showCategories = localStorage.getItem("hideCategories") != "1";
    this.markedAll = true;
    this.isFb = false;
    this.password = new FormControl(null, [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(50),
      Validators.pattern(this.patternPassword),
    ]);
    this.confirmPassword = new FormControl(null, [Validators.required]);
    this.email = new FormControl(null, [
      CustomValidators.email,
      Validators.required,
    ]);

    this.sService.getLogin().subscribe((term) => {
      this.layoutResponseModel = term;
    });

    if (localStorage.getItem("Token") === null) {
      this.getRegister();
    } else {
      this.router.navigateByUrl("/");
    }

    if (this.layoutResponseModel.disableRegistration) {
      this.router.navigateByUrl("/sign-in");
    }
    this.valForm = this.fb.group({
      firstname: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      lastname: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      telephone: [null],
      confirmPassword: [null],
      password: [
        null,
        Validators.compose([
          Validators.maxLength(50),
          Validators.minLength(8),
          Validators.pattern(this.patternPassword),
        ]),
      ],
      newsletter: [null],
      terms: [null, Validators.compose([Validators.required])],
      mediaUserTypeOther: [null],
      facebook: [null],
      twitter: [null],
      instagram: [null],
      linkedin: [null],
      youtube: [null],
      wordpress: [null],
    });

    this.addDropdown = false;
    this.dropdownLabel = "Select media category";
    this.dropdownLabel2 = "Select categories";
    this.dropdownLabel3 = "Select your languages";
    this.showPassword = false;
    this.showPasswordConfirm = false;
    this.type = "password";
    this.typeConfirm = "password";
  }

  visiblePassword(): void {
    this.showPassword = !this.showPassword;
    this.type = this.showPassword ? "text" : "password";
  }

  visiblePasswordConfirm(): void {
    this.showPasswordConfirm = !this.showPasswordConfirm;
    this.typeConfirm = this.showPasswordConfirm ? "text" : "password";
  }

  onClickOutside(event: Object) {
    if (event && event["value"] === true) {
      this.languageDropDown = false;
    }
  }

  onClickOutsideCategory(event: Object) {
    if (event && event["value"] === true) {
      this.categoryDropdown = false;
    }
  }

  onClickOutsideUser(event: Object) {
    if (event && event["value"] === true) {
      this.mediaUser = false;
    }
  }

  loginTwitter(e) {
    window.location.href =
      environment.apiUrl + "/MediaCenterUser/RegisterWithTwitter";
  }

  private handleError(error) {
    console.error("Error processing action", error);
  }

  loginPage() {
    this.router.navigateByUrl("/sign-in");
  }

  getRegister() {
    this.mcService.getRegister().subscribe(
      (response: any) => {
        this.registerResponseModel = response;
        this.registerResponseModel.mediaUserModel = new UserManageModel();
        this.categories = this.registerResponseModel.customCatogories;
        this.registerResponseModel.mediaUserModel.customCategoryIds =
          this.registerResponseModel.customCatogories.map((x) => x.id);
        this.registerResponseModel.customCatogories.forEach(
          (x) => (x.marked = true)
        );
      },
      (error) => {
        //
      }
    );
  }

  addLanguage(lang, event) {
    const index = this.registerResponseModel.mediaUserModel.languageIds.indexOf(
      lang.id
    );
    lang.checked = !lang.checked;
    if (lang.checked) {
      this.registerResponseModel.mediaUserModel.languageIds.push(lang.id);
      this.languages.push(lang.label);
    } else {
      this.registerResponseModel.mediaUserModel.languageIds.splice(index, 1);
      this.languages.splice(index, 1);
    }

    if (event) {
      event.target.style.backgroundColor = event.target.checked
        ? this.styles.style.mainIdentifyingColor
        : "";
    }
  }

  changeColor(event) {
    event.target.style.backgroundColor = event.target.checked
      ? this.styles.style.mainIdentifyingColor
      : "";
  }

  addCategory(category, event) {
    let index =
      this.registerResponseModel.mediaUserModel.customCategoryIds.indexOf(
        category.id
      );
    category.marked = !category.marked;
    if (category.marked) {
      this.registerResponseModel.mediaUserModel.customCategoryIds.push(
        category.id
      );
      if (
        this.categories.length ==
        this.registerResponseModel.mediaUserModel.customCategoryIds.length
      )
        this.markedAll = true;
    } else {
      this.markedAll = false;
      this.registerResponseModel.mediaUserModel.customCategoryIds.splice(
        index,
        1
      );
    }
  }

  selectAll(event) {
    this.markedAll = event.target.checked;
    if (this.markedAll) {
      this.registerResponseModel.mediaUserModel.customCategoryIds =
        this.registerResponseModel.customCatogories.map((x) => x.id);
      this.registerResponseModel.customCatogories.forEach(
        (x) => (x.marked = true)
      );
    } else {
      this.registerResponseModel.mediaUserModel.customCategoryIds = [];
      this.registerResponseModel.customCatogories.forEach(
        (x) => (x.marked = false)
      );
    }
  }

  addUser(user, event) {
    event.stopPropagation();
    const index = this.users.indexOf(user.id);
    user.checked = !user.checked;

    if (user.checked) {
      this.users.push(user.id);
      this.usersToShow.push(user.id);
      this.noMediaUserType = true;
    } else {
      this.users.splice(index, 1);
      this.usersToShow.splice(index, 1);
    }
    if (this.users.length == 0) {
      this.noMediaUserType = false;
    }

    this.registerResponseModel.mediaUserModel.mediaUserTypes = this.users;
    event.target.style.backgroundColor = event.target.checked
      ? this.styles.style.mainIdentifyingColor
      : "";
  }

  checkIfEmpty() {
    if (this.email.value === "") {
      this.email.markAsTouched();
    }
  }

  openAddDropdown() {
    this.addDropdown = !this.addDropdown;
  }

  checkPassword() {
    if (this.password.value === this.confirmPassword.value) {
      this.confirmError = false;
    }
  }

  setConfirmError() {
    if (this.password.value !== this.confirmPassword.value) {
      this.confirmError = true;
    }

    if (this.password.value === "") {
      this.confirmError = false;
    }
  }

  register() {
    this.loader = true;
    this.mcService
      .register(this.registerResponseModel.mediaUserModel)
      .subscribe(
        (response) => {
          this.loader = false;
          // this.router.navigateByUrl('/sign-in', {replaceUrl: true});
          this.registerSuccessPopup = true;
          this.valForm.reset();
        },
        (error) => {
          //
          this.loader = false;
        }
      );
  }

  newsletterCheck(event) {
    this.registerResponseModel.mediaUserModel.receiveNewsletter = true;
    this.newsLetter = !this.newsLetter;
    event.target.style.backgroundColor = event.target.checked
      ? this.styles.style.mainIdentifyingColor
      : "";
    if (!this.newsLetter) {
      this.valForm.controls["terms"].setValidators(Validators.required);
      this.newsletterTrue = false;
      this.registerResponseModel.mediaUserModel.receiveNewsletter = false;
    } else {
      this.newsletterTrue = true;
    }
  }

  dontWantNewsletter(event) {
    this.registerResponseModel.mediaUserModel.receiveNewsletter = false;
    this.newsLetter = !this.newsLetter;
    event.target.style.backgroundColor = event.target.checked
      ? this.styles.style.mainIdentifyingColor
      : "";
    this.firstCheckBox.nativeElement.style.backgroundColor = "";

    if (this.newsLetter) {
      this.firstCheckBox.nativeElement.style.backgroundColor =
        this.styles.style.mainIdentifyingColor;
      this.registerResponseModel.mediaUserModel.receiveNewsletter = true;
    }
  }

  termsCheck(event) {
    this.registerResponseModel.mediaUserModel.termsAndConditions =
      !this.registerResponseModel.mediaUserModel.termsAndConditions;
    this.termsAndConditions = !this.termsAndConditions;
    if (!this.termsAndConditions) {
      this.valForm.controls["terms"].setValidators(Validators.required);
      this.TC = false;
    } else {
      this.TC = true;
    }
    event.target.style.backgroundColor = event.target.checked
      ? this.styles.style.mainIdentifyingColor
      : "";
  }

  toggleDropdown() {
    this.dropdown = !this.dropdown;
  }

  isUserTypeOtherSelected() {
    return this.registerResponseModel?.mediaUserModel?.mediaUserTypes?.includes(
      "Other"
    );
  }

  onSubmit(e) {
    e.stopPropagation();
    for (const c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    this.email.markAsTouched();
    this.password.markAsTouched();
    this.confirmPassword.markAsTouched();

    if (!this.registerResponseModel.mediaUserModel.mediaUserTypes) {
      this.userFilled = false;
    }

    this.registerResponseModel.mediaUserModel.receiveNewsletter =
      this.newsletterTrue;
    this.registerResponseModel.mediaUserModel.termsAndConditions = this.TC;
    if (
      this.valForm.valid &&
      this.password.valid &&
      this.confirmPassword.valid &&
      this.email.valid &&
      this.termsAndConditions &&
      this.noMediaUserType &&
      this.registerResponseModel.mediaUserModel.mediaUserTypes
    ) {

      if (!this.checkPasswordService.hasPasswordHardening() || this.checkPasswordService.hasPasswordHardening() && this.checkPasswordService.checkAll(this.password.value)) {
        this.register();
      } else {
        this.toastr.error("Password does not meet the requirements", 'Oh Snap!', ToastrConfig.error);
      }
    } else {
      Object.keys(this.valForm.controls).forEach((key) => {
        const controlErrors: ValidationErrors = this.valForm.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach((keyError) => {
            console.log(
              "Key control: " +
              key +
              ", keyError: " +
              keyError +
              ", err value: ",
              controlErrors[keyError]
            );
          });
        }
      });
    }
  }
}
