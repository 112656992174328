<!-- <app-results-search></app-results-search> -->
<section class="news-detail flex-center">
  <div class="content-wrapper">
    <div class="search-header-bar">
      <h1 class="search-term-title" *ngIf="searchModel?.searchText?.trim() === ''">
        {{ language.searchForTerm }}
      </h1>
      <h1 class="search-term-title" *ngIf="searchModel?.searchText?.trim() != ''">
        {{ language.searchResults || "Showing results for:" }} "{{
        searchModel?.searchText
        }}"
      </h1>
      <div class="top-bar-container" *ngIf="searchModel?.searchText?.trim() != ''">
        <!-- <div class="news-picker"></div> -->
        <div class="search-type-toggle-container">
          <div class="search-type-toggle">
            <div class="search-type-toggle-element" [ngClass]="searchModel?.stories ? 'active' : ''"
              (click)="showStories()" [ngStyle]="{
                background: searchModel?.stories
                  ? styles.style.mainIdentifyingColor
                  : ''
              }">
              {{ language.stories }}
            </div>
            <div class="search-type-toggle-element" [ngClass]="searchModel?.media ? 'active' : ''" (click)="showMedia()"
              [ngStyle]="{
                background: searchModel?.media
                  ? styles.style.mainIdentifyingColor
                  : ''
              }">
              {{ language.media }}
            </div>
          </div>
        </div>
        <!-- <div class="news-picker flex-center">
          <div
            (click)="fourGrid()"
            [ngClass]="grid == 'Columns4' ? 'active' : ''"
            class="four-columns flex-center"
          >
            <i
              [ngStyle]="{
                color:
                  styles.style && grid == 'Columns4'
                    ? styles.style.mainIdentifyingColor
                    : ''
              }"
              class="fal fa-th first"
            ></i>
            <i
              [ngStyle]="{
                color:
                  styles.style && grid == 'Columns4'
                    ? styles.style.mainIdentifyingColor
                    : ''
              }"
              class="fal fa-th second"
            ></i>
          </div>
          <div
            (click)="threeGrid()"
            [ngClass]="grid == 'Columns3' ? 'active' : ''"
            class="three-columns flex-center"
          >
            <i
              [ngStyle]="{
                color:
                  styles.style && grid == 'Columns3'
                    ? styles.style.mainIdentifyingColor
                    : ''
              }"
              class="fal fa-th"
            ></i>
          </div>
          <div
            (click)="twoGrid()"
            [ngClass]="grid == 'Columns2' ? 'active' : ''"
            class="two-columns flex-center"
          >
            <i
              [ngStyle]="{
                color:
                  styles.style && grid == 'Columns2'
                    ? styles.style.mainIdentifyingColor
                    : ''
              }"
              class="fal fa-th-large"
            ></i>
          </div>
        </div> -->
      </div>
    </div>

    <ng-container *ngIf="searchModel?.searchText?.trim() != '' && searchModel?.stories">
      <h2 class="section-headline secondary" *ngIf="resultStories.stories?.length == 0">
        {{ language.searchNoResults }}
      </h2>
      <div class="news-feed flex" [ngClass]="grid">
        <app-loadersmall *ngIf="loaderStory && resultStories.stories?.length != 0"></app-loadersmall>
        <ng-container *ngFor="let group of storyGroups; let i = index">
          <app-news-group-5 class="group" [group]="group" [config]="resultStories"
            [mirrored]="i % 2 != 0"></app-news-group-5>
        </ng-container>
      </div>
      <div class="lazy-loading-loader-container" *ngIf="lazyLoadingLoader">
        <app-loadersmall></app-loadersmall>
      </div>
    </ng-container>
    <ng-container *ngIf="searchModel?.searchText?.trim() != '' && searchModel?.media">
      <h2 class="section-headline secondary" *ngIf="resultMedia.media?.length == 0">
        {{ language.searchNoResults }}
      </h2>
      <div class="media media-wrapper" id="media-wrapper">
        <app-loadersmall *ngIf="loaderMedia && (resultMedia.media?.length != 0 || true)"></app-loadersmall>
        <div class="media-feed" [ngClass]="grid">
          <ng-container *ngFor="let group of mediaGroups; let i = index">
            <app-media-group class="group" [group]="group" [config]="resultMedia"
              [mirrored]="i % 2 != 0"></app-media-group>
          </ng-container>
        </div>
        <div class="lazy-loading-loader-container" *ngIf="lazyLoadingLoader">
          <app-loadersmall></app-loadersmall>
        </div>
      </div>
    </ng-container>
  </div>
</section>

<app-popup *ngIf="popup" [(hideCounters)]="resultMedia.hideCounters" [(popup)]="popup" [mediaId]="mediaId"
  (popupClosed)="updateMedia($event)" [autoplay]="autoplay" [isPublished]="isPublished"></app-popup>