<div *ngIf="!loader">
  <section class="hero-banner">
    <div class="main-story" >
      <div class="image-opacity" [backgroundUrl]="backgroundUrl" [ngStyle]="{'background-image': 'url(' + backgroundUrl + ')' }">

        <div class="main-headline">
        
          <div class="meta flex">
            <div *ngIf="showCategory" class="category-wrapper" (click)="showSubCategory(selectedFs)">
              <div class="category" [ngClass]="selectedFs?.category.length > 1 ? 'has-child' : ''">
                <div>
                  {{ selectedFs?.category[0] }}
                </div>
              </div>
              <div class="category-dropdown" *ngIf="selectedFs?.dropdown && selectedFs?.category.length > 1">
                <div *ngFor="
                    let category of selectedFs?.category
                      | slice : 1 : selectedFs?.category.length
                  ">
                  {{ category }}
                </div>
              </div>
            </div>
          </div>
          <div class="hero-banner-title">
            {{ selectedFs?.storyTitle }}
          </div>
          <h4>
            {{ selectedFs?.shortDescription }}
          </h4>
          <div class="headline-buttons">
            <div class="main-read-more flex-center" appStyle
              (click)="readMore(selectedFs, selectedFs?.storyTitle, selectedFs)">
              {{ language.readMore }}
            </div>
           
          </div>
        </div>

      </div>
    </div>

    <div class="story-nav-container">
      <div class="story-nav" (click)="prevSlide()">
        <i class="far fa-angle-left"></i>
      </div>
      <div class="story-nav" (click)="nextSlide()">
        <i class="far fa-angle-right"></i>
      </div>
    </div>
  </section>

  <section class="news flex-center">
    <div class="content-wrapper">
      <ng-container>
        <div class="news-feed" *ngIf="homeModelResponse.latestStories.length != 0">

          <ng-container *ngFor="let group of storyGroupings; let i = index">
            <app-news-group-6 [group]="group" [config]="homeModelResponse" [mirrored]="i % 2 != 0"></app-news-group-6>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </section>
 
</div>

<app-popup *ngIf="popup" [(hideCounters)]="homeModelResponse.hideCounters" [(popup)]="popup" [mediaId]="mediaId"
  [autoplay]="autoplay"></app-popup>
<app-loader *ngIf="loader"></app-loader>