import {
  ChangeDetectorRef,
  Component,
  OnInit,
  HostListener,
} from "@angular/core";
import { McService } from "../../shared/services/mc-service";
import {
  WebNewsDetailsMediaModel,
  WebNewsDetailsModel,
} from "../news/news-model";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { saveAs } from "file-saver";
import * as JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";

import { UrlHelper } from "../../shared/helpers/url-helper";
import { TokenManagerService } from "../../shared/services/token-manager.service";
import { PageStyle } from "../../models/style/style.model";
import { FontsAndColorsService } from "../../shared/services/fonts-and-colors.service";
import { DownloadAddToModel } from "../media/media-model";
import { GoogleAnalyticsEventsService } from "../../shared/services/google-analytics-events.service";
import { LanguageService } from "../../shared/services/language.service";
import { Language } from "../../models/language/language.model";
import { DomSanitizer, Meta } from "@angular/platform-browser";
import { MyFilesService } from "../../shared/services/my-files.service";
import { ButtonsHelper } from "../../shared/helpers/buttons-helper";
import { SavedStoryService } from "../../shared/services/saved-story.service";
import { Subscription } from "rxjs";
import { environment } from "../../../environments/environment";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { CustomValidators } from "ng2-validation";
import { HttpRequestService } from "../../shared/services/http-request.service";
import { ToastrConfig } from "../../shared/helpers/toastr-config";
import { ToastrService } from "ngx-toastr";

declare var require: any;
declare var $: any;

@Component({
  selector: "app-competition-page",
  templateUrl: "./competition-page.component.html",
  styleUrls: ["./competition-page.component.less"],
})
export class CompetitionPageComponent implements OnInit {
  title: string =
    "Plane Amazing! Design the easyJet Aircraft of the Future";

  url: string;
  loader: boolean;
  autoplay: boolean;

  user: any;
  styles = new PageStyle();

  socials: string[] = [];
  currentUrl: string;

  language: Language;
  sLng: number;
  token: string;
  relatedStoriesTabOpened = true;
  mediaTabOpened = true;
  isMediaUser: boolean;
  buttonsHelper = ButtonsHelper;
  filters: any;
  imageerrorSubmission = false;
  valForm: FormGroup;
  email: any;
  termsAndConditions: boolean;
  TC: boolean;
  nameOfGuardian: any;
  categoryError: boolean = false;
  categoryDropdown: boolean = false;
  categories = [
    { id: 1, label: "Primary School (7 - 11)", checked: false },
    { id: 2, label: "Secondary School (12 - 16)", checked: false },
  ];

  submissionModel: any = {};
  noFile: boolean = false;
  submissionImage = "";
  saveLayoutUrl =
    environment.apiUrl + "/CompetitionSubmissions/NewCompetitionSubmission";
  tmpSubmissionFile: File[] = [];
  file: File[] = [];
  constructor(
    private languageService: LanguageService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private fontsAndColorsService: FontsAndColorsService,
    private httpService: HttpRequestService
  ) {
    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();
    this.token = localStorage.getItem("Token");

    this.languageService.language.subscribe((language) => {
      this.language = language;
    });
    this.submissionModel.categoryId = 0;
  }

  selectCategory(cat, event) {
    const index = cat.id;
    cat.checked = !cat.checked;
    if (cat.checked) {
      this.submissionModel.categoryId = index;
      this.categories.forEach((element) => {
        if (element.id != index) element.checked = false;
      });
    } else {
      this.submissionModel.categoryId = 0;
      this.categories.forEach((element) => {
        element.checked = false;
      });
    }

    if (event) {
      event.target.style.backgroundColor = event.target.checked
        ? this.styles.style.mainIdentifyingColor
        : "";
    }

    this.categoryError = this.submissionModel.categoryId === 0;

  }

  changeColorButtonn(e) {
    if (e.target.tagName == "DIV") {
      e.target.style.background = this.styles.style.mainButton2HoverColor;
    } else {
      e.target.parentElement.style.background =
        this.styles.style.mainButton2HoverColor;
    }
  }

  changeColorButton(e) {
    if (e.target.tagName == "DIV") {
      e.target.style.background = this.styles.style.mainButton2DefaultColor;
    }
  }

  checkIfEmpty() {
    if (this.email.value === "") {
      this.email.markAsTouched();
    }
  }

  ngOnInit() {
    this.getData();
    this.email = new FormControl(null, [
      CustomValidators.email,
      Validators.required,
    ]);
    this.valForm = this.fb.group({
      firstname: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      lastname: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      file: [null, Validators.compose([Validators.required])],
      terms: [null, Validators.compose([Validators.required])],
      nameOfGuardian: [null, Validators.compose([Validators.required])],
    });
  }

  termsCheck(event) {
    this.submissionModel.termsAndConditions =
      !this.submissionModel.termsAndConditions;
    this.termsAndConditions = !this.termsAndConditions;
    if (!this.termsAndConditions) {
      this.valForm.controls["terms"].setValidators(Validators.required);
      this.TC = false;
    } else {
      this.TC = true;
    }
    event.target.style.backgroundColor = event.target.checked
      ? this.styles.style.mainIdentifyingColor
      : "";
  }

  ngOnDestroy() { }

  getData() {
    this.sLng = Number(localStorage.getItem("lng"));
    this.user = TokenManagerService.getUser();

    if (this.user.role === "MediaUser") {
      this.isMediaUser = false;
    } else if (this.user.role && this.user.role !== "MediaUser") {
      this.isMediaUser = true;
    } else {
      this.isMediaUser = false;
    }
    window.scrollTo(0, 0);
    this.currentUrl = window.location.href;
  }

  showSubCategory(story) {
    story.categoryDropdown = !story.categoryDropdown;
  }

  showSubCategoryRandom(story) {
    story.categoryDropdown = !story.categoryDropdown;
  }

  onClickOutside(event: Object) {
    if (event && event["value"] === true) {
      this.categoryDropdown = false;
    }
  }

  changeIconColor(e) {
    e.target.style.color = this.styles.style.mainIdentifyingColor;
  }

  removeIconHoverColor(e) {
    e.target.style.color = "";
  }

  uploadSubmissionImage(e) {
    if (this.tmpSubmissionFile.length > 0) {
      const index = this.file.indexOf(this.tmpSubmissionFile[0]);
      this.file.splice(index, 1);
    }
    this.tmpSubmissionFile = [];
    const eventObj: MSInputMethodContext = <MSInputMethodContext>e;
    const target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    const files: FileList = target.files;
    this.tmpSubmissionFile.push(files[0]);
    if (files.length > 0) {
      this.file.push(this.tmpSubmissionFile[0]);
      this.imageerrorSubmission = false;
    }
    for (var i = 0; i < e.target.files.length; i++) {
      var file = e.target.files[i];
      var reader = new FileReader();
      reader.onloadend = () => {
        this.submissionImage = reader.result as string;
        this.submissionModel.submissionImage = file.name;
        this.noFile = false;

        setTimeout(() => {
          let image = document.getElementById(
            "submissionImg"
          ) as HTMLImageElement;
        });
      };
      reader.onloadstart = () => {
      };
      reader.onprogress = () => {
      };
      reader.readAsDataURL(file);
    }
  }

  register() {
    this.loader = true;
    this.httpService
      .postMultipart(this.saveLayoutUrl, this.submissionModel, this.file, true)
      .subscribe(
        (response) => {
          this.loader = false;
          // const successMessage = "Submission Successfull!";
          // this.toastr.error(successMessage, "Success!", ToastrConfig.success);
        },
        (error) => {
          this.loader = false;
          console.log(error);
          // this.toastr.error('Some of the selected media has been removed from the story.', 'Error', ToastrConfig.error);
        }
      );
  }

  onSubmit(e) {
    e.stopPropagation();
    for (const c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    this.email.markAsTouched();

    if (this.valForm.valid && this.email.valid && this.termsAndConditions && this.submissionModel.categoryId !== 0) {
      if (this.file)
        this.register(); else
        this.noFile = true;
    } else {
      Object.keys(this.valForm.controls).forEach((key) => {
        const controlErrors: ValidationErrors = this.valForm.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach((keyError) => {
            console.log(
              "Key control: " +
              key +
              ", keyError: " +
              keyError +
              ", err value: ",
              controlErrors[keyError]
            );
          });
        }
      });
    }
    this.categoryError = this.submissionModel.categoryId === 0;
  }

  openCategoryDropdown(){
    this.categoryDropdown = !this.categoryDropdown; 
    this.categoryError = this.submissionModel.categoryId === 0;
  }
}
