<section class="login flex" ngClass="{login-dna : isDna}">
  <div class="register-wrapper">
    <div class="selector-container">
      <h1 class="selector-item">
        Log in
      </h1>
    </div>
    <form action="" class="register-form flex" [formGroup]="valForm" (ngSubmit)="submitLoginForm($event)">
      <div class="form-element flex">
        <input type="email" id="email" name="email" placeholder="{{language.enterEmail}}" [(ngModel)]="loginModel.email"
          formControlName="username" />
        <span class="text-danger" *ngIf="
            valForm.controls['username'].hasError('required') &&
            (valForm.controls['username'].dirty ||
              valForm.controls['username'].touched)
          ">
          {{ language.emailNameRequired }}
        </span>
        <span class="text-danger" *ngIf="
            valForm.controls['username'].hasError('email') &&
            (valForm.controls['username'].dirty ||
              valForm.controls['username'].touched)
          ">{{ language.invalidEmail }}</span>
      </div>
      <div class="form-element flex">
        <div class="eye-icon" (click)="visiblePassword()">
          <img *ngIf="showPassword" src="../../assets/images/eye-black.svg" alt="Show password" />
          <img *ngIf="!showPassword" src="../../assets/images/eye-black-hide.svg" alt="Hide password" />
        </div>
        <input type="password" name="password" id="password" placeholder="{{language.enterPassword}}" [type]="type"
          [(ngModel)]="loginModel.password" formControlName="password" />
        <span class="text-danger" *ngIf="
            valForm.controls['password'].hasError('required') &&
            (valForm.controls['password'].dirty ||
              valForm.controls['password'].touched)
          ">
          Password is required
        </span>
      </div>
      <div class="reset-pass">
        <p>Forgot Password?</p>
        <span (click)="forgotPassword()">{{ language.forgotPassword }}</span>
      </div>
      <div class="register-buttons flex-center">
        <button type="submit" class="generic-button submit flex-center">
          {{ language.submit }}
        </button>
      </div>
      <div class='other-options'>
        <!-- <h4>OR</h4>
        <div class='redes'>
          <a routerLink=''><img src='../../assets/images/google.svg' alt='Google'></a>
          <a routerLink=''><img src='../../assets/images/twitter.svg' alt='Google'></a>
          <a routerLink=''><img src='../../assets/images/linkedin.svg' alt='Google'></a>
        </div>
        <div class='view-more-options'>View more options</div> -->
        <div class='register-account'>Don't have an account? <a href='/register'>Sign up</a></div>
      </div>
    </form>
  </div>
  <div class='banner-img desktop-only'>
    <img src="../../assets/images/Dna_login.png" alt="" >
  </div>
</section>
<app-loader *ngIf="loader"></app-loader>