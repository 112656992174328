<section class="news-page flex-center">
  <div class="content-wrapper">
    <h1 class="hidden-h1">News</h1>
    <div class="news-feed">
      <section class="filters-container">

        <!-- <div class="order-by-container">
          <input placeholder="Order by" type="search">
          <img src="/assets/icons/arrow.svg">
        </div> -->

        <!-- <div class="select-date order-by-container">
          <div class="filter-select-container">
            <select class="filter-select" [(ngModel)]="selectedSort" name="orderBy">
              <option value="0">Order By:</option>
              <option value="date-ASC">Date Ascending </option>
              <option value="date-DESC">Date Descending </option>
              <option value="alpha">Alphabetical </option>
            </select>
          </div>
        </div> -->

        <!-- Order by: DNA--> 
        <div class="select-date order-by-container">
          <div class="filter-select-container" (change)="onSortChange($event.target.value)">
            <select class="filter-select" [(ngModel)]="sortBy" name="orderBy">
              <option value="" selected data-default disabled >Order by</option>
              <option value="title-ASC">Title Ascending </option>
              <option value="title-DESC">Title Descending </option>
              <option value="date-ASC">Date Ascending </option>
              <option value="date-DESC">Date Descending </option>
            </select>
          </div>
        </div>

        <div class="filter-button-container" (click)="openFilters()">
          <img src="/assets/icons/filter-logo.svg">
          <span>Filter by</span>
        </div>
      </section>

      <div class="filter-options-container" [ngClass]="{ visible: filtersVisible, brandColor: isEasyJet }">
        <div class="filter-title">
          {{ language.filterBy || "FilterBy:" }}
          <i class="far fa-times mobile-only" (click)="toggleFilters()"></i>
        </div>
        <div class="left-and-right">
          <div class="left-part">

            <!-- SELECT MARKET -->
            <div class="select-date" *ngIf="!isInMarketPage && markets?.length > 0">
              <div class="filter-select-container">
                <select class="filter-select" name="markets" [(ngModel)]="selectedMarket">
                  <option value="0">All</option>
                  <option *ngFor="let market of markets" value="{{ market.marketId }}">
                    {{ market.marketName }}
                  </option>
                </select>
              </div>
            </div>

            <!-- SELECT BRAND -->
            <div class="select-date" *ngIf="searchModelData.subClients.length > 0">
              <div class="filter-select-container">
                <select id="brandSelect" class="filter-select" (change)="onChangeBrand($event.target.value)">
                  <option value="0">{{ language.selectBrand }}</option>
                  <option *ngFor="let brand of searchModelData.subClients" value="{{ brand.id }}">
                    {{ brand.label }}
                  </option>
                </select>
              </div>
            </div>

            <!-- SELECT CATEGORY -->
            <div class="select-date" *ngIf="searchModelData?.customCategories?.length > 0">
              <div class="filter-select-container">
                <select class="filter-select" [(ngModel)]="selectedCustomCategory" name="category">
                  <option value="0">{{ language.selectCategory }}</option>
                  <option *ngFor="let category of searchModelData.customCategories" value="{{ category.id }}">
                    {{ category.label }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Order by: vodafone--> 
              <div class="select-date" *ngIf="hasStoryCodes">
                <div class="filter-select-container" style="width: 110px;">
                  <select class="filter-select" [(ngModel)]="selectedSort" name="orderBy">
                    <option value="0">Order by:</option>
                    <option value="code-ASC" (click)="getNews(p = 1, true)">Code Ascending </option>
                    <option value="code-DESC" (click)="getNews(p = 1, true)">Code Descending </option>
                    <option value="date-ASC" (click)="getNews(p = 1, true)">Date Ascending </option>
                    <option value="date-DESC" (click)="getNews(p = 1, true)">Date Descending </option>
                  </select>
                </div>
              </div>

            <!-- CODE vodafone-->
              <div class="select-date" *ngIf="hasStoryCodes" style="height: 100%;">
                <div class="buttons-wrapper flex codeFilterContainer">
                  <div style="font-size: 13px;">Code</div>
                  <div class="from">
                    <input placeholder="{{ language.from }}" [(ngModel)]="searchModelData.searchModel.codeFrom" autocomplete="off" id="code-from" class="codeFilter" type="string" />
                  </div>
                          
                  <div class="to">
                    <input placeholder="{{ language.to }}" [(ngModel)]="searchModelData.searchModel.codeTo" autocomplete="off" id="code-to" class="codeFilter" type="string" />
                  </div>
                </div>
              </div>


          </div>
          <div class="right-part">
            <!-- SEARCH -->
            <div class="search-input-wrapper">
              <div class="search-icon"> <img src="../../assets/icons/searchIcon.png" /> </div>
              <input placeholder="{{ language.search }}" autocomplete="off" id="story-search-big" type="search"
                [(ngModel)]="searchModelData.searchModel.searchText" />
            </div>

            <!-- DATE -->
            <div class="select-date">
              <div class="buttons-wrapper flex">
                <div class="from">
                  <div class="calendar-input flex">
                    <i class="fal fa-calendar-alt desktop-only" (click)="openDateFrom()"></i>
                    <dx-date-box [(value)]="searchModelData.searchModel.dateFrom" [opened]="dateFromButton"
                      [displayFormat]="'dd/MM/yyyy'" type="date" (onValueChanged)="changeFromDate($event)"
                      name="dateFrom" [placeholder]="language.from"
                      [readOnly]="true">
                    </dx-date-box>
                  </div>
                </div>
                <div class="to">
                  <div class="calendar-input flex">
                    <i class="fal fa-calendar-alt desktop-only" (click)="openDateTo()"></i>
                    <dx-date-box [(value)]="searchModelData.searchModel.dateTo" [opened]="dateToButton"
                      [displayFormat]="'dd/MM/yyyy'" (onValueChanged)="changeToDate($event)" type="date" name="dateTo"
                      [placeholder]="language.to"
                      [readOnly]="true">
                    </dx-date-box>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="buttons">
          <div class="generic-button filter-toggle-button flex" (click)="getNews(p = 1, true); toggleFilters()">
            <span>
              {{ language.applyFilters || 'Apply Filters' }}
            </span>
          </div>
          <div class="generic-button filter-toggle-button flex" (click)="clearFilters(); toggleFilters()">
            <span>
              {{ language.clearFilters || 'Clear Filters' }}
            </span>
          </div>
        </div>
      </div>
      <div class="filter-options-container-dummy"></div>
      <div class="news-feed flex" *ngIf="!hasStoryCodes && !isEasyJet">
        <ng-container *ngFor="let group of storyGroups; let i = index">
          <app-news-group-5 class="group" [group]="group" [config]="config" [mirrored]="i % 2 != 0"></app-news-group-5>
        </ng-container>
        <div id="noStories" *ngIf="storyGroups[0].length === 0" >No stories in this folder</div>
      </div>
      <div class="news-feed flex wrap" *ngIf="!hasStoryCodes && isEasyJet">
        <ng-container *ngFor="let story of algoliaStories; let i = index">
          <app-story-preview-5 class="news-item" [story]="story" [config]="config" [template]="4"></app-story-preview-5>
        </ng-container>
        <div id="noStories" *ngIf="algoliaStories.length === 0" >No stories in this folder</div>

      </div>
      <div class="news-feed flex" *ngIf="hasStoryCodes && !searchModelData.searchModel.marketId && !isInMarketPage">
        <ng-container *ngFor="let groupedStory of groupedStories; let i = index">
          <app-code-story class="group" [storiesByCode]="groupedStory" [(loginPopup)]="loginPopup"
            [mustRegisterToDownload]="webListResponse.mustRegisterToDownload"
            [anonymousShare]="webListResponse.anonymousShare"></app-code-story>
        </ng-container>
        <div id="noStories" *ngIf="groupedStories.length === 0">No stories in this folder</div>

      </div>
      <div class="news-feed grid" *ngIf="hasStoryCodes && (searchModelData.searchModel.marketId || isInMarketPage)">
        <ng-container *ngFor="let story of webListResponse.stories; let i = index">
          <app-article [story]="story" [marketName]="selectedMarket.marketName"
            [mustRegisterToDownload]="mustRegisterToDownload" [anonymousShare]="anonymousShare"></app-article>
        </ng-container>
        <div id="noStories" *ngIf="webListResponse.stories.length === 0">No stories in this folder</div>

      </div>
    </div>

    <div class="load-more-wrapper">
      <div class="lazy-loading-loader-container" *ngIf="lazyLoadingLoader">
        <app-loadersmall></app-loadersmall>
      </div>
      <div class="break"></div>
    </div>
  </div>
</section>
<app-loader *ngIf="loader"></app-loader>


<div class="bkg-filter" [ngClass]="{ active: bkgFilters }"></div>
<section class="open-filters-container" [ngClass]="{ open: this.filters }">
  <div class="filterHeader">
      <div class="filterTitle">Filter by</div>
      <div id="closeFilter" (click)="closeFilters()">
          X
      </div>
  </div>
 
  <div id="filters">

    <!-- SELECT MARKET  -->
    <div class="filterDropdown" *ngIf="!isInMarketPage && markets?.length > 0">
      <div class="filterNameDropdown" (click)="toggleDropdowFilter('markets')">
        <div class="filterNameText">Markets</div>
        <div class="openCloseFilter">+</div>
      </div>

      <div class="filtersContainer" [(ngModel)]="selectedMarket" [ngClass]="{ open: this.dropdownFilterMarkets }">
        <div id="all-markets-filter" class="categoryDiv">
          <div class="select-market" *ngIf="!isInMarketPage && markets?.length > 0">
            <div class="filter-select-container">
              <select class="filter-select" name="markets" [(ngModel)]="selectedMarket">
                <option value="0">All</option>
                <option *ngFor="let market of markets" value="{{ market.marketId }}">
                  {{ market.marketName }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- SELECT BRAND  -->
    <div class="filterDropdown" *ngIf="searchModelData.subClients.length > 0">
      <div class="filterNameDropdown" (click)="toggleDropdowFilter('brands')">
        <div class="filterNameText">Brands</div>
        <div class="openCloseFilter">+</div>
      </div>
      <div id="brandSelect" class="filter-select filtersContainer" [ngClass]="{ open: this.dropdownFilterBrands }" (change)="onChangeBrand($event.target.value)">
        <div class="categoryDiv">
          <div class="select-date" *ngIf="searchModelData.subClients.length > 0">
            <div class="filter-select-container">
              <select id="brandSelect" class="filter-select" (change)="onChangeBrand($event.target.value)">
                <option value="0">{{ language.selectBrand }}</option>
                <option *ngFor="let brand of searchModelData.subClients" value="{{ brand.id }}">
                  {{ brand.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

   <!-- SELECT CATEGORY  -->
   <div class="filterDropdown select-category" *ngIf="searchModelData?.customCategories?.length > 0">
      <div class="filterNameDropdown" (click)="toggleDropdowFilter('categories')">
        <div class="filterNameText">Category</div>
        <div class="openCloseFilter">+</div>
      </div>
      <div id="brandSelect" class="filter-select filtersContainer" [ngClass]="{ open: this.dropdownFilterCategory }" [(ngModel)]="selectedCustomCategory">
        <div class="categoryDiv">
          <div class="select-date " *ngIf="searchModelData?.customCategories?.length > 0">
            <div class="filter-select-container">
              <select class="filter-select" [(ngModel)]="selectedCustomCategory" name="category">
                <option value="0">{{ language.selectCategory }}</option>
                <option *ngFor="let category of searchModelData.customCategories" value="{{ category.id }}">
                  {{ category.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- DATE -->
    <div class="filterDropdown">
      <div class="filterNameDropdown" (click)="toggleDropdowFilter('date')">
        <div class="filterNameText">Date</div>
        <div class="openCloseFilter">+</div>
      </div>
      <div class="selectedFiltersBox"></div>
      <div id="brandSelect" class="filter-select filtersContainer" [ngClass]="{ open: this.dropdownFilterDate }" [(ngModel)]="selectedCustomCategory">
        <div class="select-date">
          <div class="buttons-wrapper flex">
            <div class="from">
              <div class="calendar-input flex">
                <i class="fal fa-calendar-alt desktop-only" (click)="openDateFrom()"></i>
                <dx-date-box [(value)]="searchModelData.searchModel.dateFrom" [opened]="dateFromButton"
                  [displayFormat]="'dd/MM/yyyy'" type="date" (onValueChanged)="changeFromDate($event)"
                  name="dateFrom" [placeholder]="language.from" class="container-date"
                  [readOnly]="true">
                </dx-date-box>
              </div>
            </div>
            <div class="to">
              <div class="calendar-input flex">
                <i class="fal fa-calendar-alt desktop-only" (click)="openDateTo()"></i>
                <dx-date-box [(value)]="searchModelData.searchModel.dateTo" [opened]="dateToButton"
                  [displayFormat]="'dd/MM/yyyy'" (onValueChanged)="changeToDate($event)" type="date" name="dateTo"
                  [placeholder]="language.to" class="container-date"
                  [readOnly]="true">
                </dx-date-box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div id="cleanFiltersContainer">
      <div class="tr-3" id="cleanFilters" (click)="clearFilters(); toggleFilters()">
        {{ language.clearFilters || 'Clear Filters' }}
      </div>
      <div class="tr-3" id="applyFilters" (click)="toggleFilters(); getNews(1, selectedType, true)">
        {{ language.applyFilters || 'Apply Filters' }}
      </div>
  </div>
</section>


     <!-- SELECT MARKET 
    <div class="select-market" *ngIf="!isInMarketPage && markets?.length > 0">
      <div class="filter-select-container">
        <select class="filter-select" name="markets" [(ngModel)]="selectedMarket">
          <option value="0">All</option>
          <option *ngFor="let market of markets" value="{{ market.marketId }}">
            {{ market.marketName }}
          </option>
        </select>
      </div>
    </div>

    SELECT BRAND 
    <div class="select-date" *ngIf="searchModelData.subClients.length > 0">
      <div class="filter-select-container">
        <select id="brandSelect" class="filter-select" (change)="onChangeBrand($event.target.value)">
          <option value="0">{{ language.selectBrand }}</option>
          <option *ngFor="let brand of searchModelData.subClients" value="{{ brand.id }}">
            {{ brand.label }}
          </option>
        </select>
      </div>
    </div>

   SELECT CATEGORY 
    <div class="select-date" *ngIf="searchModelData?.customCategories?.length > 0">
      <div class="filter-select-container">
        <select class="filter-select" [(ngModel)]="selectedCustomCategory" name="category">
          <option value="0">{{ language.selectCategory }}</option>
          <option *ngFor="let category of searchModelData.customCategories" value="{{ category.id }}">
            {{ category.label }}
          </option>
        </select>
      </div>
    </div>
  
    DATE 
    <div class="select-date">
      <div class="buttons-wrapper flex">
        <div class="from">
          <div class="calendar-input flex">
            <i class="fal fa-calendar-alt" (click)="openDateFrom()"></i>
            <dx-date-box [(value)]="searchModelData.searchModel.dateFrom" [opened]="dateFromButton"
              [displayFormat]="'dd/MM/yyyy'" type="date" (onValueChanged)="changeFromDate($event)"
              name="dateFrom" [placeholder]="language.from">
            </dx-date-box>
          </div>
        </div>
        <div class="to">
          <div class="calendar-input flex">
            <i class="fal fa-calendar-alt" (click)="openDateTo()"></i>
            <dx-date-box [(value)]="searchModelData.searchModel.dateTo" [opened]="dateToButton"
              [displayFormat]="'dd/MM/yyyy'" (onValueChanged)="changeToDate($event)" type="date" name="dateTo"
              [placeholder]="language.to">
            </dx-date-box>
          </div>
        </div>
      </div>
    </div>
  
  -->