import { Component, OnInit } from "@angular/core";
import { McService } from "./shared/services/mc-service";
import { Meta, MetaDefinition, Title } from "@angular/platform-browser";
import { MetaModel } from "./models/meta/meta";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Location } from "@angular/common";
import { GoogleAnalyticsEventsService } from "./shared/services/google-analytics-events.service";
import { LayoutModel } from "./layout/layout-model";
import { PageStyle } from "./models/style/style.model";
import { Language } from "./models/language/language.model";
import { StorageService } from "./shared/services/storage.service";
import { FontsAndColorsService } from "./shared/services/fonts-and-colors.service";
import { LanguageService } from "./shared/services/language.service";
import { ToastContainerDirective, ToastrService } from "ngx-toastr";
import { OutBoundLinkClickInterceptService } from "./shared/services/outbound-link-click-intercept.service";
import { UserCentricsService } from "./shared/services/user-centrics.service";

declare const Usercentrics: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less"],
})
export class AppComponent implements OnInit {
  toastContainer: ToastContainerDirective;

  title = "app";
  metadatas: MetaModel;

  styles: PageStyle;
  layoutResponseModel: LayoutModel;
  loader = true;
  // language=new Language();
  sLng: number;
  language: Language;

  public toasterconfig = {
    closeButton: true,
    timeOut: 2000,
  };

  constructor(
    private mcService: McService,
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private googleAnalytics: GoogleAnalyticsEventsService,
    private userCentrics: UserCentricsService,
    private sService: StorageService,
    public fontsAndColorsService: FontsAndColorsService,
    public languageService: LanguageService,
    private toastrService: ToastrService,
    private outboundLinkClickInterceptService: OutBoundLinkClickInterceptService
  ) { }

  ngOnInit() {
    this.toastrService.overlayContainer = this.toastContainer;
    this.sLng = Number(localStorage.getItem("lng"));
    this.getMeta();
    this.getStyle();
  }

  setupUserCentrics() {
    if (window) {
      let key = "uL39q46aEoNsHf";
      if (this.metadatas.UCID && this.metadatas.UCID != "") {
        key = this.metadatas.UCID;
      }
      const script = document.createElement("script");
      script.id = "usercentrics-cmp";
      script.src = "https://app.usercentrics.eu/browser-ui/latest/loader.js";
      script.setAttribute("data-settings-id", key);
      script.async = true;
      script.addEventListener("load", () => {
        console.log("Usercentrics script loaded");
      });
      document.head.appendChild(script);
      window.addEventListener("ucEvent", (event: any) => {
        if (event?.detail?.event === "consent_status")
          this.userCentrics.setConsents(event.detail);
      });
    }
  }

  getNewTitlelFromUrl(url: string): string {
    if (url == "") return "Home";
    if (url == "stories") return "News";
    if (url == "media-library") return "Media";
    if (url == "sign-in") return "SignIn";
    if (url == "about-us") return "AboutUs";
    if (url == "register") return "Register";
    if (url == "brands") return "Brands";
    if (url == "legal") return "Legal";
    if (url == "contact-us") return "ContactUs";
    if (url == "contacts") return "Contacts";
  }

  loadMetadata() {
    this.addGoogleVerificationMeta();
    let newTitle = this.getNewTitlelFromUrl(
      this.router.routerState.snapshot.url.split("/")[1]
    );
    if (this.metadatas.metadatas.length === 0) {
      this.titleService.setTitle("DNA - Digital News Agency");
    }
    for (let x = 0; x < this.metadatas.metadatas.length; x++) {
      if (this.metadatas.metadatas[x].pageType.includes(newTitle)) {
        if (this.metadatas.metadatas[x].pageTitle !== "") {
          this.titleService.setTitle(this.metadatas.metadatas[x].pageTitle);
        } else {
          this.titleService.setTitle(this.metadatas.metadatas[x].pageType);
        }
        this.meta.updateTag({
          name: "keywords",
          content: this.metadatas.metadatas[x].keywords,
        });
        this.meta.updateTag({
          name: "description",
          content: this.metadatas.metadatas[x].description,
        });
        this.meta.updateTag({
          name: "twitter:title",
          content: this.metadatas.metadatas[x].twitterTitle,
        });
        this.meta.updateTag({
          name: "twitter:description",
          content: this.metadatas.metadatas[x].twitterDescritption,
        });
        this.meta.updateTag({
          name: "twitter:image",
          content: this.metadatas.metadatas[x].twitterImage,
        });
      }
    }
    this.addDefaultTitle(newTitle);

    // replace hardcoded tracking id with tracking id from database
    // this.metadatas.trackingID = "HOLA";
    if (this.metadatas.trackingID) {
      this.googleAnalytics.initGoogleAnalytics(this.metadatas.trackingID);
    }
    this.googleAnalytics.emitPageView(this.router.routerState.snapshot.url);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const title = this.router.routerState.snapshot.url;
        this.googleAnalytics.emitPageView(event.urlAfterRedirects);
        this.convertUrlToTitle(title);
      }
    });
  }

  addGoogleVerificationMeta() {
    var websitesWithMeta: Array<String> = [
      "https://bacardibrand.bacardilimited.com",
      "https://bacardigroup.bacardilimited.com",
      "https://bombaysapphire.bacardilimited.com",
      "https://dewars.bacardilimited.com",
      "http://mediacentre.easyjet.com",
      "http://press.economist.com",
      "https://greygoose.bacardilimited.com",
      "https://martini.bacardilimited.com",
      "https://digitalnewsagency.com",
    ];

    var actualWebsite: String = window.location.origin;

    var metaDefinition: MetaDefinition = {
      name: "google-site-verification",
      content: "7QMHUSc9Z4GkuYOm1zXTIQdSqf1c-VjzgaLqDgGfArU",
    };

    if (websitesWithMeta.includes(actualWebsite)) {
      this.meta.updateTag(metaDefinition);
    }
  }

  getMeta() {
    this.mcService.getMeta().subscribe(
      (response: any) => {
        this.metadatas = response;
        this.loadMetadata();
        this.setupUserCentrics();
      },
      (error) => {
        //
      }
    );
  }

  addDefaultTitle(newTitle: string) {
    if (!this.metadatas.metadatas.find((x) => x.pageType === newTitle)) {
      newTitle = newTitle;
      this.titleService.setTitle(this.formatUrlString(newTitle));
      this.meta.updateTag({ name: "keywords", content: newTitle });
      this.meta.updateTag({ name: "description", content: newTitle });
      this.meta.updateTag({ name: "twitter:title", content: newTitle });
      this.meta.updateTag({ name: "twitter:description", content: newTitle });
      this.meta.updateTag({ name: "twitter:image", content: "" });
    }

    if (this.router.routerState.snapshot.url === "/sign-in") {
      this.titleService.setTitle("Sign in");
      this.meta.updateTag({ name: "keywords", content: "Sign in" });
      this.meta.updateTag({ name: "description", content: "Sign in" });
      this.meta.updateTag({ name: "twitter:title", content: "Sign in" });
      this.meta.updateTag({ name: "twitter:description", content: "Sign in" });
      this.meta.updateTag({ name: "twitter:image", content: "" });
    } else if (this.router.routerState.snapshot.url === "/register") {
      this.titleService.setTitle("Register");
      this.meta.updateTag({ name: "keywords", content: "Register" });
      this.meta.updateTag({ name: "description", content: "Register" });
      this.meta.updateTag({ name: "twitter:title", content: "Register" });
      this.meta.updateTag({ name: "twitter:description", content: "Register" });
      this.meta.updateTag({ name: "twitter:image", content: "" });
    } else if (this.router.routerState.snapshot.url === "/brands") {
      this.titleService.setTitle("Brands");
      this.meta.updateTag({ name: "keywords", content: "Brands" });
      this.meta.updateTag({ name: "description", content: "Brands" });
      this.meta.updateTag({ name: "twitter:title", content: "Brands" });
      this.meta.updateTag({ name: "twitter:description", content: "Brands" });
      this.meta.updateTag({ name: "twitter:image", content: "" });
    }
  }

  convertUrlToTitle(title) {
    let newTitle = this.getNewTitlelFromUrl(title.split("/")[1]);
    if (this.metadatas.metadatas.length === 0) {
      this.titleService.setTitle("DNA - Digital News Agency");
    }
    for (let x = 0; x < this.metadatas.metadatas.length; x++) {
      if (this.metadatas.metadatas[x].pageType.includes(newTitle)) {
        if (this.metadatas.metadatas[x].pageTitle !== "") {
          this.titleService.setTitle(this.metadatas.metadatas[x].pageTitle);
        } else {
          this.titleService.setTitle(this.metadatas.metadatas[x].pageType);
        }
        this.meta.updateTag({
          name: "keywords",
          content: this.metadatas.metadatas[x].keywords,
        });
        this.meta.updateTag({
          name: "description",
          content: this.metadatas.metadatas[x].description,
        });
        this.meta.updateTag({
          name: "twitter:title",
          content: this.metadatas.metadatas[x].twitterTitle,
        });
        this.meta.updateTag({
          name: "twitter:description",
          content: this.metadatas.metadatas[x].twitterDescritption,
        });
        this.meta.updateTag({
          name: "twitter:image",
          content: this.metadatas.metadatas[x].twitterImage,
        });
      }
    }
    this.addDefaultTitle(newTitle);
  }

  getStyle() {
    this.mcService.getStyle().subscribe(
      (response: any) => {
        this.styles = response;
        this.fontsAndColorsService.setPageStyleInfo(response);
        this.loader = false;
      },
      (error) => {
        //
        this.loader = false;
      }
    );
  }

  formatUrlString(url: string): string {
    let newUrl = "";

    if (url) {
      newUrl = url.charAt(0).toUpperCase() + url.slice(1);
      const words = newUrl.split("-");
      if (words.length > 1) {
        newUrl = words[0] + " " + words[1];
      }
    }

    return newUrl;
  }
}
