import { Component, Input, OnInit } from '@angular/core';
import { McService } from '../shared/services/mc-service';
import { deleteModel, DownloadMediaModel, DownloadModel } from './download-model';
import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';
import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenManagerService } from '../shared/services/token-manager.service';
import { ToastrService } from 'ngx-toastr';
import { DownloadAddToModel } from '../routes/media/media-model';
import { PageStyle } from '../models/style/style.model';
import { FontsAndColorsService } from '../shared/services/fonts-and-colors.service';
import { GoogleAnalyticsEventsService } from '../shared/services/google-analytics-events.service';
import { LanguageService } from '../shared/services/language.service';
import { Language } from '../models/language/language.model';
import { UrlHelper } from '../shared/helpers/url-helper';
import { ToastrConfig } from '../shared/helpers/toastr-config';
import { WebpHelperService } from '../shared/services/webp-helper.service';
import { MyFilesService } from '../shared/services/my-files.service';
import { MyFilesModel } from '../shared/services/my-files-model';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.less']
})
export class DownloadComponent implements OnInit {
  @Input() dropdownSize: string;

  loginPopup: boolean;
  popup: boolean;
  loader: boolean;
  autoplay: boolean;
  isUrlCopied: boolean;
  disabledButton = false;
  dropdown = false;
  downloadActive = false;
  cancelDownloads = false;

  mediaId: number;
  categoryLabel: string;
  shareCartUrl: string;

  styles: PageStyle;
  videoFormats: DownloadMediaModel[] = [];
  deleteModel: deleteModel[] = [];
  downloadMediaResponse: DownloadModel;
  language: Language;

  cartItems: MyFilesModel;
  forDownload: any[] = [];
  publishedDownloadIds: any[] = [];
  storyDownloadIds: any[] = [];
  user: any;
  downloadSubscription: any;

  constructor(private route: ActivatedRoute, private mcService: McService, private http: HttpClient, private languageService: LanguageService,
    private router: Router, private toastr: ToastrService, public fontsAndColorsService: FontsAndColorsService,
    private googleAnalytics: GoogleAnalyticsEventsService, public webPHelper: WebpHelperService, private myFilesService: MyFilesService) {

    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();

    this.languageService.language.subscribe(
      (language) => {
        this.language = language;
      }
    );

    this.route.params.subscribe(params => {
      if (params.ids) {
        // 
        const cartIds = JSON.parse(decodeURIComponent(params.ids));
        localStorage.setItem('cart', JSON.stringify(cartIds));
      }
    });
  }

  ngOnInit() {
    this.user = TokenManagerService.getUser();
    this.getDownloadBox();
    window.scrollTo(0, 0);
    this.popup = false;
    this.categoryLabel = this.language.availableFormats;
  }

  getDownloadBox() {
    this.loader = true;
    this.cartItems = this.myFilesService.getMyFiles();

    this.mcService.downloadBox(this.cartItems)
      .subscribe((response: any) => {
        this.downloadMediaResponse = response;
        if (this.downloadMediaResponse.mustRegisterToDownload && localStorage.getItem('Token') === null) {
          window.scrollTo(0, 0);
          // this.loginPopup = true;
          // return;
          this.router.navigateByUrl('/sign-in');

        }
        // if (response.mustRegisterToAccess && localStorage.getItem('Token') == null) {
        //   this.router.navigateByUrl('/register');
        // }

        let mediaLength = 0;

        for (const item of this.downloadMediaResponse.downloadBoxList) {
          for (const media of item.media) {
            mediaLength++;
          }
        }

        if (this.cartItems.length > mediaLength) {

          this.cartItems = new MyFilesModel([], []);

          for (const item of this.downloadMediaResponse.downloadBoxList) {
            for (const media of item.media) {
              if (media.media.isPublished) {
                this.cartItems.publishedMediaIds.push(media.media.id);
              } else {
                this.cartItems.storyMediaIds.push(media.media.id);
              }
            }

          }

          this.myFilesService.setMyFiles(this.cartItems);

          this.toastr.error('Some of the selected media has been removed from the story.', 'Error', ToastrConfig.error);

        }

        this.loader = false;

        this.initVideoFormats(this);
      },
        (error2) => {
          //
        });
  }

  mediaChecked(media, dl, event) {
    const component = this;
    media.checked = !media.checked;
    const index = this.forDownload.indexOf(media);
    if (component.forDownload.indexOf(media) === -1 && media.checked) {
      component.forDownload.push(media.downloadUrl);
      if (dl.isPublished) {
        component.publishedDownloadIds.push(dl.id);
      } else {
        component.storyDownloadIds.push(dl.id);
      }
      const delModel = new deleteModel();
      delModel.id = dl.id;
      delModel.url = media.downloadUrl;
      component.deleteModel.push(delModel);

    } else if (!media.checked) {
      component.forDownload.splice(index, 1);
      if (dl.isPublished) {
        component.publishedDownloadIds.splice(component.publishedDownloadIds.indexOf(dl.id), 1);
      } else {
        component.storyDownloadIds.splice(component.storyDownloadIds.indexOf(dl.id), 1);
      }
    }
    event.target.style.backgroundColor = event.target.checked ? this.styles.style.mainIdentifyingColor : '';
  }

  openMediaPopup(media) {
    this.autoplay = false;
    this.mediaId = media.id;
    this.popup = true;
    media.views++;
  }

  openMediaPopupAutoplay(media) {
    this.autoplay = true;
    this.mediaId = media.id;
    this.popup = true;
    media.views++;
  }

  downloadVideoType(vt) {
    const component = this;
    // component.disabledButton = true;
    const zip = new JSZip();
    let count = 0;
    const zipFilename = 'download.zip';
    const urls = [];

    this.loader = true;

    for (let i = 0; i < component.downloadMediaResponse.downloadBoxList.length; i++) {

      const mediaDeleteList = [];

      for (let y = 0; y < component.downloadMediaResponse.downloadBoxList[i].media.length; y++) {
        for (let z = 0; z < component.downloadMediaResponse.downloadBoxList[i].media[y].downloadLinks.length; z++) {
          if (component.downloadMediaResponse.downloadBoxList[i].media[y].downloadLinks[z].label === vt.label &&
            component.downloadMediaResponse.downloadBoxList[i].media[y].media.mediaType === 'Video') {
            const split = component.downloadMediaResponse.downloadBoxList[i].media[y].downloadLinks[z].downloadUrl.split('/');
            const filename = split[split.length - 1];

            urls.push(component.downloadMediaResponse.downloadBoxList[i].media[y].downloadLinks[z].downloadUrl);

            mediaDeleteList.push(this.downloadMediaResponse.downloadBoxList[i].media[y]);
          }
        }
      }

      for (let x = 0; x < mediaDeleteList.length; x++) {
        this.downloadMediaResponse.downloadBoxList[i].media
          .splice(this.downloadMediaResponse.downloadBoxList[i].media.indexOf(mediaDeleteList[x]), 1);
      }

    }


    urls.forEach(function (url) {
      const filename = url.split('/')[8];

      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          throw err; // or handle the error
        }
        zip.file(filename, data, { binary: true });
        count++;
        if (count === urls.length) {

          zip.generateAsync({ type: 'blob' }).then(function (content) {

            saveAs(content, zipFilename);
            component.loader = false;
            component.resetDownloadArrays(component);

            if (component.user.cmsUser == null) {
              component.addDownload();
            }
          });
        }
      });
    });

  }


  addDownload() {
    this.user = TokenManagerService.getUser();
    if (localStorage.getItem('Token') === null) {
      this.user.id = null;
    }
    const niz = new DownloadAddToModel();
    niz.publishedMediaIds = this.publishedDownloadIds;
    niz.storyMediaIds = this.storyDownloadIds;
    niz.userId = this.user.id;
    niz.storyMediaIds.forEach(mediaId => {
      this.googleAnalytics.emitEvent('media/' + mediaId, 'download', niz.userId.toString());
    });
    niz.publishedMediaIds.forEach(mediaId => {
      this.googleAnalytics.emitEvent('media/' + mediaId, 'download-pm', niz.userId.toString());
    });
    this.mcService.addDownloadToMedia(niz)
      .subscribe((response) => {
      },
        (error) => {
          //
        });
  }

  cancelDownload() {
    if (this.downloadSubscription) {
      this.downloadSubscription.unsubscribe();
    }

    this.downloadActive = false;
    this.disabledButton = false;
    this.cancelDownloads = true;
  }

  download() {
    const component = this;
    const urls = this.forDownload;
    const zip = new JSZip();
    let count = 0;
    const zipFilename = 'download.zip';

    if (this.forDownload.length > 1) {

      for (let i = 0; i < this.downloadMediaResponse.downloadBoxList.length; i++) {

        const mediaDeleteList = [];

        for (let j = 0; j < this.downloadMediaResponse.downloadBoxList[i].media.length; j++) {
          for (let c = 0; c < this.deleteModel.length; c++) {
            if (this.downloadMediaResponse.downloadBoxList[i].media[j].media.id === this.deleteModel[c].id) {
              const index = this.downloadMediaResponse.downloadBoxList[i].media
                .indexOf(this.downloadMediaResponse.downloadBoxList[i].media[j]);

              mediaDeleteList.push(this.downloadMediaResponse.downloadBoxList[i].media[j]);
              if (this.downloadMediaResponse.downloadBoxList[i].media[j].media.isPublished) {
                this.cartItems.publishedMediaIds.splice(index, 1);
              } else {
                this.cartItems.storyMediaIds.splice(index, 1);
              }

              this.myFilesService.setMyFiles(this.cartItems);

            }
          }
        }

        for (let x = 0; x < mediaDeleteList.length; x++) {
          this.downloadMediaResponse.downloadBoxList[i].media
            .splice(this.downloadMediaResponse.downloadBoxList[i].media.indexOf(mediaDeleteList[x]), 1);
        }
      }


      component.disabledButton = true;
      component.downloadActive = true;
      component.cancelDownloads = false;

      urls.forEach(url => {
        const filename = url.split('/')[8];

        if (component.cancelDownloads) {
          return;
        }

        // loading a file and add it in a zip file
        JSZipUtils.getBinaryContent(url, function (err, data) {

          if (component.cancelDownloads) {
            return;
          }

          zip.file(filename, data, { binary: true });
          count++;

          if (count === urls.length) {
            zip.generateAsync({ type: 'blob' }).then(function (content) {

              saveAs(content, zipFilename);

              component.resetDownloadArrays(component);

              component.disabledButton = false;
              component.downloadActive = false;

              if (component.user.cmsUser == null) {
                component.addDownload();
              }

            });
          }
        });
      });
    } else {
      for (const x of this.forDownload) {

        component.disabledButton = true;
        component.downloadActive = true;

        for (let i = 0; i < this.downloadMediaResponse.downloadBoxList.length; i++) {

          const mediaDeleteList = [];

          for (let j = 0; j < this.downloadMediaResponse.downloadBoxList[i].media.length; j++) {
            for (let c = 0; c < this.deleteModel.length; c++) {
              if (this.downloadMediaResponse.downloadBoxList[i].media[j].media.id === this.deleteModel[c].id) {
                const index = this.downloadMediaResponse.downloadBoxList[i].media
                  .indexOf(this.downloadMediaResponse.downloadBoxList[i].media[j]);

                mediaDeleteList.push(this.downloadMediaResponse.downloadBoxList[i].media[j]);

                if (this.downloadMediaResponse.downloadBoxList[i].media[j].media.isPublished) {
                  this.cartItems.publishedMediaIds.splice(index, 1);
                } else {
                  this.cartItems.storyMediaIds.splice(index, 1);
                }
                this.myFilesService.setMyFiles(this.cartItems);
              }
            }
          }

          for (let item = 0; x < mediaDeleteList.length; item++) {
            this.downloadMediaResponse.downloadBoxList[i].media
              .splice(this.downloadMediaResponse.downloadBoxList[i].media.indexOf(mediaDeleteList[item]), 1);
          }

        }

        this.downloadSubscription = this.http.get(x, { responseType: 'blob' })
          .subscribe((blob: Blob) => {

            const filename = x.split('/')[8];

            saveAs(blob, filename);

            component.resetDownloadArrays(component);

            component.disabledButton = false;
            component.downloadActive = false;

            if (component.user.cmsUser == null) {
              component.addDownload();
            }
          },
            (error) => {
              component.disabledButton = false;
              component.downloadActive = false;
            });
      }
    }
  }


  resetDownloadArrays(component) {
    component.forDownload = [];
    component.deleteModel = [];
    component.initVideoFormats(component);
  }

  initVideoFormats(component) {
    component.videoFormats = [];

    for (const download of component.downloadMediaResponse.downloadBoxList) {
      for (const media of download.media) {
        for (const link of media.downloadLinks) {
          if (media.media.mediaType === 'Video' && !component.videoFormats.find(x => x.label === link.label)) {
            component.videoFormats.push({ label: link.label });
          }
        }
      }
    }
  }

  shareCart() {
    this.shareCartUrl = `${UrlHelper.getCurrentUrlHostOnly()}/download/share/${localStorage.getItem('cart')}`;
  }

  closeLoader() {
    this.loader = false;
  }

}
