<div class="news-box" [ngClass]="additionalClasses" [ngClass]="customClasses + (template === 4 ? ' homepage-4' : '')">
  <div class="featured-image {{ story.category.length == 0  ? 'featured-image-none' : '' }}">
    <span class="image-container">
      <picture *ngIf="!story.embeddedCode">
        <source srcset="{{ webPHelper.changeFileToWebP(getSource(story)) }}" type="image/webp" />
        <source srcset="{{ getSource(story) }}" type="image/jpeg" />
        <img (error)="webPHelper.removeWebP($event)" src="{{ getSource(story) }}" alt="Story featured image" />
      </picture>
      <div *ngIf="story.embeddedCode" [innerHTML]="story.embeddedCode" class="results-story-iframe"></div>
      <div class="news-overlay" *ngIf="!story.embeddedCode"></div>

      <div *ngIf="showCategory" class="category-wrapper {{ story.category.length == 0  ? 'no-category' : '' }}">
        <div class="category">
          <div class="text">
            {{ story.category[0] }}
          </div>
        </div>
      </div>

      <div *ngIf="showCounters" class="action-icons flex-center">
        <div *ngIf="!config.hideCounters">
          {{ story.views }}
        </div>
        <div *ngIf="!config.hideCounters" class="view-icon">
          <i class="fal fa-eye"></i>
        </div>
      </div>
    </span>
    <div class="button-hover-overlay" (click)="readMore()">
      <div class="button-overlay button-container">
        <div class="add-to-wishlist" *ngIf="user || user.id != 0" (click)="toggleSavedStory(story.storyId, $event)">

          <div class="iconContainer">
            <img class="icon" *ngIf="!inSavedStories(story.storyId)" src="/assets/images/dna/heart.svg">
            <img class="icon filled" *ngIf="inSavedStories(story.storyId)" src="/assets/images/dna/heartFilled.svg">
          </div>
          <div *ngIf="!inSavedStories(story.storyId)">{{ language.addStoryToFav }} </div>
          <div  *ngIf="inSavedStories(story.storyId)">{{ language.addedToMyFiles }} </div>
          
        </div>


        <!-- <ng-container *ngIf="imageMedia.length > 0 && mediaTabOpened">
          <div class="media-group-header">
            <div class="media-group-title">
              {{ language.images }} ({{ imageMedia.length }})
            </div>
            <div class="add-to-cart" (click)="downloadAll(imageMedia, 'image')">
              {{
                downloadingImage
                  ? language.downloading
                  : language.downloadAllImages
              }}
              <span class="fal fa-download"></span>
            </div>
          </div>
          <div class="story-highlight-media-wrapper">
            <ng-container *ngFor="let media of imageMedia">
              <app-media-file
                class="media-file"
                [newsDetail]="true"
                [customClasses]="'news-detail-media-file'"
                [media]="media"
                [config]="webNewsDetailsResponse"
              ></app-media-file>
            </ng-container>
          </div>
        </ng-container> -->

        <ng-container *ngIf="imageMedia.length > 0">
          <div class="button-overlay download-all-files" (click)="downloadAll(imageMedia, 'image')">
            <div class="iconContainer">
              <img class="icon" src="/assets/images/dna/download.svg">
            </div>
            {{ downloadingAll ? language.downloading : "Download all files" }}
          </div>
        </ng-container>

        <app-share-5 *ngIf="config.socialSharing?.length > 0" [image]="story.fullHDUrl ? story.fullHDUrl : story.thumbUrl" [title]="story.storyTitle"
          [description]="story.shortDescription" [big]="false" [horizontal]="true" [shared]="story.shared"
          [sbUrl]="getStoryUrl()" [staggingStoryId]="story.staggingStoryId" [mediaId]="story.mediaId"
          [storyId]="story.storyId" [socials]="config.socialSharing">
        </app-share-5>

      </div>
    </div>
  </div>

  <div class="info">
    <div class="datum" *ngIf="!story.hideStoryDate && template === 4" (click)="readMore()">
      {{ story.publishedDate | date : "dd MMMM yyyy" }}
    </div>
    <div class="story-title" (click)="readMore()">
      {{ shortenTitle(story.storyTitle) }}
    </div>
    <div class="datum" *ngIf="!story.hideStoryDate && template !== 4" (click)="readMore()">
      {{ story.publishedDate | date : "dd MMMM yyyy" }}
    </div>
    <hr />

    <div class="action">
      <div class="wrapper">
        <a (click)="readMore()" class="read-more">
          {{ language.readMore }}
        </a>

        <span>
          <!-- <div class="add-to-wishlist" (click)="toggleSavedStory(story.storyId)">
            <i *ngIf="!inSavedStories(story.storyId)" class="far fa-heart"></i>
            <i *ngIf="inSavedStories(story.storyId)" class="fas fa-heart"></i>
          </div> -->

          <app-share-5 *ngIf="config.socialSharing.length > 0" [image]="story.thumbUrl" [title]="story.storyTitle"
            [description]="story.shortDescription" [big]="false" [vertical]="true" [shared]="story.shared"
            [sbUrl]="getStoryUrl()" [staggingStoryId]="story.staggingStoryId" [mediaId]="story.mediaId"
            [storyId]="story.storyId" [socials]="config.socialSharing">
          </app-share-5>
        </span>
      </div>
    </div>
  </div>
</div>