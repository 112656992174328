import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LanguageService } from "../../services/language.service";
import { Language } from "../../../models/language/language.model";
import { SavedStoryService } from "../../services/saved-story.service";
import { CommunicationMediaCartService } from "../../services/communication-media-cart.service";
import { McService } from "../../services/mc-service";
import { MyFilesService } from "../../services/my-files.service";
import { TokenManagerService } from "../../services/token-manager.service";
import { Router, ActivatedRoute } from "@angular/router";
import { GoogleAnalyticsEventsService } from "../../services/google-analytics-events.service";
import * as JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";
import { DownloadAddToModel } from "../../../routes/media/media-model";
import { HttpClient } from "@angular/common/http";


@Component({
  selector: "app-media-cart",
  templateUrl: "./media-cart.component.html",
  styleUrls: ["./media-cart.component.less"],
})

export class MediaCartComponent implements OnInit {
  @Input() isMediaCartOpen: boolean; // Initial open state from parent component
  @Output() openChange = new EventEmitter<boolean>(); // Emit change in open state

  filters: any;
  language: Language;
  fileType = "all";
  products = [];
  guest: boolean = true;
  response: any;

  constructor(
    private languageService: LanguageService,
    private savedStoryService: SavedStoryService,
    private communicationMediaCartService: CommunicationMediaCartService,
    private mcService: McService,
    private myFileService: MyFilesService,
    private router: Router,
    private googleAnalytics: GoogleAnalyticsEventsService,
    private http: HttpClient,

  ) {
    this.language = languageService.getLanguage();

  }

  ngOnInit() {
    this.filters = [
      {
        type: "all",
        text: this.language.allFiles,
        faClass: "fal fa-archive",
      },
      {
        type: "image",
        text: this.language.imageFiles,
        faClass: "fal fa-camera-retro",
      },
      {
        type: "video",
        text: this.language.videoFiles,
        faClass: "fal fa-video",
      },
      {
        type: "document",
        text: this.language.documents,
        faClass: "fal fa-file",
      },
      {
        type: "audio",
        text: this.language.audioFiles,
        faClass: "fal fa-volume-up",
      },
    ];

    this.loadProducts();

    this.communicationMediaCartService.headerFunctionCalled.subscribe(() => {
      this.loadProducts();
    });

    if (TokenManagerService.getUser() && TokenManagerService.getUser().id == 0) {
      this.guest = true;
    } else {
      this.guest = false;
    }
  }

  loadProducts() {
    try {
      this.mcService.downloadBox(this.myFileService.getFiles()).subscribe(
        (response: any) => {
          this.products = response.downloadBoxList;
        },
        (error2) => {
          console.error(error2)
        })

    } catch (e) {
      console.error(e)
    }
  }


  filterType(type) {
    this.fileType = type;
  }

  showFile(media) {
    if (
      this.fileType != "all" &&
      media.mediaType.toLowerCase() != this.fileType
    )
      return false;
    return true;
  }

  closeMediaCart() {
    this.isMediaCartOpen = false; // Set overall open state to false (if triggered from outside)
    this.openChange.emit(this.isMediaCartOpen); // Emit the change in overall open state
    document.body.classList.remove("noScroll");
  }

  downloadAllFiles() {
    this.loadProducts();
    if (TokenManagerService.getUser() && TokenManagerService.getUser().id == 0) {
      this.closeMediaCart()
      this.router.navigateByUrl("/sign-in");
    } else{
      this.downloadAll();
    }
  }

  sendCart() {
    console.log('SEND CART');
  }

  emptyCart() {
    this.myFileService.clearFiles();
  }


  downloadAll() {
    const component = this;
    let urls = this.products.map(box => box.media.map((media) => media.downloadLinks[0].downloadUrl))
    urls=[].concat(...urls)
    // ? box.map((media) => media.downloadLink)
    // : box.storyMedias.map((media) => media.downloadLink);
    const publishedMediaIds = this.products.map(box => box.media.filter((media) => media.media.isPublished).map((media) => media.media.id))
    // isCart
    // ? box.filter((media) => media.isPublished).map((media) => media.id)
    // : box.storyMedias.filter((media) => media.isPublished).map((media) => media.id);
    const storyMediaIds = this.products.map(box => box.media.filter((media) => !media.media.isPublished).map((media) => media.media.id))
    // isCart
    //   ? box.filter((media) => !media.isPublished).map((media) => media.id)
    //   : box.storyMedias.filter((media) => !media.isPublished).map((media) => media.id);
    const zip = new JSZip();
    let count = 0;
    const zipFilename = "download.zip"
    // if (urls.length > 1) {

      urls.forEach(url => {
            const filename = url.split("/")[8];

            // loading a file and add it in a zip file
            JSZipUtils.getBinaryContent(url, function (err, data) {
              zip.file(filename, data, { binary: true });
              count++;

              if (count === urls.length) {
                zip.generateAsync({ type: "blob" }).then(function (content) {
                  saveAs(content, zipFilename);

                  const user = TokenManagerService.getUser();
                  if (!user.cmsUser) {
                    component.addDownload(storyMediaIds, publishedMediaIds)
                  }
                });
              }
            });
      });
    // } else {
    //   const x=urls[0]
    //     this.http
    //       .get(x, { responseType: "blob" })
    //       .subscribe(
    //         (blob: Blob) => {
    //           const filename = x.split("/")[8];

    //           saveAs(blob, filename);
    //           const user = TokenManagerService.getUser();
    //           if (!user.cmsUser) {
    //             component.addDownload(storyMediaIds, publishedMediaIds);
    //           }
    //         },
    //         (error) => console.error(error)
    //       );
    // }
  }

  addDownload(storyMediaIds, publishedMediaIds) {
    const user = TokenManagerService.getUser();
    if (localStorage.getItem("Token") === null) {
      user.id = null;
    }
    const niz = new DownloadAddToModel();
    niz.publishedMediaIds = publishedMediaIds;
    niz.storyMediaIds = storyMediaIds;
    niz.userId = user.id;
    niz.publishedMediaIds.forEach((mediaId) => {
      this.googleAnalytics.emitEvent(
        "media/" + mediaId,
        "download-pm",
        niz.userId.toString()
      );
    });
    niz.storyMediaIds.forEach((mediaId) => {
      this.googleAnalytics.emitEvent(
        "media/" + mediaId,
        "download",
        niz.userId.toString()
      );
    });
    this.mcService.addDownloadToMedia(niz).subscribe(
      (response) => { },
      (error) => { }
    );
  }

}
