import { WebManageMediaModel } from '../../download/download-model';

export class MediaModel {
  public mediaPageGridType: string;
  public maxMediaPerPage: number;
  public images: number;
  public mustRegisterToDownload: boolean;
  public socialSharing: string[];
  public mustRegisterToAccess: boolean;
  public anonymousShare: boolean;
  public video: number;
  public document: number;
  public audio: number;
  public media: WebManageMediaModel[];
  public totalCount: number;
  public hideCounters: boolean;
  public publishedDate: string;
}

export class DownloadAddToModel {
  public storyMediaIds: number[];
  public publishedMediaIds: number[];
  public userId: number;
}
