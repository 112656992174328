import { Injectable } from "@angular/core";
import { file } from "jszip";
import { Subject } from "rxjs";
import { MyFilesModel } from "./my-files-model";
import { CommunicationMediaCartService } from "./communication-media-cart.service";
// import { DownloadBoxService } from "./download-box.service";

@Injectable()
export class MyFilesService {
  public files: Subject<any> = new Subject();

  myFiles: MyFilesModel;
  public filesChanged: Subject<void> = new Subject<void>();

  constructor(
    private communicationMediaCartService: CommunicationMediaCartService,
    // private downloadBoxService : DownloadBoxService
  ) { }

  getFiles(): MyFilesModel {
    let storyMediaIds;
    let publishedMediaIds;
    if (localStorage.getItem("Token") === null) {
      storyMediaIds = JSON.parse(localStorage.getItem("anonCart"));
      publishedMediaIds = JSON.parse(localStorage.getItem("anonPublished"));
    } else {
      storyMediaIds = JSON.parse(localStorage.getItem("cart"));
      publishedMediaIds = JSON.parse(localStorage.getItem("published"));
    }
    if (storyMediaIds === null) storyMediaIds = [];
    if (publishedMediaIds === null) publishedMediaIds = [];
    return new MyFilesModel(storyMediaIds, publishedMediaIds);
  }

  getMyFiles(): MyFilesModel {
    if (!this.myFiles || this.myFiles.length == 0) {
      this.myFiles = this.getFiles();
    }
    if (!this.myFiles)
      this.myFiles = new MyFilesModel([], []);
    return this.myFiles;
  }

  setMyFiles(myFiles: MyFilesModel) {
    this.myFiles = myFiles;
    if (localStorage.getItem("Token") === null) {
      if (myFiles.storyMediaIds.length > 0) localStorage.setItem("anonCart", JSON.stringify(myFiles.storyMediaIds));
      if (myFiles.publishedMediaIds.length > 0) localStorage.setItem("anonPublished", JSON.stringify(myFiles.publishedMediaIds));
    } else {
      if (myFiles.storyMediaIds.length > 0) localStorage.setItem("cart", JSON.stringify(myFiles.storyMediaIds));
      if (myFiles.publishedMediaIds.length > 0) localStorage.setItem("published", JSON.stringify(myFiles.publishedMediaIds));
    }
    this.files.next(myFiles);
  }

  pushMyFiles(file: number, isPublished: boolean = false) {
    if (!this.myFiles) this.myFiles = this.getMyFiles();
    if (isPublished) {
      if (this.myFiles.publishedMediaIds.indexOf(file) >= 0) return;
      this.myFiles.publishedMediaIds.push(file);
    }
    else {
      if (this.myFiles.storyMediaIds.indexOf(file) >= 0) return;
      this.myFiles.storyMediaIds.push(file);
    }

    if (localStorage.getItem("Token") === null) {
      if (isPublished) localStorage.setItem("anonPublished", JSON.stringify(this.myFiles.publishedMediaIds));
      else localStorage.setItem("anonCart", JSON.stringify(this.myFiles.storyMediaIds));
    } else {
      if (isPublished) localStorage.setItem("published", JSON.stringify(this.myFiles.publishedMediaIds));
      else localStorage.setItem("cart", JSON.stringify(this.myFiles.storyMediaIds));

      //update files in db
      // this.downloadBoxService.createDownloadBox().subscribe(
      //   (response: any) => {
      //     console.log("resp", response)
      //   },
      //   (error) => console.error(error)

      // )
    }

    this.communicationMediaCartService.callFunctionInH();
    this.communicationMediaCartService.callFunctionInMc()
    this.files.next(this.myFiles);
  }

  clearFiles() {
    this.myFiles = new MyFilesModel([], []);
    if (localStorage.getItem("Token") === null) {
      localStorage.setItem("anonCart", JSON.stringify(this.myFiles.storyMediaIds));
      localStorage.setItem("anonPublished", JSON.stringify(this.myFiles.publishedMediaIds));
    } else {
      localStorage.setItem("cart", JSON.stringify(this.myFiles.storyMediaIds));
      localStorage.setItem("published", JSON.stringify(this.myFiles.publishedMediaIds));
    }
    this.communicationMediaCartService.callFunctionInMc()
    this.communicationMediaCartService.callFunctionInH();
    this.files.next(this.myFiles);
  }

  deleteFile(id: number, isPublished: boolean = false) {
    if (!this.myFiles) this.myFiles = this.getMyFiles();
    if (isPublished) {
      let index = this.myFiles.publishedMediaIds.indexOf(id);
      if (index >= 0) {
        this.myFiles.publishedMediaIds.splice(index, 1);
        if (localStorage.getItem("Token") === null) localStorage.setItem("anonPublished", JSON.stringify(this.myFiles.publishedMediaIds));
        else localStorage.setItem("published", JSON.stringify(this.myFiles.publishedMediaIds));
        this.communicationMediaCartService.callFunctionInH();
        this.communicationMediaCartService.callFunctionInMc()
        this.files.next(this.myFiles);
      }
    } else {
      let index = this.myFiles.storyMediaIds.indexOf(id);
      if (index >= 0) {
        this.myFiles.storyMediaIds.splice(index, 1);
        if (localStorage.getItem("Token") === null) localStorage.setItem("anonCart", JSON.stringify(this.myFiles.storyMediaIds));
        else{
          localStorage.setItem("cart", JSON.stringify(this.myFiles.storyMediaIds));
          //update files in db
          // this.downloadBoxService.createDownloadBox().subscribe(
          //   (response: any) => {
          //     console.log("resp", response)
          //   },
          //   (error) => console.error(error)

          // )
        } 
        this.communicationMediaCartService.callFunctionInH();
        this.communicationMediaCartService.callFunctionInMc()
        this.files.next(this.myFiles);
      }
    }
  }
}
