<div class="popup-background">
  <div class="popup-overlay"></div>
  <div class="popup">
    <!--<div class="close-icon close-popup-icon" *ngIf="token || !popupModelResponse?.mustRegisterToAccess" (click)="closePopup()"></div>-->
    <div class="close-icon close-popup-icon" (click)="closePopup()"></div>
    <div>
      <img class="featured-image-popup" src="{{ popupModelResponse?.media?.thumbUrl }}"
        *ngIf="popupModelResponse?.media?.mediaType != 'Video' && popupModelResponse.media.extension == 'pdf'" alt="{{ popupModelResponse?.media?.name }}" />

        <img class="featured-image-popup" src="{{ popupModelResponse?.media?.thumbUrl }}"
        *ngIf="popupModelResponse?.media?.mediaType != 'Video' && popupModelResponse.media.extension != 'pdf'" alt="{{ popupModelResponse?.media?.name }}" style="background-color: grey; height: 400px;"/>


      <video class="featured-image-popup" *ngIf="popupModelResponse?.media?.mediaType === 'Video' && !autoplay" controls
        controlsList="nodownload" oncontextmenu="return false;">
        <source src="{{ playerSource }}" type="video/mp4" />
        {{ language.unsupportedVideoBrowser }}
      </video>

      <video class="featured-image-popup" *ngIf="popupModelResponse?.media?.mediaType === 'Video' && autoplay" controls
        autoplay controlsList="nodownload" oncontextmenu="return false;">
        <source src="{{ playerSource }}" type="video/mp4" />
        {{ language.unsupportedVideoBrowser }}
      </video>

      <audio class="featured-image-popup" *ngIf="popupModelResponse?.media?.mediaType === 'Audio'" controls
        controlsList="nodownload" oncontextmenu="return false;">
        <source src="{{ playerSource }}" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
    <div class="popup-content-wrapper">
      <div class="popup-content">
        <div class="popup-headline">
          {{ popupModelResponse?.media?.name | replace : "_" : " " }}
        </div>
        <div class="action-buttons flex">
          <div *ngIf="!hideCounters" class="action-button flex-center">
            <i class="fal fa-eye" [ngStyle]="{
                color: styles.style ? styles.style.mainIdentifyingColor : ''
              }"></i>
            {{ popupModelResponse?.media?.views }}
          </div>
          <div *ngIf="!hideCounters" class="action-button flex-center" appClickOutside
            (clickOutside)="onClickOutside($event)" (click)="dropdownRecent = true">
            <i class="fal fa-arrow-to-bottom" [ngStyle]="{
                color: styles.style ? styles.style.mainIdentifyingColor : ''
              }"></i>
            {{ popupModelResponse?.media?.downloads }}
            <div class="dropdown popup-dropdown flex" *ngIf="dropdownRecent">
              <div>{{ language.latestDownloads }}</div>
              <div class="normal-font" *ngFor="
                  let lastDownload of popupModelResponse?.media.lastDownloads
                ">
                {{ lastDownload }}
              </div>
              <div class="normal-font" *ngIf="popupModelResponse?.media.lastDownloads.length == 0">
                {{ language.noDownloads }}
              </div>
            </div>
          </div>
          <app-share *ngIf="urlForShare" [image]="popupModelResponse?.media?.thumbUrl" [big]="true" [vertical]="false" [isPublished]="isPublished"
            [open]="true" [noTitle]="true" [shared]="popupModelResponse?.media?.shares"
            (emitShared)="updateSharedNumber($event)" [sbUrl]="urlForShare"
            [title]="popupModelResponse?.media?.shareTitle"
            [description]="popupModelResponse?.media?.shareShortDescription" [mediaId]="mediaId"
            [webNewsDetailsResponse]="webNewsDetailsResponse" [isMediaShare]="true" [isMediaShareBig]="true"
            (embeddOpen)="openEmbeddedPopup()" [socials]="popupModelResponse?.socialSharing"></app-share>
        </div>
        <div>{{ language.availableFormats }}:</div>
        <ul class="download-format flex-center">
          <div *ngFor="let popup of popupModelResponse?.downloadLinks">
            <li class="file flex-center" *ngIf="
                popup.label.includes('ORIGINAL') ||
                popup.label.includes('ORGINAL') ||
                popup.label.includes('XLSX') ||
                popup.label.includes('TXT') ||
                popup.label.includes('PPTX') ||
                popup.label.includes('DOCX') ||
                popup.label.includes('PDF')
              ">
              <div class="file-type flex">
                <i class="fal fa-video"></i>
                <span>{{ popup.label }}</span>
              </div>
              <div class="download-information flex-end">
                <button *ngIf="!popup.downloadActive" [ngClass]="[disabledButton ? 'disabledButton' : '']"
                  class="generic-button flex-center" (click)="download(popup)">
                  {{ language.download }}
                </button>
                <div class="flex" *ngIf="popup.downloadActive">
                  <div class="cancel-download-loader-wrap">
                    <app-loader></app-loader>
                  </div>
                  <button class="download-button flex-center" (click)="cancelDownload(popup)">
                    {{ language.cancel }}
                  </button>
                </div>
              </div>
            </li>
          </div>
        </ul>
        <div class="availability" *ngIf="popupModelResponse.alsoIn">
          <div>{{ language.alsoAvailableIn }}:</div>
          <div *ngIf="popupModelResponse?.alsoInStoryLink" class="headline">
            <a class="unchangedLink" (click)="goToStory()">
              {{ popupModelResponse?.alsoInTitle }}</a>
          </div>
          <hr />
          <div>{{ popupModelResponse?.alsoInShortDescription }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loader *ngIf="loader"></app-loader>
<app-loginpopup *ngIf="loginPopup" [(loginPopup)]="loginPopup" (loginSuccessfulChange)="refreshMediaLinks()">
</app-loginpopup>