import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { environment } from '../../../environments/environment';
import { HttpRequestService } from "./http-request.service";
import { MyFilesService } from "./my-files.service";

@Injectable()
export class DownloadBoxService {
  private createBoxUrl = environment.apiUrl + '/DownloadBox/Create';
  private getAllUrl = environment.apiUrl + '/DownloadBox/GetAll';
  private createFromGuestUrl = environment.apiUrl + '/DownloadBox/CreateGuest';

  constructor(private http: HttpRequestService, private myFiles: MyFilesService) { 

    this.myFiles.filesChanged.subscribe(() => {
      console.log("chang",myFiles.getMyFiles())
    });

  }


  createDownloadBox() {
    const box = this.myFiles.getMyFiles();
    return this.http.post(this.createBoxUrl, box);
  }

  createFromGuest(){
    const box = this.myFiles.getMyFiles();
    return this.http.post(this.createFromGuestUrl, box);
  }

  getAll(){
    return this.http.get(this.getAllUrl);
  }
}
