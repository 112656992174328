// communication-media-cart.service.ts
import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommunicationMediaCartService {

  public headerFunctionCalled = new EventEmitter<void>();
  public mediaCartFunctionCalled = new EventEmitter<void>();

  constructor() { }

  callFunctionInH() {
    this.headerFunctionCalled.emit();
  }

  callFunctionInMc(){
    this.mediaCartFunctionCalled.emit()
  }
}
