import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { environment } from '../../../environments/environment';
import { ToastrConfig } from "../helpers/toastr-config";
import { HttpRequestService } from "./http-request.service";
import { TokenManagerService } from "./token-manager.service";
import { Observable, of } from 'rxjs';
import { map, tap } from "rxjs/operators";
import { Router } from "@angular/router";

import { CommunicationMediaCartService } from "./communication-media-cart.service";

@Injectable()
export class SavedStoryService {

  private addUrl = environment.apiUrl + '/SavedStory/Add';
  private addGuest = environment.apiUrl + '/SavedStory/AddGuest';
  private getAllUrl = environment.apiUrl + '/SavedStory/GetAll';
  private getAllGuestUrl = environment.apiUrl + '/SavedStory/GetAllGuest';
  private removeUrl = environment.apiUrl + '/SavedStory/Remove';
  private removeGuestUrl = environment.apiUrl + '/SavedStory/RemoveGuest';
  private getAllIdsUrl = environment.apiUrl + '/SavedStory/GetAllIds';
  private getAllIdGuestsUrl = environment.apiUrl + '/SavedStory/GetAllIdsGuest';
  savedStoryIds: any[] = [];

  constructor(
    private http: HttpRequestService,
     private toastr: ToastrService, 
     private communicationMediaCartService: CommunicationMediaCartService,
     private router: Router,
    ) { }

  add(storyId) {
    //No user logged in
    if (TokenManagerService.getUser() && TokenManagerService.getUser().id == 0) {
      // //first check if theres guest token
      // let userToken: string = localStorage.getItem('GuestToken') != null ? localStorage.getItem('GuestToken') : null
      // let ans = this.http.post(this.addGuest, { storyId: storyId, userToken: userToken }).toPromise().then((response) => {
      //   if (response && userToken == null){
      //     //@ts-ignore
      //     userToken = response;
      //     localStorage.setItem('GuestToken', userToken);
      //   }
      //   if (!this.savedStoryIds) this.savedStoryIds = [];
      //   this.savedStoryIds.push(storyId);
      //   localStorage.setItem("savedStories", JSON.stringify(this.savedStoryIds));
      //   this.toastr.success('Story Saved.', 'Well Done!', ToastrConfig.success);
      // });
      // this.communicationMediaCartService.callFunctionInH();

      // return ans
      this.router.navigateByUrl('/sign-in');
      // window.location.href = '/sign-in';
    }
    let ans = this.http.post(this.addUrl, storyId).toPromise().then((response) => {
      if (!this.savedStoryIds) this.savedStoryIds = [];
      this.savedStoryIds.push(storyId);
      localStorage.setItem("savedStories", JSON.stringify(this.savedStoryIds));
      this.toastr.success('Story Saved.', 'Well Done!', ToastrConfig.success);
    });
    return ans
  }

  remove(storyId) {
    if (TokenManagerService.getUser() && TokenManagerService.getUser().id == 0) {
      let userToken: string = localStorage.getItem('GuestToken');
      if(typeof userToken == 'undefined'){
        throw new Error("No guest found")
      }
      return this.http.post(this.removeGuestUrl, { storyId: storyId, userToken: userToken }).toPromise().then((response) => {
        if (!this.savedStoryIds) this.savedStoryIds = [];
        this.savedStoryIds = this.savedStoryIds.filter(sId => sId != storyId);
        localStorage.setItem("savedStories", JSON.stringify(this.savedStoryIds));
        this.toastr.success('Story Removed.', 'Well Done!', ToastrConfig.success);
      });
    }
    return this.http.post(this.removeUrl, storyId).toPromise().then((response) => {
      if (!this.savedStoryIds) this.savedStoryIds = [];
      this.savedStoryIds = this.savedStoryIds.filter(sId => sId != storyId);
      localStorage.setItem("savedStories", JSON.stringify(this.savedStoryIds));
      this.toastr.success('Story Removed.', 'Well Done!', ToastrConfig.success);
    });
  }

  getAll(languageId = 1){
    if (TokenManagerService.getUser() && TokenManagerService.getUser().id == 0) {
      if(localStorage.getItem('GuestToken') == null) return;
      let userToken: string = localStorage.getItem('GuestToken');
      return this.http.get(this.getAllGuestUrl + '?userToken=' + userToken+ '&languageId=' + languageId);
    }
    return this.http.get(this.getAllUrl + '?languageId=' + languageId);
  }

  getIds() {
    if (TokenManagerService.getUser() && TokenManagerService.getUser().id == 0) {
      if (localStorage.getItem('GuestToken') == null) return;
      let userToken: string = localStorage.getItem('GuestToken');
      return this.http.get(this.getAllIdGuestsUrl+"?userToken="+userToken).subscribe(
        (response: any) => {
          this.savedStoryIds = response;
          localStorage.setItem("savedStories", JSON.stringify(this.savedStoryIds));
        },
        (error) => console.error(error));
    }
    return this.http.get(this.getAllIdsUrl).subscribe(
      (response: any) => {
        this.savedStoryIds = response;
        localStorage.setItem("savedStories", JSON.stringify(this.savedStoryIds));
      },
      (error) => console.error(error));
  }

  getCartAmount(): Observable<number> {
    if (TokenManagerService.getUser() && TokenManagerService.getUser().id == 0) {
      if (localStorage.getItem('GuestToken') === null) {
        return of(0); // Return an observable with 0 if GuestToken is undefined
      }
      let userToken: string = localStorage.getItem('GuestToken');
      return this.http.get(this.getAllIdGuestsUrl + "?userToken=" + userToken).pipe(
        tap((response: any) => { // Log the response
        }),
        map((response: any) => response.length) // Return the length of the array
      );
    }
    return this.http.get(this.getAllIdsUrl).pipe(
      tap((response: any) => {
      }),
      map((response: any) => response.length) // Return the length of the array
    );
  }

  inSavedStories(storyId) {
    if (!this.savedStoryIds) this.savedStoryIds = [];
    return this.savedStoryIds.includes(storyId);
  }

}
