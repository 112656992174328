<section class="story-detail flex-center">
  <div class="header-data desktop-only">
    <div class="story-information-item" *ngIf="publisher">
      <div class="title">{{ language.storyPublisher }}</div>
      <div class="info">
        {{ webNewsDetailsResponse?.publishedDate | date : " dd MMMM yyyy" }}
      </div>
    </div>
    <!-- <div class="story-save" (click)="toggleSavedStory(webNewsDetailsResponse.storyId)">
      <div class="add-to-wishlist"> {{ language.addStoryToFav }} </div>
    </div> -->
    <div class="add-to-cart" (click)="downloadAll(null, 'all')">
      <img src="/assets/images/dna/download.svg" alt="" />
      {{ downloadingAll ? language.downloading : "Download All files" }}
    </div>
  </div>
  <div class="story-body story-body-top">
    <div class="header-image flex-center" [ngClass]="[
        !webNewsDetailsResponse?.smallHeroImage ? ' story-detail-v2' : ''
      ]">
      <div class="header-wrapper flex-center" [ngClass]="[
          !webNewsDetailsResponse?.smallHeroImage ? ' story-detail-v2' : '',
          webNewsDetailsResponse?.isMobileCrop ? 'mobile-crop' : ''
        ]">
        <div class="slider-overlay" *ngIf="
            webNewsDetailsResponse?.smallHeroImage == false &&
            !webNewsDetailsResponse?.embeddedCode
          ">
          <img src="{{ webNewsDetailsResponse.thumbUrl }}" alt="" />
        </div>
        <div class="story-featured-image" [ngClass]="[
            !webNewsDetailsResponse?.smallHeroImage ? ' story-detail-v2' : '',
            webNewsDetailsResponse?.embeddedCode ? 'story-with-video' : ''
          ]">
          <div class="white-triangle" [ngClass]="[
              !webNewsDetailsResponse?.smallHeroImage ? ' story-detail-v2' : ''
            ]"></div>

          <picture class="featured-image" *ngIf="
              webNewsDetailsResponse?.featuredMediaType != 'Video' &&
              !webNewsDetailsResponse?.embeddedCode &&
              webNewsDetailsResponse != undefined
            ">
            <source class="featured-image"
              srcset="{{ webPHelperService.changeFileToWebP(webNewsDetailsResponse.thumbUrl) }}" type="image/webp" />
            <source class="featured-image" srcset="{{ webNewsDetailsResponse.thumbUrl }}" type="image/jpeg" />
            <img (error)="webPHelperService.removeWebP($event)" class="featured-image"
              src="{{ webNewsDetailsResponse.thumbUrl }}" alt="Story featured image" />
          </picture>

          <video class="featured-image" *ngIf="webNewsDetailsResponse?.featuredMediaType === 'Video'" loop
            [muted]="true" oncontextmenu="return false;">
            <source src="{{ webNewsDetailsResponse?.thumbUrl }}" type="video/mp4" />
            {{ language.unsupportedVideoBrowser }}
          </video>
          <div *ngIf="webNewsDetailsResponse?.embeddedCode" class="story-details-iframe"
            [innerHTML]="webNewsDetailsResponse?.embeddedCode"></div>
        </div>
      </div>
      <div class="header-title">
        <div *ngIf="showCategory" class="category-wrapper" (click)="showSubCategory(webNewsDetailsResponse)">
          <div class="category" [ngClass]="
              webNewsDetailsResponse?.category.length > 1 ? 'has-child' : ''
            ">
            <div>
              {{ webNewsDetailsResponse?.category[0].name }}
            </div>
          </div>
          <div class="category-dropdown" *ngIf="
              webNewsDetailsResponse?.dropdown &&
              webNewsDetailsResponse?.category.length > 1
            ">
            <div *ngFor="
                let category of webNewsDetailsResponse?.category
                  | slice : 1 : webNewsDetailsResponse?.category.length
              ">
              {{ category }}
            </div>
          </div>
        </div>
        <h1 class="story-headline">
          {{ webNewsDetailsResponse?.storyTitle }}
        </h1>

        <p class="story-description">
          {{webNewsDetailsResponse?.shortDescription}}
        </p>
      </div>
    </div>
  </div>

  <div class="story-body story-body-bottom">
    <div class="story-information">
      <div class="story-share" *ngIf="webNewsDetailsResponse">
        <div class="share-buttons">
          <ng-container *ngFor="let social of socials">
            <app-share-button-6 class="share-button" [image]="webNewsDetailsResponse?.thumbUrl" [sbUrl]="currentUrl"
              [title]="webNewsDetailsResponse?.storyTitle" [description]="webNewsDetailsResponse?.shortDescription"
              [storyId]="webNewsDetailsResponse.storyId" [mediaId]="webNewsDetailsResponse.mediaId"
              [staggingStoryId]="webNewsDetailsResponse.staggingStoryId" [social]="social" [open]="share"
              [shared]="webNewsDetailsResponse?.shared"></app-share-button-6>
          </ng-container>
        </div>
      </div>
      <div class="story-text">
        <div class="story-information-item" *ngIf="!webNewsDetailsResponse?.hideStoryDate">
          <div class="title">{{ language.storyDate }}</div>
          <div class="info">
            {{ webNewsDetailsResponse?.publishedDate | date : " dd MMMM yyyy" }}
          </div>
        </div>
        <h3 class="story-headline">
          {{ webNewsDetailsResponse?.storyTitle }}
        </h3>
        <div class="bodytext" [innerHTML]="webNewsDetailsResponse?.text"></div>
      </div>
    </div>
    <div class="story-highlight-media" *ngIf="webNewsDetailsResponse?.media.length > 0">
      <h3 class="section-title" (click)="mediaTabOpened = !mediaTabOpened">
        {{ language.media }}
        <div class="minimizeSection" [ngClass]="{ opened: mediaTabOpened }">

          <img *ngIf="mediaTabOpened" src="../../assets/images/dna/minus.svg">
          <img *ngIf="!mediaTabOpened" src="../../assets/images/dna/plus.svg">
        </div>
      </h3>
      <ng-container *ngIf="imageMedia.length > 0 && mediaTabOpened">
        <div class="media-group-header">
          <div class="media-group-title">
            {{ language.images }} ({{ imageMedia.length }})
          </div>
          <div class="add-to-cart" (click)="downloadAll(imageMedia, 'image')">
            {{
            downloadingImage
            ? language.downloading
            : language.downloadAllImages
            }}
            <span> <img src="/assets/images/dna/download.svg"></span>
          </div>
        </div>
        <div class="story-highlight-media-wrapper">
          <ng-container *ngFor="let media of imageMedia">
            <app-media-file-6 class="media-file" [newsDetail]="true" [customClasses]="'news-detail-media-file'"
              [media]="media" [config]="webNewsDetailsResponse"></app-media-file-6>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="videoMedia.length > 0 && mediaTabOpened">
        <div class="media-group-header">
          <div class="media-group-title">
            {{ language.videos }} ({{ videoMedia.length }})
          </div>
          <div class="add-to-cart" (click)="downloadAll(videoMedia, 'video')">
            {{
            downloadingVideo
            ? language.downloading
            : language.downloadAllVideos
            }}
            <span> <img src="/assets/images/dna/download.svg"></span>
          </div>
        </div>
        <div class="story-highlight-media-wrapper">
          <ng-container *ngFor="let media of videoMedia">
            <app-media-file-6 class="media-file" [newsDetail]="true" [customClasses]="'news-detail-media-file'"
              [media]="media" [config]="webNewsDetailsResponse"></app-media-file-6>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="documentMedia.length > 0 && mediaTabOpened">
        <div class="media-group-header">
          <div class="media-group-title">
            {{ language.documents }} ({{ documentMedia.length }})
          </div>
          <div class="add-to-cart" (click)="downloadAll(documentMedia, 'document')">
            {{
            downloadingDocument
            ? language.downloading
            : language.downloadAllDocuments
            }}
            <span>
              <img src="/assets/images/dna/download.svg"></span>
          </div>
        </div>
        <div class="story-highlight-media-wrapper">
          <ng-container *ngFor="let media of documentMedia">
            <app-media-file-6 class="media-file" [newsDetail]="true" [customClasses]="'news-detail-media-file'"
              [media]="media" [config]="webNewsDetailsResponse"></app-media-file-6>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="audioMedia.length > 0 && mediaTabOpened">
        <div class="media-group-header">
          <div class="media-group-title">
            {{ language.audios }} ({{ audioMedia.length }})
          </div>
          <div class="add-to-cart" (click)="downloadAll(audioMedia, 'audio')">
            {{
            downloadingAudio
            ? language.downloading
            : language.downloadAllAudios
            }}
            <span>
              <img src="/assets/images/dna/download.svg"></span>
          </div>
        </div>
        <div class="story-highlight-media-wrapper">
          <ng-container *ngFor="let media of audioMedia">
            <app-media-file-6 class="media-file" [newsDetail]="true" [customClasses]="'news-detail-media-file'"
              [media]="media" [config]="webNewsDetailsResponse"></app-media-file-6>
          </ng-container>
        </div>
      </ng-container>
      <!-- <div class="download-all-container">
      <div class="generic-button" (click)="downloadAll()">
        <span class="fal fa-download"></span>
        Download All Media
      </div>
    </div> -->
    </div>
  </div>

  <div id="latest-news" class="latest-news">
    <div class="story-feed" *ngIf="
        webNewsDetailsResponse?.youMayAlsoLikeSection &&
        (token || !webNewsDetailsResponse.mustRegisterToAccess)
      ">
      <h2 class="section-title">
        {{ language.youMayAlsoLike }}
      </h2>
      <div class="story-wrapper" [ngClass]="{ opened: relatedStoriesTabOpened }">
        <ng-container *ngFor="let relatedStory of webNewsDetailsResponse.alsoMayLike">
          <app-story-preview-6 class="related-stories-container" [story]="relatedStory"
            [config]="webNewsDetailsResponse" [customClasses]="'related-stories'"></app-story-preview-6>
        </ng-container>
      </div>
    </div>
  </div>
</section>

<app-popup *ngIf="popup" [(popup)]="popup" [(hideCounters)]="webNewsDetailsResponse.hideCounters" [mediaId]="mediaId"
  [autoplay]="autoplay"></app-popup>
<app-loader *ngIf="loader"></app-loader>
<app-loginpopup *ngIf="loginPopup" [(loginPopup)]="loginPopup"
  (loginSuccessfulChange)="onLogin($event)"></app-loginpopup>

<div id="pruebaDownload" *ngIf="downloadProgress"> Downloading Media </div>