<div class="popup-background">
  <div class="popup-overlay"></div>
  <div class="popup">
    <div class="close-icon" (click)="close()"></div>
    <section class="login flex">
      <div class="gray-background-login"></div>
      <div class="register-wrapper">
        <div class="headline" [ngStyle]="{
            'background-color': styles.style ? styles.style.textH1Color : ''
          }">
          {{ language.registrationSuccess }}
        </div>

        <div class="text-box" [ngStyle]="{
            color: styles.style ? styles.style.textMainColor : ''
          }">
          Hi, <br /><br />
          Thank you for completing the sign-up process.
          <br /><br />
          {{ language.activationEmailSent }}
          <br /><br />
          {{ language.activationEmailInstructions }}
          <br /><br />
          {{ language.lookForwardMessage }}
          <br /><br />
          {{ language.thankYou }},<br />
          DNA Team
        </div>
      </div>
    </section>
  </div>
</div>