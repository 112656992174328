import { throwError as observableThrowError, Observable } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { FacebookService, LoginResponse, LoginOptions, UIResponse, UIParams, FBVideoComponent } from 'ngx-facebook';
import { LoginModel, fbModel } from './login-model';
import { McService } from '../shared/services/mc-service';
import { TokenManagerService } from '../shared/services/token-manager.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { StorageService } from '../shared/services/storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { ToastrService } from 'ngx-toastr';
import { PageStyle } from '../models/style/style.model';
import { FontsAndColorsService } from '../shared/services/fonts-and-colors.service';
import { environment } from "../../environments/environment";
import { LayoutModel } from '../layout/layout-model';
import { LanguageService } from "../shared/services/language.service";
import { Language } from "../models/language/language.model";
import { ToastrConfig } from '../shared/helpers/toastr-config';
import { SavedStoryService } from '../shared/services/saved-story.service';
import { MyFilesService } from '../shared/services/my-files.service';
import { DownloadBoxService } from '../shared/services/download-box.service';

@Component({
  selector: 'app-login-6',
  templateUrl: './login-6.component.html',
  styleUrls: ['./login-6.component.less']
})
export class LoginComponent6 implements OnInit {
  loggedUserName: string;
  isPassowordSet: boolean;
  loginModel = new LoginModel();
  response: any;
  loader = false;
  emailPopup: boolean;
  valForm: FormGroup;
  fbData = new fbModel();
  token: string;
  styles = new PageStyle();
  error: string;
  layoutResponseModel: LayoutModel;
  language: Language;
  returnUrl = '/';
  showPassword: boolean;
  type: string;
  @Input() isDna: boolean;
  magicLink: boolean = true;
  showSentPopup: boolean = false;


  constructor(private mcService: McService,
    private toastr: ToastrService,
    private languageService: LanguageService,
    private fb: FacebookService,
    private form: FormBuilder,
    private authLoginService: TokenManagerService,
    private router: Router,
    private sService: StorageService,
    public fontsAndColorsService: FontsAndColorsService,
    private activatedRoute: ActivatedRoute,
    private savedStoryService: SavedStoryService,
    private myFilesService: MyFilesService,
    private downloadBoxService: DownloadBoxService
  ) {

    //

    this.language = languageService.getLanguage();

    this.languageService.language.subscribe(
      (language) => {
        this.language = language;
      }
    );

    this.valForm = this.form.group({
      'password': [null, Validators.required],
      'username': [null, Validators.compose([Validators.required, CustomValidators.email])],
    });

    const headerFooter = JSON.parse(localStorage.getItem('headerfooter'));

    // fb.init({
    //   appId: headerFooter.facebookAppId,
    //   version: 'v6.0'
    // });
    this.styles = fontsAndColorsService.getPageStyleInfo();
    const token = JSON.parse(localStorage.getItem('Token'));
    this.token = token && token.token;

    this.activatedRoute.params.subscribe((params: Params) => {
      this.token = params['token'];
    });

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.error = params['error'];
    });

    if (sessionStorage.getItem('redirectUrl'))
      this.returnUrl = sessionStorage.getItem('redirectUrl');

  }

  ngOnInit() {
    if (this.error) {
      this.toastr.error(this.error, 'Error', ToastrConfig.error);
    } else if (this.token) {

      this.authLoginService.loginWithMagicLink(this.token)
        .subscribe((response: any) => {

          this.response = response;
          this.loader = false;
          this.loggedUserName = TokenManagerService.getUser().fullName;
          // this.isPassowordSet = TokenManagerService.getUser().isPasswordSet;
          this.sService.setName(this.loggedUserName);

          if (localStorage.getItem("anonCart") != null && localStorage.getItem("anonCart")?.length > 0) {
            localStorage.setItem("cart", localStorage.getItem("anonCart"));
            localStorage.setItem("anonCart", '');
            this.downloadBoxService.createFromGuest().subscribe(
              (response) => {
                console.log("resp", response)
              },
              (error) => {
                console.error(error);
              }
            )
          }
          if (localStorage.getItem("anonPublished") != null && localStorage.getItem("anonPublished")?.length > 0) {
            localStorage.setItem("published", localStorage.getItem("anonPublished"));
          }

          const files = this.myFilesService.getFiles();
          this.myFilesService.setMyFiles(files);

          // this.sService.setPw(this.isPassowordSet);
          this.router.navigateByUrl(this.returnUrl);
          this.savedStoryService.getIds();
        },
          (error) => {
            localStorage.removeItem('Token')
            //
          });

    } else if (localStorage.getItem('Token') !== null) {
      this.router.navigateByUrl(this.returnUrl, { replaceUrl: true });
    }

    this.sService.getLogin().subscribe(term => {
      this.layoutResponseModel = term;
    });

    this.showPassword = false;
    this.type = 'password';
    //
  }

  visiblePassword(): void {
    this.showPassword = !this.showPassword;
    this.type = this.showPassword ? 'text' : 'password';
  }

  loginTwitter() {
    window.location.href = environment.apiUrl + '/MediaCenterUser/LoginWithTwitter';
  }

  login() {
    this.fb.login()
      .then((res: LoginResponse) => {
        this.mcService.logInFb(res.authResponse.accessToken)
          .subscribe((response: any) => {
            //
            const token = response.token;
            if (token) {
              this.token = token;

              if (response.user.role === 3 || response.user.role === 4 || response.user.role === 5 || response.user.role === 6) {
                return observableThrowError('Wrong email or password!');
              }
              localStorage.setItem('Token', JSON.stringify({ token: token }));
              localStorage.setItem('User', JSON.stringify({ user: response.user }));
              const files = this.myFilesService.getFiles();
              this.myFilesService.setMyFiles(files);
              this.savedStoryService.getIds();
              this.sService.setName(response.user.fullName);
              this.router.navigateByUrl(this.returnUrl, { replaceUrl: true });
            } else {
              return false;
            }
          },
            (error) => {

              this.toastr.error(error.message, 'Error', ToastrConfig.error);
            });
      })
      .catch(this.handleError);
  }

  st() {
    this.fb.api('/me?fields=email,first_name,last_name').then(
      (response: any) => {
        //
        this.fbData.email = response.email;
        this.fbData.facebookId = response.id;
        this.fbData.firstName = response.first_name;
        this.fbData.lastName = response.last_name;
        // this.loginModel.email=response.email;
        // this.loginModel.password='';
        //
        this.mcService.logInFb(this.fbData)
          .subscribe((response: any) => {

            const token = response.token;
            if (token) {
              this.processLogin(token, response);
              const files = this.myFilesService.getFiles();
              this.myFilesService.setMyFiles(files);
            } else {
              return false;
            }

          },
            (error) => {

              this.toastr.error(error.message, 'Error', ToastrConfig.error);
            });
      }
    )
      .catch(this.handleError);
  }

  registerPage() {
    this.router.navigateByUrl('/register');
  }


  private handleError(error) {
    console.error('Error processing action', error);
    this.toastr.error(error.message, 'Error', ToastrConfig.error);
  }

  submitLoginForm(e) {

    if (!this.magicLink) {
      e.stopPropagation();
      for (const c in this.valForm.controls) {
        this.valForm.controls[c].markAsTouched();
      }
      if (this.valForm.valid) {
        this.loader = true;
        this.authLoginService.login(this.loginModel)
          .subscribe((response: any) => {
            this.response = response;
            this.loader = false;
            this.loggedUserName = TokenManagerService.getUser().fullName;
            // this.isPassowordSet = TokenManagerService.getUser().isPasswordSet;
            this.sService.setName(this.loggedUserName);

            if (localStorage.getItem("anonCart") != null && localStorage.getItem("anonCart")?.length > 0) {
              localStorage.setItem("cart", localStorage.getItem("anonCart"));
              localStorage.setItem("anonCart", '');
              this.downloadBoxService.createFromGuest().subscribe(
                (response) => {
                  console.log("resp", response)
                },
                (error) => {
                  console.error(error);
                }
              )
            }
            if (localStorage.getItem("anonPublished") != null && localStorage.getItem("anonPublished")?.length > 0) {
              localStorage.setItem("published", localStorage.getItem("anonPublished"));
            }

            const files = this.myFilesService.getFiles();
            this.myFilesService.setMyFiles(files);

            // this.sService.setPw(this.isPassowordSet);
            this.router.navigateByUrl(this.returnUrl);
            this.savedStoryService.getIds();

          },
            (error) => {
              this.toastr.error(error.message, 'Error', ToastrConfig.error);

              this.loader = false;
            });
      }

    } else {
      if(this.loginModel.email !== null && this.loginModel.email !== '' && this.loginModel.email !== undefined){
        this.loader = true;
        this.authLoginService.loginMagicLink(this.loginModel)
        .subscribe((response: any) => {
          // this.response = response;
          this.loader = false;
          this.showSentPopup = true;
        },
          (error) => {
            this.toastr.error(error.error.message, 'Error', ToastrConfig.error);
            this.loader = false;
          });  
      } else {
        this.toastr.error("Please enter an email", 'Error', ToastrConfig.error);
      }

    }

  }

  closeSentPopup(){
    this.showSentPopup = false;
  }

  forgotPassword() {
    this.router.navigateByUrl('/forgotpassword');
  }

  processLogin(token, response) {
    this.token = token;

    if (response.user.role === 3 || response.user.role === 4 || response.user.role === 5 || response.user.role === 6) {
      return observableThrowError('Wrong email or password!');
    }
    localStorage.setItem('Token', JSON.stringify({ token: token }));
    localStorage.setItem('User', JSON.stringify({ user: response.user }));

    this.sService.setName(response.user.fullName);
    this.router.navigateByUrl(this.returnUrl);
  }

  changeToLogin() {
    this.magicLink = false;
  }
  changeToMagicLink() {
    this.magicLink = true;
  }

}
