import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from "rxjs";

@Injectable()
export class CommunicationService {

  public fromBrands: BehaviorSubject<any> = new BehaviorSubject(false);
  public brandsRedirect: BehaviorSubject<any> = new BehaviorSubject(false);

  private _data;
  set data(value) {
    this._data = value;
  }
  get data() {
    return this._data;
  }

  setFromBrands(url: string) {
    this.fromBrands.next(url);
  }
  public fixedWrapper: BehaviorSubject<any> = new BehaviorSubject(false);

}




