<section class="register flex-center" ngClass="{register-dna : isDna}">
  <div class="register-wrapper">
    <div class="selector-container">
      <h1 class="selector-item selector-item-active">
        {{ language.register }}
      </h1>
    </div>
    <div class="register-section flex">
      <form action="" class="register-form flex" [formGroup]="valForm">
        <div class="form-elements">
          <div class="form-element form-element-width-50 flex">
            <input type="text" id="name" placeholder="Enter your first name"
              [(ngModel)]="registerResponseModel?.mediaUserModel.firstName" formControlName="firstname" />
            <span class="text-danger" *ngIf="
                valForm.controls['firstname'].hasError('required') &&
                (valForm.controls['firstname'].dirty ||
                  valForm.controls['firstname'].touched)
              ">
              {{ language.firstNameRequired }}</span>
          </div>
          <div class="form-element form-element-width-50 flex">
            <input type="text" id="lastname" placeholder="Enter your last name"
              [(ngModel)]="registerResponseModel?.mediaUserModel.lastName" formControlName="lastname" />
            <span class="text-danger" *ngIf="
                valForm.controls['lastname'].hasError('required') &&
                (valForm.controls['lastname'].dirty ||
                  valForm.controls['lastname'].touched)
              ">
              {{ language.lastNameRequired }}</span>
          </div>
        </div>
        <div class="form-element flex">
          <input type="text" id="email" placeholder="{{language.enterEmail}}"
            [(ngModel)]="registerResponseModel?.mediaUserModel.email" (keyup)="checkIfEmpty()" [formControl]="email" />
          <span class="text-danger" *ngIf="email.hasError('required') && (email.dirty || email.touched)">
            {{ language.emailNameRequired }}</span>
          <span class="text-danger" *ngIf="email.hasError('email') && (email.dirty || email.touched)">Must be a valid
            email address</span>
        </div>
        <div class="form-element flex">
          <input type="text" id="phone" placeholder="Enter your phone number"
            [(ngModel)]="registerResponseModel?.mediaUserModel.telephoneNumber" formControlName="telephone" />
        </div>
        <div class="form-element flex password" *ngIf="!isFb && !twitterRegister">
          <div class="eye-icon" (click)="visiblePassword()">
            <img *ngIf="showPassword" src="../../assets/images/eye-black.svg" alt="Show password" />
            <img *ngIf="!showPassword" src="../../assets/images/eye-black-hide.svg" alt="Hide password" />
          </div>
          <input type="password" id="password" (keyup)="setConfirmError()" placeholder="{{language.enterPassword}}"
            [type]="type" [(ngModel)]="registerResponseModel?.mediaUserModel.password" [formControl]="password" />
          <span class="text-danger" *ngIf="
              password.hasError('required') &&
              (password.dirty || password.touched)
            ">
            Password is required</span>
          <span class="text-danger" *ngIf="
              password.hasError('maxlength') &&
              (password.dirty || password.touched)
            ">
            {{ language.max50Characters }}</span>
          <span class="text-danger" *ngIf="
              password.hasError('minlength') &&
              (password.dirty || password.touched)
            ">
            {{ language.min4Characters }}</span>
          <span class="text-danger second" *ngIf="
              password.hasError('pattern') &&
              (password.dirty || password.touched)
            ">
            {{ language.passwordNoSpaces }}</span>
        </div>
        <div class="form-element flex password" *ngIf="!isFb && !twitterRegister">
          <div class="eye-icon" (click)="visiblePasswordConfirm()">
            <img *ngIf="showPasswordConfirm" src="../../assets/images/eye-black.svg" alt="Show password" />
            <img *ngIf="!showPasswordConfirm" src="../../assets/images/eye-black-hide.svg" alt="Hide password" />
          </div>
          <input [(ngModel)]="registerResponseModel?.mediaUserModel.confirmPassword" [type]="typeConfirm"
            [ngClass]="[confirmError ? 'ng-invalid ng-touched' : '']" type="password" id="repeat-password"
            placeholder="Repeat your password" [formControl]="confirmPassword" (keyup)="checkPassword()" />
          <span class="text-danger" *ngIf="
              confirmPassword.hasError('required') &&
              (confirmPassword.dirty || confirmPassword.touched)
            ">
            {{ language.confirmPasswordRequired }}</span>
          <span class="text-danger" *ngIf="
              confirmPassword.value != password.value &&
              !confirmPassword.hasError('required') &&
              password.value != ''
            ">{{ language.passwordsNoMatch }}</span>
        </div>
        <div class="form-element flex">
          <label>I am a...</label>
          <div class="select bigger register-dropdown flex-center" (click)="mediaUser = !mediaUser" appClickOutside
            (clickOutside)="onClickOutsideUser($event)">
            <span *ngIf="usersToShow.length === 0">Users</span>
            <div class="flex select-list">
              <ng-container *ngFor="let user of usersToShow; let isLast = last">
                <div>{{ user }}{{ isLast ? "" : ", " }}</div>
              </ng-container>
            </div>
            <div class="language-dropdown flex flex-column" *ngIf="mediaUser">
              <div class="choice flex" *ngFor="let user of registerResponseModel.userGroups"
                (click)="addUser(user, $event)">
                <input type="checkbox" [checked]="user.checked" [ngStyle]="{
                    background: user.checked
                      ? styles.style?.mainIdentifyingColor
                      : ''
                  }" />
                <div>{{ user.name }}</div>
              </div>
            </div>
          </div>
          <div class="text-danger" *ngIf="userFilled == false">
            {{ language.mediaUserTypeRequired }}
          </div>
        </div>
        <div *ngIf="isUserTypeOtherSelected()" class="form-element flex">
          <label for="name">Tell us what you do</label>
          <input type="text" id="name" placeholder="Other" [(ngModel)]="
              registerResponseModel?.mediaUserModel.mediaUserTypeOther
            " formControlName="mediaUserTypeOther" />
        </div>

        <div *ngIf="showCategories && false" class="form-element flex">
          <label>{{ language.categoriesOfInterest }}</label>
          <div class="select bigger register-dropdown flex-center" (click)="categoryDropdown = !categoryDropdown"
            appClickOutside (clickOutside)="onClickOutsideCategory($event)">
            <span *ngIf="
                registerResponseModel.mediaUserModel.customCategoryIds
                  .length === 0
              ">{{ language.categories }}</span>
            <div class="flex select-list">
              <ng-container *ngFor="let cat of categories; let isLast = last">
                <div *ngIf="
                    registerResponseModel.mediaUserModel.customCategoryIds.indexOf(
                      cat.id
                    ) !== -1
                  ">
                  {{ cat.label }}{{ isLast ? "" : ", " }}
                </div>
              </ng-container>
            </div>
            <div class="language-dropdown flex flex-column" *ngIf="categoryDropdown">
              <div class="choice flex">
                <input type="checkbox" [checked]="markedAll" (click)="$event.stopPropagation(); selectAll($event)"
                  [ngStyle]="{
                    background: markedAll
                      ? styles.style?.mainIdentifyingColor
                      : ''
                  }" />
                <div (click)="$event.stopPropagation(); selectAll()">All</div>
              </div>
              <div class="choice flex" *ngFor="let category of registerResponseModel.customCatogories"
                (click)="$event.stopPropagation(); addCategory(category)">
                <input type="checkbox" [checked]="category.marked" (click)="
                    $event.stopPropagation(); addCategory(category, $event)
                  " [ngStyle]="{
                    background: category.marked
                      ? styles.style?.mainIdentifyingColor
                      : ''
                  }" />
                <div (click)="$event.stopPropagation(); addCategory(category)">
                  {{ category.label }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="false" class="form-element flex">
          <label>{{ language.languages }}</label>
          <div class="select bigger register-dropdown flex-center" (click)="languageDropDown = !languageDropDown"
            appClickOutside (clickOutside)="onClickOutside($event)">
            <span *ngIf="languages.length === 0">{{ language.languages }}</span>
            <div class="flex select-list">
              <ng-container *ngFor="let lang of languages; let isLast = last">
                <div>{{ lang }}{{ isLast ? "" : ", " }}</div>
              </ng-container>
            </div>
            <div class="language-dropdown flex flex-column" *ngIf="languageDropDown">
              <div class="choice flex" *ngFor="let lang of registerResponseModel.languages"
                (click)="$event.stopPropagation(); addLanguage(lang, $event)">
                <input type="checkbox" [checked]="lang.checked" [ngStyle]="{
                    background: lang.checked
                      ? styles.style?.mainIdentifyingColor
                      : ''
                  }" />
                <div (click)="$event.stopPropagation(); addLanguage(lang)">
                  {{ lang.label }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="checkbox-wrapper">
          <div class="flex newsletter">
            <span [innerHTML]="
                registerResponseModel?.registrationInfoModel
                  ?.registerNotificationText
              "></span>
          </div>

          <div class="flex terms-checkbox">
            <input type="checkbox" (click)="termsCheck($event); $event.stopPropagation()" [checked]="termsAndConditions"
              formControlName="terms" />
            <span class="terms-link" (click)="termsPopup = true">
              <u>{{
                registerResponseModel?.registrationInfoModel
                ?.registerTermsQuestion
                }}</u>
            </span>
            <br />
          </div>
          <span class="text-danger" *ngIf="
              (valForm.controls['terms'].dirty ||
                valForm.controls['terms'].touched) &&
              !termsAndConditions
            ">
            You must read the Terms and Conditions first
          </span>

          <div class="flex newsletter">
            <input type="checkbox" (click)="newsletterCheck($event); $event.stopPropagation()" #firstCheckBox
              [checked]="registerResponseModel.mediaUserModel.receiveNewsletter" formControlName="newsletter" />
            <!-- <span [innerHTML]="
                registerResponseModel?.registrationInfoModel
                  ?.registerNotificationQuestion
              "></span> -->
              <span>Yes, please send me an email alert when news stories and content relevant to me is published on DNA.</span>
          </div>
        </div>
        <div class="register-buttons flex">
          <button (click)="onSubmit($event)" type="submit" class="generic-button submit flex-center"
            *ngIf="!isFb && !twitterRegister">
            {{ language.submit }}
          </button>
        </div>
      </form>
    </div>
  </div>

  <div class='banner-img desktop-only'>
    <img src="../../assets/images/Dna_login.png" alt="">
  </div>
</section>
<app-terms-popup *ngIf="termsPopup" [(termsPopup)]="termsPopup"></app-terms-popup>
<app-register-popup *ngIf="registerSuccessPopup" [(registerPopup)]="registerSuccessPopup"></app-register-popup>
<app-loader *ngIf="loader"></app-loader>