// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // cmsUrl: 'http://localhost:55260/redirected?token=',
  // apiUrl: 'http://localhost:55260/api',
  // apiUrlRoot: 'http://localhost:55260',
  /*
  cmsUrl: 'http://dna-web-develop.azurewebsites.net/redirected?token=',
  apiUrl: 'https://dna-api-development.azurewebsites.net/api',
  apiUrlRoot: 'https://dna-api-development.azurewebsites.net',
  mediaUrl: 'https://dnamediastorage.blob.core.windows.net',
  facebookAppId: '137527330414505',
  facebookAppSecret: '9142cc75dfbbe38e5a8104c057fc7bea'
  */

  // cmsUrl: "http://dna-user-front-sandbox.azurewebsites.net/redirected?token=",
  // apiUrl: "https://dna-api-sandbox.azurewebsites.net/api",
  // apiUrlRoot: "https://dna-api-sandbox.azurewebsites.net",
  // mediaUrl: "https://dnasandboxmediastorage.blob.core.windows.net/",

  cmsUrl: 'https://dna-web-dev.azurewebsites.net/redirected?token=',
  apiUrlRoot: "https://dna-api-master-dev.azurewebsites.net",
  apiUrl: "https://dna-api-master-dev.azurewebsites.net/api",
  mediaUrl: "https://dnamediastoragedev.blob.core.windows.net/",

  facebookAppId: "137527330414505",
  facebookAppSecret: "9142cc75dfbbe38e5a8104c057fc7bea",
  algoliaApplicationId: "0KMDPEZ84J",
  algoliaApiKey: "e44d0540379aa8b931318b3275dc2b7e",
  algoliaStoriesIndex: 'dev_stories',
  algoliaMediaIndex: 'dev_media',
  algoliaStoriesAsc: 'dev_stories_storyTimestamp_asc',
  algoliaStoriesTitleAsc : 'dev_stories_title_asc',
  algoliaStoriesTitleDesc : 'dev_stories_title_desc',
};
