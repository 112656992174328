<div class="header-dummy"></div>
<header *ngIf="styles.style" class="flex-center" [ngClass]="{ 'invert-colors': invertColors && false }">
  <div class="header-wrapper flex">
    <div class="left-header-container">
      <div class="logo flex" [ngClass]="[biggerLogo ? 'logo-big' : '']" routerLink="/" routerLinkActive="active"
        (click)="activeLink()">
        <img [src]="layoutResponseModel?.clientLogo" alt="Client Logo" #logoFlag (load)="onLoad()" />
      </div>
    </div>
    <nav>
      <div class="bottom-bar flex-center">
        <div class="underline-links-container" *ngIf="!mustRegister || loggedUserName">
          <a *ngFor="let link of layoutResponseModel?.headerLinks" style="cursor: pointer" (click)="reroute(link)"
            routerLink="/{{ link.url }}" routerLinkActive="active">
            <div routerLink="/{{ link.url }}" routerLinkActive="active">
              {{ link.link }}
            </div>
          </a>
          <a routerLink="/brands" routerLinkActive="active" style="cursor: pointer"
            *ngIf="layoutResponseModel?.hasBrands" (click)="rerouteToBrands()">
            <div routerLink="/brands" routerLinkActive="active">
              {{ language.brands }}
            </div>
          </a>
        </div>
        <div id="account-options" class="account-options top-bar flex-end" [ngClass]="[!mustRegister || loggedUserName ? '' : 'mb-54']">
          <div *ngIf="!mustRegister || loggedUserName" class="search flex-center" (click)="showSearch()">
            <div class="search-icon">
              <svg>
                <image xlink:href="../../assets/icons/searchIconBlack.svg"
                  src="../../assets/icons/searchIconBlack.svg" />
              </svg>
            </div>
          </div>
          <a *ngIf="fromBrandsUrl" href="{{ fromBrandsUrl + brandsRedirect }}">
            <div class="flex return-to-brands">
              <div class="arrow-down"></div>
              Return to Brands
            </div>
          </a>
          <div class="header-separator"></div>
          <a *ngIf="loggedUserName === '' || loggedUserName == undefined" routerLink="/sign-in"
            routerLinkActive="active" (click)="rerouteToLogin(language.signIn)" class="generic-button sign-in"
            [ngClass]="{ empty: invertColors && false }">
            <div class="lowercase">{{ language.signIn }}</div>
          </a>
          <div class="user-dropdown-holder" *ngIf="loggedUserName != '' && loggedUserName != undefined">
            <div (click)="showProfile()" appClickOutside (clickOutside)="onClickOutside($event)">
              <div class="profile-name flex">
                <span>{{ loggedUserName }}</span>
              </div>
              <div class="user-dropdown" *ngIf="showDropdown">
                <div (click)="editProfile()">{{ language.myAccount }}</div>
                <div (click)="redirectToCms()" *ngIf="!!user.cmsUser">
                  {{ language.redirectToCms }}
                </div>
                <div (click)="logout()">{{ language.logOut }}</div>
              </div>
            </div>
          </div>
          <div class="user-dropdown-holder">
            <div (click)="showMarkets()" appClickOutside (clickOutside)="onClickOutsideMarkets($event)"
              *ngIf="markets?.length > 0">
              <div class="flex-center">
                {{ currentMarket == null ? 'Markets' : currentMarket.marketName }}
                <div class="arrow-down" [ngClass]="showDropdownMarkets ? 'active' : ''"></div>
              </div>
              <div class="user-dropdown" *ngIf="showDropdownMarkets">
                <div *ngFor="let market of markets" (click)="goToMarket(market)">
                  {{ market.marketName }}
                </div>
              </div>
            </div>
          </div>
          <div class="user-dropdown-holder">
            <div (click)="showLanguage()" appClickOutside (clickOutside)="onClickOutsidee($event)"
              *ngIf="layoutResponseModel?.languages.length > 1">
              <div class="flex-center">
                {{ activeLng.substr(0, 2) }}
                <div class="arrow-down" [ngClass]="showDropdownLng ? 'active' : ''"></div>
              </div>
              <div class="user-dropdown" *ngIf="showDropdownLng">
                <div *ngFor="let lng of layoutResponseModel?.languages" (click)="translate(lng.id, lng.label)">
                  {{ lng.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="hamburger" (click)="openMobileNav()" *ngIf="!search">
      <i class="fas fa-bars"></i>
    </div>
    <div class="mobile-nav flex" *ngIf="mobileNav">
      <div class="close-nav flex-center" (click)="closeNav()">
        <i class="far fa-times"></i>
      </div>
      <div class="search flex-center" (click)="showSearch(); closeNav()">
        SEARCH
      </div>
      <div class="login-nav-wrapper">
        <a *ngIf="fromBrandsUrl" href="{{ fromBrandsUrl + brandsRedirect }}">
          <div>Return to Brands</div>
        </a>
        <a *ngIf="loggedUserName === '' || loggedUserName == undefined" routerLink="/sign-in" routerLinkActive="active"
          (click)="closeNav()">
          <div>Sign In</div>
        </a>
        <div class="user-dropdown-holder" *ngIf="loggedUserName != '' && loggedUserName != undefined">
          <a (click)="showMobileProfile()" appClickOutside (clickOutside)="onClickOutside($event)">
            <div class="profile-name flex">
              <span>{{ loggedUserName }}</span>
              <div class="arrow-down" [ngClass]="showDropdown ? 'active' : ''"></div>
            </div>
            <div class="user-dropdown" *ngIf="showMobDropdown">
              <div (click)="editProfile()">User Profile</div>
              <div (click)="redirectToCms()" *ngIf="!!user.cmsUser">
                {{ language.redirectToCms }}
              </div>
              <div (click)="logout()">{{ language.logOut }}</div>
            </div>
          </a>
        </div>
        <a *ngIf="loggedUserName === '' || loggedUserName == undefined" routerLink="/register" routerLinkActive="active"
          (click)="closeNav()">
          <div>Register</div>
        </a>
      </div>
      <div class="flex flex-column">
        <ng-container *ngIf="!mustRegister || loggedUserName">
          <a *ngFor="let link of layoutResponseModel?.headerLinks" style="cursor: pointer"
            (click)="reroute(link); closeNav()">
            <div>
              {{ link.link }}
            </div>
          </a>
          <a routerLink="/brands" style="cursor: pointer" *ngIf="layoutResponseModel?.hasBrands"
            (click)="rerouteToBrands()">
            <div (click)="closeNav()">
              {{ language.brands }}
            </div>
          </a>
        </ng-container>
        <a style="cursor: pointer">
          <div (click)="openMyFiles()" id="myFilesNav">
            {{ language.myFiles || language.myFiles }} ({{
            cart ? cart.length || 0 : 0
            }})
          </div>
        </a>
        <div class="user-dropdown-holder language-dropdown">
          <div (click)="showMarkets()" appClickOutside (clickOutside)="onClickOutsideMarkets($event)"
            *ngIf="markets?.length > 0">
            <div class="language flex">
              {{ currentMarket == null ? 'Markets' : currentMarket.marketName }}
              <div class="arrow-down" [ngClass]="showDropdownMarkets ? 'active' : ''"></div>
            </div>
            <div class="user-dropdown language-dropdown-list" *ngIf="showDropdownMarkets">
              <div *ngFor="let market of markets" (click)="goToMarket(market)">
                {{ market.marketName }}
              </div>
            </div>
          </div>
        </div>
        <div class="user-dropdown-holder language-dropdown">
          <div *ngIf="layoutResponseModel?.languages.length > 1">
            <div class="language flex">
              {{ activeLng }}
              <div class="arrow-down" [ngClass]="mobileLanguageDropdown ? 'active' : ''"></div>
            </div>
            <div class="user-dropdown language-dropdown-list" *ngIf="mobileLanguageDropdown">
              <div *ngFor="let lng of layoutResponseModel?.languages">
                <span>{{ lng.label }}</span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="layoutResponseModel?.showHeaderSocialLinks" class="social-media-links-container">
          <span *ngFor="let object of socialLinkObjects">
            <a *ngIf="object.checked" target="_blank" [href]="object.link">
              <i *ngIf="object.name === 'facebook'" class="fab fa-facebook-square"></i>
              <i *ngIf="object.name === 'twitter'" class="fab fa-twitter-square"></i>
              <i *ngIf="object.name === 'linkedin'" class="fab fa-linkedin"></i>
              <i *ngIf="object.name === 'youtube'" class="fab fa-youtube-square"></i>
              <i *ngIf="object.name === 'instagram'" class="fab fa-instagram"></i>
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</header>

<app-my-files *ngIf="showMyFiles" (clickedOutsideEvent)="closeMyFiles($event)"></app-my-files>

<app-search [(search)]="search" #appSearch [layoutResponseModel]="layoutResponseModel"></app-search>
<app-loader *ngIf="loader"></app-loader>