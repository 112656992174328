<div class="bkg-filter" [ngClass]="{ active: bkgFilters }"></div>
<section class="open-filters-container" [ngClass]="{ open: this.filters }">
  <div class="filterHeader">
      <div class="filterTitle">Filter by</div>
      <div id="closeFilter" (click)="closeFilters()">
          X
      </div>
  </div>

  <div id="filters">
    <div class="filterDropdown">
        <div class="filterNameDropdown">
            <div class="filterNameText">All Topics</div>
            <div class="openCloseFilter">+</div>
        </div>
        <div class="selectedFiltersBox"></div>
        <div class="filtersContainer">

          <div id="all-topics-filter" class="categoryDiv">
              <a class="subfilterNameDropdown categoryNameDiv">Topic1</a>
              <a class="subfilterNameDropdown categoryNameDiv">Topic2</a>
              <a class="subfilterNameDropdown categoryNameDiv">Topic3</a>
          </div>
        </div>
    </div>
    <div class="filterDropdown">
      <div class="filterNameDropdown">
        <div class="filterNameText">Market</div>
        <div class="openCloseFilter">+</div>
      </div>
      <div class="selectedFiltersBox"></div>
      <div class="filtersContainer">
        <div id="all-markets-filter" class="categoryDiv">
            <a class="subfilterNameDropdown categoryNameDiv" >Market1</a>
            <a class="subfilterNameDropdown categoryNameDiv" >Market2</a>
            <a class="subfilterNameDropdown categoryNameDiv" >Market3</a>
        </div>
      </div>
    </div>
  </div>
  <div id="cleanFiltersContainer">
      <div class="tr-3" id="cleanFilters">Delete filters</div>
      <div class="tr-3" id="applyFilters">Apply filters</div>
  </div>
</section>