import {
  ChangeDetectorRef,
  Component,
  OnInit,
  HostListener,
} from "@angular/core";
import { McService } from "../../../shared/services/mc-service";
import { WebNewsDetailsMediaModel, WebNewsDetailsModel } from "../news-model";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { saveAs } from "file-saver";
import * as JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";
import { StorageService } from "../../../shared/services/storage.service";
import { UrlHelper } from "../../../shared/helpers/url-helper";
import {
  SearchModel,
  SearchResultModel,
  WebSearchModel,
} from "../../../header/search/search-model";
import { TokenManagerService } from "../../../shared/services/token-manager.service";
import { PageStyle } from "../../../models/style/style.model";
import { FontsAndColorsService } from "../../../shared/services/fonts-and-colors.service";
import { DownloadAddToModel } from "../../media/media-model";
import { GoogleAnalyticsEventsService } from "../../../shared/services/google-analytics-events.service";
import { LanguageService } from "../../../shared/services/language.service";
import { Language } from "../../../models/language/language.model";
import {
  DomSanitizer,
  Meta,
  MetaDefinition,
  Title,
} from "@angular/platform-browser";
import { MyFilesService } from "../../../shared/services/my-files.service";
import { ButtonsHelper } from "../../../shared/helpers/buttons-helper";
import { SavedStoryService } from "../../../shared/services/saved-story.service";
import { Subscription } from "rxjs";
import { WebpHelperService } from "../../../shared/services/webp-helper.service";

declare var require: any;
declare var $: any;

@Component({
  selector: "app-news-detail",
  templateUrl: "./news-detail.component.html",
  styleUrls: ["./news-detail.component.less"],
})
export class NewsDetailComponent implements OnInit {
  urlSubscription: Subscription;

  popup: boolean;
  loginPopup: boolean;
  media: any[];

  filtered: WebNewsDetailsMediaModel[] = [];
  imageMedia: WebNewsDetailsMediaModel[] = [];
  audioMedia: WebNewsDetailsMediaModel[] = [];
  videoMedia: WebNewsDetailsMediaModel[] = [];
  documentMedia: WebNewsDetailsMediaModel[] = [];
  cartItems: any[] = [];
  image: number[] = [];
  video: number[] = [];
  audio: number[] = [];
  activeSubTab: number;
  storyId: number;
  url: string;
  forDownload: any[] = [];
  downloadIds: any[] = [];
  blob: any;
  loader: boolean;
  disabledButton: boolean;
  mediaId: number;
  autoplay: boolean;
  allFiles: number;
  share: boolean;
  all: boolean;
  imageTab: boolean;
  videoTab: boolean;
  documentTab: boolean;
  audioTab: boolean;
  user: any;
  styles = new PageStyle();
  activeAllTab = false;
  activeTab = "all";
  activeImageTab = false;
  activeVideoTab = false;
  activeDocumentsTab = false;
  activeAudioTab = false;
  activeFb = false;
  activeMail = false;
  activeTw = false;
  activeIn = false;
  socials: string[] = [];
  currentUrl: string;
  allActive = false;
  imageActive = false;
  videoActive = false;
  docActive = false;
  audioActive = false;
  language: Language;
  sLng: number;
  token: string;
  relatedStoriesTabOpened = true;
  mediaTabOpened = true;
  isMediaUser: boolean;
  buttonsHelper = ButtonsHelper;
  publisher: string;

  private scrolled25: boolean = false;
  private scrolled50: boolean = false;
  private scrolled75: boolean = false;
  private scrolled90: boolean = false;
  private scrolled100: boolean = false;

  webNewsDetailsResponse: WebNewsDetailsModel;
  filters: any;

  constructor(
    private myFilesService: MyFilesService,
    private languageService: LanguageService,
    private mcService: McService,
    private meta: Meta,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private fontsAndColorsService: FontsAndColorsService,
    private googleAnalytics: GoogleAnalyticsEventsService,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private savedStoryService: SavedStoryService,
    private storageService: StorageService,
    public webPHelperService: WebpHelperService
  ) {
    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();
    this.token = localStorage.getItem("Token");
    this.languageService.language.subscribe((language) => {
      this.language = language;
    });
  }

  activeallC(name) {
    if (name == "all") {
      if (this.activeAllTab) {
        this.allActive = true;
      } else {
        this.activeAllTab = true;
      }
    } else if (name == "image") {
      if (this.activeImageTab) {
        this.imageActive = true;
      } else {
        this.activeImageTab = true;
      }
    } else if (name == "video") {
      if (this.activeVideoTab) {
        this.videoActive = true;
      } else {
        this.activeVideoTab = true;
      }
    } else if (name == "document") {
      if (this.activeDocumentsTab) {
        this.docActive = true;
      } else {
        this.activeDocumentsTab = true;
      }
    } else if (name == "audio") {
      if (this.activeAudioTab) {
        this.audioActive = true;
      } else {
        this.activeAudioTab = true;
      }
    }
  }

  activeallCOut(name) {
    if (name == "all") {
      if (!this.allActive) {
        this.activeAllTab = false;
      }
    } else if (name == "image") {
      if (!this.imageActive) {
        this.activeImageTab = false;
      }
    } else if (name == "video") {
      if (!this.videoActive) {
        this.activeVideoTab = false;
      }
    } else if (name == "document") {
      if (!this.docActive) {
        this.activeDocumentsTab = false;
      }
    } else if (name == "audio") {
      if (!this.audioActive) {
        this.activeAudioTab = false;
      }
    }
  }

  showCart() {
    if (
      this.webNewsDetailsResponse.mustRegisterToDownload &&
      localStorage.getItem("Token") === null
    ) {
      window.scrollTo(0, 0);
      // this.loginPopup = true;
      // return;
      this.router.navigateByUrl('/sign-in');

    } else {
      if (this.cartItems.length > 0) {
        this.router.navigateByUrl("/download");
      } else {
        return;
      }
    }
  }

  addDownload() {
    this.user = TokenManagerService.getUser();
    if (localStorage.getItem("Token") === null) {
      this.user.id = null;
    }
    let niz = new DownloadAddToModel();
    niz.storyMediaIds = this.downloadIds;
    niz.userId = this.user.id;
    niz.publishedMediaIds = [];
    niz.storyMediaIds.forEach((mediaId) => {
      this.googleAnalytics.emitEvent(
        "media/" + mediaId,
        "download",
        niz.userId ? niz.userId.toString() : ""
      );
    });
    this.mcService.addDownloadToMedia(niz).subscribe(
      (response) => { },
      (error) => {
        //
      }
    );
  }

  ngOnInit() {
    this.urlSubscription = this.route.params.subscribe((params: Params) => {
      this.getData();
    });
  }

  ngOnDestroy() {
    this.urlSubscription.unsubscribe();
  }

  getData() {
    this.sLng = Number(localStorage.getItem("lng"));
    this.user = TokenManagerService.getUser();

    if (this.user.role === "MediaUser") {
      this.isMediaUser = false;
    } else if (this.user.role && this.user.role !== "MediaUser") {
      this.isMediaUser = true;
    } else {
      this.isMediaUser = false;
    }

    this.storyId = +this.router.routerState.snapshot.url.split("/")[2];
    this.getNewsDetails();
    window.scrollTo(0, 0);
    this.popup = false;
    this.filtered = this.media;
    this.share = false;
    this.currentUrl = window.location.href;

    let mediaFromRoute = this.route.snapshot.paramMap.get("media");
    if (mediaFromRoute != undefined) {
      let tempMedia = { id: mediaFromRoute };
      this.openMediaPopup(tempMedia);
    }
  }

  showSubCategory(story) {
    story.categoryDropdown = !story.categoryDropdown;
  }

  showSubCategoryRandom(story) {
    story.categoryDropdown = !story.categoryDropdown;
  }

  getNewsDetails() {
    this.loader = true;
    if (this.sLng == 0) {
      this.sLng = 1;
    }

    let langId = this.route.snapshot.queryParamMap.get("lang");
    if (langId) {
      this.sLng = Number(langId);
    }

    this.mcService
      .getNewsDetail(this.storyId, this.sLng, this.isMediaUser)
      .subscribe(
        (response: any) => {
          this.webNewsDetailsResponse = response;
          this.titleService.setTitle(response?.storyTitle.substring(0, 65));
          this.meta.updateTag({
            name: "keywords",
            content: response?.storyTitle.substring(0, 65),
          });
          this.meta.updateTag({
            name: "description",
            content: response?.shortDescription.substring(0, 100),
          });
          this.meta.updateTag({
            name: "twitter:title",
            content: response?.storyTitle.substring(0, 65),
          });
          this.meta.updateTag({
            name: "twitter:description",
            content: response?.shortDescription.substring(0, 100),
          });

          this.webNewsDetailsResponse.alsoMayLike =
            this.webNewsDetailsResponse.alsoMayLike.slice(0, 2);
          for (const media of this.webNewsDetailsResponse.media) {
            media.shareUrl =
              UrlHelper.getCurrentUrlHostOnly() +
              "/content-files/" +
              this.webNewsDetailsResponse.staggingStoryId +
              "/" +
              UrlHelper.getUrlTitle(this.webNewsDetailsResponse.storyTitle) +
              "/" +
              media.id;
            //
          }

          if (
            this.webNewsDetailsResponse.mustRegisterToAccess &&
            !this.webNewsDetailsResponse.anonymousShare &&
            !this.token
          ) {
            sessionStorage.setItem(
              "redirectUrl",
              "/" + window.location.pathname
            );
            this.router.navigateByUrl("/sign-in");
          }

          if (this.webNewsDetailsResponse.featuredEmbeddedCode) {
            this.webNewsDetailsResponse.embeddedCode =
              this.sanitizer.bypassSecurityTrustHtml(
                this.webNewsDetailsResponse.featuredEmbeddedCode
              );
          }

          for (const story of this.webNewsDetailsResponse.alsoMayLike) {
            if (story.featuredEmbeddedCode) {
              story.embeddedCode = this.sanitizer.bypassSecurityTrustHtml(
                story.featuredEmbeddedCode
              );
            }
          }

          if (this.webNewsDetailsResponse.socialSharing != null) {
            this.socials = this.webNewsDetailsResponse.socialSharing;
          }

          this.allFiles =
            this.webNewsDetailsResponse.images +
            this.webNewsDetailsResponse.video +
            this.webNewsDetailsResponse.document +
            this.webNewsDetailsResponse.audio;
          this.filtered = response.media;
          this.loader = false;

          for (const f of this.filtered) {
            if (f.mediaType === "Audio" || f.mediaType === "Video") {
              f.mediaLengthMin = Math.floor(f.mediaLenght / 60);
              f.mediaLengthSec = f.mediaLenght - f.mediaLengthMin * 60;
            }
          }

          this.groupMedia();

          if (localStorage.getItem("cart") != null) {
            this.cartItems = JSON.parse(localStorage.getItem("cart"));
          }
          // this.meta.updateTag({name: 'keywords', content: response.thumbUrl});
          this.meta.updateTag({
            name: "description",
            content: response.shortDescription,
          });
          this.meta.updateTag({
            name: "twitter:title",
            content: response.storyTitle,
          });
          this.meta.updateTag({
            name: "twitter:description",
            content: response.shortDescription,
          });
          this.meta.updateTag({
            name: "twitter:text:description",
            content: response.shortDescription,
          });
          this.meta.updateTag({
            name: "twitter:image",
            content: response.thumbUrl,
          });
          this.meta.updateTag({
            name: "twitter:card",
            content: "summary_large_image",
          });
          this.meta.updateTag({
            property: "og:image",
            content: response.thumbUrl,
          });
          this.meta.updateTag({
            property: "og:description",
            content: response.shortDescription,
          });
          this.meta.updateTag({
            property: "og:title",
            content: response.storyTitle,
          });
          setTimeout(() => {
            $("#bodytext a").attr("target", "_blank");
          }, 1000);

          this.filters = [
            {
              key: "all",
              text: this.language.allFiles,
              faClass: "fal fa-archive",
              amount: this.allFiles,
            },
            {
              key: "image",
              text: this.language.imageFiles,
              faClass: "fal fa-camera-retro",
              amount: this.webNewsDetailsResponse.images,
            },
            {
              key: "video",
              text: this.language.videoFiles,
              faClass: "fal fa-video",
              amount: this.webNewsDetailsResponse.video,
            },
            {
              key: "document",
              text: this.language.documents,
              faClass: "fal fa-file",
              amount: this.webNewsDetailsResponse.document,
            },
            {
              key: "audio",
              text: this.language.audioFiles,
              faClass: "fal fa-volume-up",
              amount: this.webNewsDetailsResponse.audio,
            },
          ];
        },
        (error2) => {
          this.router.navigateByUrl("/home");
          //
        }
      );
  }

  groupMedia() {
    this.imageMedia = this.filtered.filter(
      (x) => x.mediaType.toLowerCase() === "image"
    );
    this.audioMedia = this.filtered.filter(
      (x) => x.mediaType.toLowerCase() === "audio"
    );
    this.videoMedia = this.filtered.filter(
      (x) => x.mediaType.toLowerCase() === "video"
    );
    this.documentMedia = this.filtered.filter(
      (x) => x.mediaType.toLowerCase() === "document"
    );
  }

  openMediaPopup(media) {
    this.autoplay = false;
    this.mediaId = media.id;
    this.popup = true;
    media.views++;
  }

  openMediaPopupAutplay(media) {
    this.autoplay = true;
    this.mediaId = media.id;
    this.popup = true;
    media.views++;
  }

  openShare() {
    this.share = !this.share;
  }

  openDropdown(id) {
    for (const x of this.webNewsDetailsResponse.alsoMayLike) {
      if (x.storyId === id) {
        x.shareDropdown = !x.shareDropdown;
      }
    }
  }
  openMediaDropdown(media) {
    media.mediaDropdown = !media.mediaDropdown;
    event.stopPropagation();
  }

  onClickOutside(event: Object, media) {
    if (event && event["value"] === true) {
      media.mediaDropdown = false;
    }
  }

  readMore(id) {
    window.scrollTo(0, 0);
    this.user = TokenManagerService.getUser();
    this.storyId = id.staggingStoryId;
    if (localStorage.getItem("Token") === null) {
      this.user.id = null;
    }
    if (this.user.cmsUser == null) {
      this.googleAnalytics.emitEvent(
        "story/" + id.staggingStoryId,
        "view",
        this.user.id ? this.user.id.toString() : "anonymous"
      );
    }

    this.router.navigate([
      "/story/" +
      id.staggingStoryId +
      "/" +
      UrlHelper.getUrlTitle(id.storyTitle),
    ]);
    this.loader = true;

    this.getNewsDetails();
  }

  mediaChecked(media, e) {
    e.stopPropagation();
    let component = this;
    const index = this.forDownload.indexOf(media);
    media.checked = !media.checked;
    if (component.forDownload.indexOf(media) == -1 && media.checked) {
      component.forDownload.push(media.downloadLink);
      component.downloadIds.push(media.id);
    } else if (!media.checked) {
      component.forDownload.splice(index, 1);
      component.downloadIds.splice(index, 1);
    }
  }

  downloadingAll = false;
  downloadingImage = false;
  downloadingVideo = false;
  downloadingDocument = false;
  downloadingAudio = false;

  downloadAll(list = null, mediaType) {
    if (mediaType == "all") this.downloadingAll;
    else if (mediaType == "image") this.downloadingImage;
    else if (mediaType == "video") this.downloadingVideo;
    else if (mediaType == "document") this.downloadingDocument;
    else if (mediaType == "audio") this.downloadingAudio;
    let component = this;
    if (list === null) list = this.filtered;
    list.forEach(function (media) {
      component.forDownload.push(media.downloadLink);
      component.downloadIds.push(media.id);
    });
    this.download({}, "text", mediaType);
  }

  sendToCart() {
    let component = this;
    this.filtered.forEach(function (value) {
      if (component.cartItems.indexOf(value.id) == -1 && value.checked) {
        component.cartItems.push(value.id);
        value.addedToCart = true;
        value.checked = false;
        component.myFilesService.pushMyFiles(value.id);
      }
    });
  }

  sendAllToCart(list = null) {
    let component = this;
    if (list === null) list = this.filtered;
    list.forEach(function (value) {
      if (component.cartItems.indexOf(value.id) == -1) {
        component.cartItems.push(value.id);
        value.addedToCart = true;
        value.checked = false;
        component.myFilesService.pushMyFiles(value.id);
      }
    });
  }

  addDownloadClientSide() {
    for (const x of this.webNewsDetailsResponse.media) {
      for (const y of this.forDownload) {
        if (x.downloadLink == y) {
          x.downloads++;
        }
      }
    }
  }

  onLogin(state) {
    if (state) {
      this.download({}, "text");
    }
  }

  download(element, text, mediaType = null) {
    if (
      this.webNewsDetailsResponse.mustRegisterToDownload &&
      !this.webNewsDetailsResponse.anonymousShare &&
      localStorage.getItem("Token") === null
    ) {
      window.scrollTo(0, 0);
      // this.loginPopup = true;
      // return;
      this.router.navigateByUrl('/sign-in');
    } else {
      var urls = this.forDownload;
      let component = this;
      var zip = new JSZip();
      var count = 0;
      var zipFilename = "download.zip";
      this.loader = true;
      if (this.forDownload.length > 1) {
        element.textContent = text;
        component.disabledButton = true;
        urls.forEach(function (url) {
          var filename = url.split("/")[8];
          JSZipUtils.getBinaryContent(url, function (err, data) {
            if (err) {
              throw err;
            }
            zip.file(filename, data, { binary: true });
            count++;
            if (count == urls.length) {
              zip.generateAsync({ type: "blob" }).then(function (content) {
                saveAs(content, zipFilename);

                component.disabledButton = false;
                element.textContent = "Download";
                if (component.user.cmsUser == null) {
                  component.addDownload();
                }
                if (mediaType == "all") component.downloadingAll = false;
                else if (mediaType == "image") component.downloadingImage = false;
                else if (mediaType == "video") component.downloadingVideo = false;
                else if (mediaType == "document") component.downloadingDocument = false;
                else if (mediaType == "audio") component.downloadingAudio = false;

                component.loader = false;
              });
            }
          });
        });
        this.addDownloadClientSide();
      } else {
        for (const x of this.forDownload) {
          component.disabledButton = true;
          element.textContent = text;
          this.http
            .get(x, { responseType: "blob" })
            .toPromise()
            .then(
              (blob: Blob) => {
                let filename = x.split("/")[8];
                saveAs(blob, filename);
                component.disabledButton = false;
                element.textContent = "Download";
                if (component.user.cmsUser == null) {
                  component.addDownload();
                }
                component.loader = false;
              },
              (error) => { }
            );
          this.addDownloadClientSide();
        }
      }
      this.forDownload = [];
    }
  }

  getStoryUrl(storyId) {
    return (
      this.currentUrl.slice(0, this.currentUrl.lastIndexOf("story/")) +
      "story/" +
      storyId +
      "/" +
      UrlHelper.getUrlTitle(
        this.webNewsDetailsResponse.alsoMayLike.find(
          (x) => x.staggingStoryId == storyId
        ).storyTitle
      )
    );
  }

  getMediaUrl(mediaId: number) {
    const currentUrlArray = this.currentUrl.split("/");
    if (currentUrlArray.length > 6) {
      return window.location.href;
    } else {
      return window.location.href + "/" + mediaId;
    }
  }

  changeTab(tab: string) {
    this.activeTab = tab;
    if (tab == "all") this.filtered = this.webNewsDetailsResponse.media;
    else {
      tab = tab.replace(/\b\w/g, (l) => l.toUpperCase());
      this.filtered = this.webNewsDetailsResponse.media.filter(
        (x) => x.mediaType === tab
      );
    }
  }

  scrollToMedias() {
    document.getElementById("medias").scrollIntoView();
  }

  toggleSavedStory(storyId) {
    if (this.inSavedStories(storyId))
      this.savedStoryService
        .remove(storyId)
        .then((response: any) => { })
        .catch((error) => {
          console.error(error);
        });
    else
      this.savedStoryService
        .add(storyId)
        .then((response: any) => { })
        .catch((error) => {
          console.error(error);
        });
  }

  inSavedStories(storyId) {
    return this.savedStoryService.inSavedStories(storyId);
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll($event) {
    var percentage: number = Math.round(this.getScrollPercentage());
    if (percentage == 25 && !this.scrolled25) {
      this.scrolled25 = true;
      this.googleAnalytics.emitEvent(
        "Scroll",
        "ScrollingExperience",
        "Article Scroll",
        this.webNewsDetailsResponse.storyTitle,
        percentage
      );
      return;
    }
    if (percentage == 50 && !this.scrolled50) {
      this.scrolled50 = true;
      this.googleAnalytics.emitEvent(
        "Scroll",
        "ScrollingExperience",
        "Article Scroll",
        this.webNewsDetailsResponse.storyTitle,
        percentage
      );
      return;
    }
    if (percentage == 75 && !this.scrolled75) {
      this.scrolled75 = true;
      this.googleAnalytics.emitEvent(
        "Scroll",
        "ScrollingExperience",
        "Article Scroll",
        this.webNewsDetailsResponse.storyTitle,
        percentage
      );
      return;
    }
    if (percentage == 90 && !this.scrolled90) {
      this.scrolled90 = true;
      this.googleAnalytics.emitEvent(
        "Scroll",
        "ScrollingExperience",
        "Article Scroll",
        this.webNewsDetailsResponse.storyTitle,
        percentage
      );
      return;
    }
    if (percentage == 100 && !this.scrolled100) {
      this.scrolled100 = true;
      this.googleAnalytics.emitEvent(
        "Scroll",
        "ScrollingExperience",
        "Article Scroll",
        this.webNewsDetailsResponse.storyTitle,
        percentage
      );
      return;
    }
  }

  getWindowHeight() {
    return (
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight ||
      0
    );
  }
  getWindowYscroll() {
    return (
      window.pageYOffset ||
      document.body.scrollTop ||
      document.documentElement.scrollTop ||
      0
    );
  }
  getDocHeight() {
    return Math.max(
      document.body.scrollHeight || 0,
      document.documentElement.scrollHeight || 0,
      document.body.offsetHeight || 0,
      document.documentElement.offsetHeight || 0,
      document.body.clientHeight || 0,
      document.documentElement.clientHeight || 0
    );
  }
  getScrollPercentage() {
    return (
      ((this.getWindowYscroll() + this.getWindowHeight()) /
        this.getDocHeight()) *
      100
    );
  }

  searchByCategory(category) {
    this.loader = true;
    let searchResultResponse = new SearchResultModel();
    const searchModel = new WebSearchModel();
    searchModel.languageId = Number(localStorage.getItem("lng"));
    searchModel.stories = true;
    searchModel.media = false;
    searchModel.images = false;
    searchModel.video = false;
    searchModel.document = false;
    searchModel.audio = false;
    searchModel.page = 1;
    searchModel.pageSize = 12;
    if (category.isCustom) {
      searchModel.customCategoryIds = [category.id];
    } else {
      searchModel.mainCategoryIds = [category.id];
    }

    this.mcService.searchMcStories(searchModel).subscribe(
      (response: any) => {
        searchResultResponse = response;
        for (const result of searchResultResponse.stories) {
          if (result.featuredEmbeddedCode) {
            result.embeddedCode = this.sanitizer.bypassSecurityTrustHtml(
              result.featuredEmbeddedCode
            );
          }
        }
        if (
          searchResultResponse.mustRegisterToAccess &&
          localStorage.getItem("Token") == null
        ) {
          this.loader = false;
          sessionStorage.setItem("redirectUrl", "/" + window.location.pathname);
          this.router.navigateByUrl("/sign-in");
        } else {
          this.storageService.setSearchItemStories(searchResultResponse);
          this.storageService.setSearchModel(searchModel);
          this.loader = false;
          this.router.navigateByUrl("/results");
        }
      },
      (error) => {
        searchModel.stories = false;
      }
    );
  }
}
