import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageStyle } from '../../../models/style/style.model';
import { FontsAndColorsService } from '../../services/fonts-and-colors.service';
import { TokenManagerService } from '../../services/token-manager.service';
import { McService } from '../../services/mc-service';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { UrlHelper } from '../../helpers/url-helper';
import { WebNewsDetailsModel } from '../../../routes/news/news-model';
import { FacebookService } from 'ngx-facebook';
import { environment } from '../../../../environments/environment';
import { ButtonsHelper } from '../../helpers/buttons-helper';
import { Language } from "../../../models/language/language.model";
import { LanguageService } from "../../services/language.service";

@Component({
  selector: 'app-share-5',
  templateUrl: './share-5.component.html',
  styleUrls: ['./share-5.component.less']
})
export class ShareComponent5 implements OnInit {

  @Input() socials: string[];
  @Input() open: boolean = false;
  @Input() shared: number;
  @Input() storyId: number = null;
  @Input() staggingStoryId: number = null;
  @Input() mediaId: number = null;
  @Input() sbUrl: string;
  @Input() big: boolean = false;
  @Input() vertical: boolean = false;
  @Input() horizontal: boolean = false;
  @Input() noTitle: boolean = false;
  @Input() description: string;
  @Input() title: string;
  @Input() image: string;
  @Input() webNewsDetailsResponse: WebNewsDetailsModel;
  @Input() isMediaShare: boolean = false;
  @Input() isMediaShareVertical: boolean = false;
  @Input() isMediaShareBig: boolean = false;
  @Input() whiteShareStyle: boolean;
  @Input() hideShareNumber = true;
  @Input() isPublished = false;
  @Output() emitShared = new EventEmitter();
  @Output() embeddOpen = new EventEmitter();

  buttonsHelper = ButtonsHelper;
  embeddedPopupShow: boolean;
  user: any;
  activeFb = false;
  activeMail = false;
  activeTw = false;
  activeIn = false;
  activeEm = false;
  urlStoryForEmail: string;
  styles = new PageStyle();
  language: Language;

  constructor(
    private fb: FacebookService, 
    private languageService: LanguageService, 
    private fontsAndColorsService: FontsAndColorsService, 
    private mcService: McService, 
    private googleAnalytics: GoogleAnalyticsEventsService,
    ) {

    const headerFooter = JSON.parse(localStorage.getItem('headerfooter'));

    fb.init({
      appId: headerFooter.facebookAppId,
      version: 'v6.0'
    });
    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();

  }

  ngOnInit() {
    if (this.sbUrl) {
      this.urlStoryForEmail = this.sbUrl.replace(/(^\w+:|^)\/\//, '');
    }
  }

  openShare($event) {
    $event.stopPropagation();
    this.open = !this.open;
  }

  onClickOutside(event: Object) {
    if (event && event['value'] === true) {
      this.open = false;
    }
  }


  openEmbeddedPopup() {
    this.embeddedPopupShow = true;
  }

  closeEmbeddedPopup() {
    this.embeddedPopupShow = false;

  }

  clickSharedNews(networkType) {
    this.user = TokenManagerService.getUser();

    if (localStorage.getItem('Token') === null) {
      this.user.id = 'anonymous';
    }


    switch (networkType) {
      case 'facebook':
        this.fb.ui({
          method: 'share_open_graph',
          action_type: 'og.shares',
          action_properties: JSON.stringify({
            object: {
              'og:url': this.sbUrl,
              'og:title': this.title,
              'og:description': this.description,
              'og:image': this.image,
            }
          })
        }).then(res => {
          //this.shared++;
        }).catch(e => {
          //
        });
        break;
      case 'twitter':
        const twitterUrl = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(this.title) + '&url=' + encodeURIComponent(this.sbUrl);
        window.open(twitterUrl, '_blank');
        break;
      case 'linkedin':
        // const linkedinUrl = "https://www.linkedin.com/shareArticle?mini=true&url=" + encodeURIComponent(this.sbUrl);
        const linkedinUrl = "https://www.linkedin.com/sharing/share-offsite/?url=digitalnewsagency.com/" + encodeURIComponent(this.sbUrl);
        window.open(linkedinUrl, '_blank');
        break;
      case 'mail':
        const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

        if (iOS) {
          try {
            (window as any).webkit.messageHandlers.shareByEmailClicked.postMessage({
              url: this.sbUrl,
              title: this.title,
              subject: this.title
            });
          } catch (err) {
          }

        }
        break;
      default:
    }
    this.shareAnalytics(networkType);
  }

  private shareAnalytics(networkType) {
    if (this.isMediaShare) {
      this.mcService.addSharedToMedia(this.mediaId, this.user.id, networkType, this.isPublished, true).subscribe();
      this.googleAnalytics.emitEvent('share', "SocialSharing", "ArticleSharing", `${this.title} | ${networkType}`);
    } else {
      this.mcService.addSharedToNews(this.storyId, this.user.id, networkType, true).subscribe();
      this.googleAnalytics.emitEvent('share', "SocialSharing", "ArticleSharing", `${this.title} | ${networkType}`);
    }
    this.shared++;
    this.emitShared.emit(this.shared);
  }
}
